:root {
  --mdb-red: #f44336;
  --mdb-pink: #e91e63;
  --mdb-purple: purple-500;
  --mdb-indigo: #3f51b5;
  --mdb-blue: #2196f3;
  --mdb-cyan: #00bcd4;
  --mdb-teal: #009688;
  --mdb-green: #4caf50;
  --mdb-yellow: #ffeb3b;
  --mdb-orange: #ff9800;
  --mdb-white: #fff;
  --mdb-black: #000;
  --mdb-gray: #757575;
  --mdb-gray-dark: #4f4f4f;
  --mdb-gray-50: #fbfbfb;
  --mdb-gray-100: #f5f5f5;
  --mdb-gray-200: #eee;
  --mdb-gray-300: #e0e0e0;
  --mdb-gray-400: #bdbdbd;
  --mdb-gray-500: #9e9e9e;
  --mdb-gray-600: #757575;
  --mdb-gray-700: #616161;
  --mdb-gray-800: #4f4f4f;
  --mdb-gray-900: #262626;
  --mdb-primary: #3b71ca;
  --mdb-secondary: #9fa6b2;
  --mdb-success: #14a44d;
  --mdb-danger: #dc4c64;
  --mdb-warning: #e4a11b;
  --mdb-info: #54b4d3;
  --mdb-light: #fbfbfb;
  --mdb-dark: #332d2d;
  --mdb-primary-rgb: 59, 113, 202;
  --mdb-secondary-rgb: 159, 166, 178;
  --mdb-success-rgb: 20, 164, 77;
  --mdb-danger-rgb: 220, 76, 100;
  --mdb-warning-rgb: 228, 161, 27;
  --mdb-info-rgb: 84, 180, 211;
  --mdb-light-rgb: 251, 251, 251;
  --mdb-dark-rgb: 51, 45, 45;
  --mdb-white-rgb: 255, 255, 255;
  --mdb-black-rgb: 0, 0, 0;
  --mdb-body-color-rgb: 79, 79, 79;
  --mdb-body-bg-rgb: 255, 255, 255;
  --mdb-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --mdb-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --mdb-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --mdb-body-font-family: var(--mdb-font-roboto);
  --mdb-body-font-size: 1rem;
  --mdb-body-font-weight: 400;
  --mdb-body-line-height: 1.6;
  --mdb-body-color: #4f4f4f;
  --mdb-body-bg: #fff;
  --mdb-border-width: 1px;
  --mdb-border-style: solid;
  --mdb-border-color: #e0e0e0;
  --mdb-border-color-translucent: #0000002d;
  --mdb-border-radius: .25rem;
  --mdb-border-radius-sm: .25rem;
  --mdb-border-radius-lg: .5rem;
  --mdb-border-radius-xl: 1rem;
  --mdb-border-radius-2xl: 2rem;
  --mdb-border-radius-pill: 50rem;
  --mdb-link-color: #3b71ca;
  --mdb-link-hover-color: #386bc0;
  --mdb-code-color: #e91e63;
  --mdb-highlight-bg: #fff9c4;
}

*, :after, :before {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--mdb-body-font-family);
  font-size: var(--mdb-body-font-size);
  font-weight: var(--mdb-body-font-weight);
  line-height: var(--mdb-body-line-height);
  color: var(--mdb-body-color);
  text-align: var(--mdb-body-text-align);
  background-color: var(--mdb-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1, h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  .h1, h1 {
    font-size: 2.5rem;
  }
}

.h2, h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  .h2, h2 {
    font-size: 2rem;
  }
}

.h3, h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 1200px) {
  .h3, h3 {
    font-size: 1.75rem;
  }
}

.h4, h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  .h4, h4 {
    font-size: 1.5rem;
  }
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

.small, small {
  font-size: .875em;
}

.mark, mark {
  background-color: var(--mdb-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--mdb-link-color);
}

a:hover {
  color: var(--mdb-link-hover-color);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code, kbd, pre, samp {
  font-family: var(--mdb-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--mdb-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--mdb-body-bg);
  background-color: var(--mdb-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #757575;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody, td, tfoot, th, thead, tr {
  border: 0 solid;
  border-color: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-inline, .list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #757575;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid, .img-thumbnail {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  border: 1px solid var(--mdb-border-color);
  background-color: #fff;
  border-radius: .25rem;
  padding: .25rem;
  box-shadow: 0 .125rem .25rem #00000013;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #757575;
  font-size: .875em;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --mdb-gutter-x: 1.5rem;
  --mdb-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--mdb-gutter-x) * .5);
  padding-left: calc(var(--mdb-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --mdb-gutter-x: 1.5rem;
  --mdb-gutter-y: 0;
  margin-top: calc(var(--mdb-gutter-y) * -1);
  margin-right: calc(var(--mdb-gutter-x) * -.5);
  margin-left: calc(var(--mdb-gutter-x) * -.5);
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--mdb-gutter-x) * .5);
  padding-left: calc(var(--mdb-gutter-x) * .5);
  margin-top: var(--mdb-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --mdb-gutter-x: 0;
}

.g-0, .gy-0 {
  --mdb-gutter-y: 0;
}

.g-1, .gx-1 {
  --mdb-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --mdb-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --mdb-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --mdb-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --mdb-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --mdb-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --mdb-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --mdb-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --mdb-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --mdb-gutter-y: 3rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --mdb-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --mdb-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --mdb-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --mdb-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --mdb-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --mdb-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --mdb-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --mdb-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --mdb-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --mdb-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --mdb-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --mdb-gutter-y: 3rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --mdb-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --mdb-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --mdb-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --mdb-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --mdb-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --mdb-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --mdb-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --mdb-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --mdb-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --mdb-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --mdb-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --mdb-gutter-y: 3rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --mdb-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --mdb-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --mdb-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --mdb-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --mdb-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --mdb-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --mdb-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --mdb-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --mdb-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --mdb-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --mdb-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --mdb-gutter-y: 3rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --mdb-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --mdb-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --mdb-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --mdb-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --mdb-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --mdb-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --mdb-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --mdb-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --mdb-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --mdb-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --mdb-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --mdb-gutter-y: 3rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --mdb-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --mdb-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --mdb-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --mdb-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --mdb-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --mdb-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --mdb-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --mdb-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --mdb-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --mdb-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --mdb-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --mdb-gutter-y: 3rem;
  }
}

.table {
  --mdb-table-color: var(--mdb-body-color);
  --mdb-table-bg: transparent;
  --mdb-table-border-color: var(--mdb-border-color);
  --mdb-table-accent-bg: transparent;
  --mdb-table-striped-color: var(--mdb-body-color);
  --mdb-table-striped-bg: #00000005;
  --mdb-table-active-color: var(--mdb-body-color);
  --mdb-table-active-bg: #0000001a;
  --mdb-table-hover-color: var(--mdb-body-color);
  --mdb-table-hover-bg: #00000006;
  width: 100%;
  color: var(--mdb-table-color);
  vertical-align: top;
  border-color: var(--mdb-table-border-color);
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  background-color: var(--mdb-table-bg);
  box-shadow: inset 0 0 0 9999px var(--mdb-table-accent-bg);
  border-bottom-width: 1px;
  padding: 1rem 1.4rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid inherit;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped-columns > :not(caption) > tr > :nth-child(2n), .table-striped > tbody > tr:nth-of-type(odd) > * {
  --mdb-table-accent-bg: var(--mdb-table-striped-bg);
  color: var(--mdb-table-striped-color);
}

.table-active {
  --mdb-table-accent-bg: var(--mdb-table-active-bg);
  color: var(--mdb-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --mdb-table-accent-bg: var(--mdb-table-hover-bg);
  color: var(--mdb-table-hover-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  color: #0009;
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  color: #0009;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .775rem;
}

.form-text {
  color: #757575;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: #4f4f4f;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  transition: all .2s linear;
  display: block;
  box-shadow: inset 0 1px 2px #00000013;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #4f4f4f;
  background-color: #fff;
  outline: 0;
  box-shadow: inset 0 1px 2px #00000013, 0 0 0 .25rem #3b71ca40;
}

.form-control::-webkit-date-and-time-value {
  height: 1.6em;
}

.form-control::placeholder {
  color: #757575;
  opacity: 1;
}

.form-control:disabled {
  opacity: 1;
  background-color: #eee;
}

.form-control::file-selector-button {
  -webkit-margin-end: .75rem;
  margin: -.375rem -.75rem;
  color: #4f4f4f;
  pointer-events: none;
  border: 0 solid;
  border-color: inherit;
  background-color: #eee;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e2e2e2;
}

.form-control-plaintext {
  width: 100%;
  color: #4f4f4f;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.6;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.6em + .5rem + 2px);
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .775rem;
}

.form-control-sm::file-selector-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1rem;
}

.form-control-lg::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.6em + .75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.6em + .5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.6em + .75rem + 2px);
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .25rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .25rem;
}

.form-control-color.form-control-sm {
  height: calc(1.6em + .5rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.6em + 1rem + 2px);
}

.form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #4f4f4f;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%234f4f4f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #bdbdbd;
  border-radius: .25rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  display: block;
  box-shadow: inset 0 1px 2px #00000013;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  box-shadow: inset 0 1px 2px #00000013, 0 0 0 .25rem #3b71ca40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: #eee;
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #4f4f4f;
}

.form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .775rem;
}

.form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.form-check {
  min-height: 1.6rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .3em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #3b71ca;
  outline: 0;
  box-shadow: 0 0 0 .25rem #3b71ca40;
}

.form-check-input:checked {
  background-color: #3b71ca;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #3b71ca;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
  border-color: #757575;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check:disabled + .btn, .btn-check[disabled] + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3b71ca;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem #0000001a;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #c4d4ef;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #e0e0e0;
  border-color: #0000;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px #00000013;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3b71ca;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem #0000001a;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #c4d4ef;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #e0e0e0;
  border-color: #0000;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px #00000013;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #9e9e9e;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #9e9e9e;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control-plaintext::placeholder, .form-floating > .form-control::placeholder {
  color: #0000;
}

.form-floating > .form-control-plaintext:not(:-moz-placeholder-shown), .form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control-plaintext:-webkit-autofill, .form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-floating, .input-group > .form-select {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-floating:focus-within, .input-group > .form-select:focus {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: #4f4f4f;
  text-align: center;
  white-space: nowrap;
  background-color: #eee;
  border: 1px solid #bdbdbd;
  border-radius: .25rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  display: flex;
}

.input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1rem;
}

.input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .775rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
}

.valid-tooltip {
  color: #000;
  border-radius: .25rem;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2314a44d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right calc(.4em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: calc(1.6em + .75rem);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.4em + .1875rem) top calc(.4em + .1875rem);
  padding-right: calc(1.6em + .75rem);
}

.form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%234f4f4f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E"), url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2314a44d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: 4.125rem;
}

.form-control-color.is-valid, .was-validated .form-control-color:valid {
  width: calc(3.75rem + 1.6em);
}

.input-group > .form-control:not(:focus).is-valid, .input-group > .form-floating:not(:focus-within).is-valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-control:not(:focus):valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .was-validated .input-group > .form-select:not(:focus):valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
}

.invalid-tooltip {
  color: #000;
  border-radius: .25rem;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc4c64'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc4c64' stroke='none'/%3E%3C/svg%3E");
  background-position: right calc(.4em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: calc(1.6em + .75rem);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.4em + .1875rem) top calc(.4em + .1875rem);
  padding-right: calc(1.6em + .75rem);
}

.form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%234f4f4f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E"), url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc4c64'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc4c64' stroke='none'/%3E%3C/svg%3E");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.8em + .375rem) calc(.8em + .375rem);
  padding-right: 4.125rem;
}

.form-control-color.is-invalid, .was-validated .form-control-color:invalid {
  width: calc(3.75rem + 1.6em);
}

.input-group > .form-control:not(:focus).is-invalid, .input-group > .form-floating:not(:focus-within).is-invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .was-validated .input-group > .form-select:not(:focus):invalid {
  z-index: 4;
}

.btn {
  --mdb-btn-padding-x: 1.5rem;
  --mdb-btn-padding-y: .375rem;
  --mdb-btn-font-size: .75rem;
  --mdb-btn-font-weight: 500;
  --mdb-btn-line-height: 1.5;
  --mdb-btn-color: #4f4f4f;
  --mdb-btn-bg: transparent;
  --mdb-btn-border-width: 2px;
  --mdb-btn-border-color: transparent;
  --mdb-btn-hover-border-color: transparent;
  --mdb-btn-disabled-opacity: .65;
  --mdb-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--mdb-btn-focus-shadow-rgb), .5);
  padding: var(--mdb-btn-padding-y) var(--mdb-btn-padding-x);
  font-family: var(--mdb-btn-font-family);
  font-size: var(--mdb-btn-font-size);
  font-weight: var(--mdb-btn-font-weight);
  line-height: var(--mdb-btn-line-height);
  color: var(--mdb-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--mdb-btn-border-width) solid var(--mdb-btn-border-color);
  background-color: var(--mdb-btn-bg);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--mdb-btn-hover-color);
  background-color: var(--mdb-btn-hover-bg);
  border-color: var(--mdb-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--mdb-btn-color);
  background-color: var(--mdb-btn-bg);
  border-color: var(--mdb-btn-border-color);
}

.btn:focus-visible {
  color: var(--mdb-btn-hover-color);
  background-color: var(--mdb-btn-hover-bg);
}

.btn-check:focus-visible + .btn, .btn:focus-visible {
  border-color: var(--mdb-btn-hover-border-color);
  box-shadow: var(--mdb-btn-box-shadow), var(--mdb-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
  color: var(--mdb-btn-active-color);
  background-color: var(--mdb-btn-active-bg);
  border-color: var(--mdb-btn-active-border-color);
  box-shadow: var(--mdb-btn-active-shadow);
}

.btn-check:checked + .btn:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn:first-child:active:focus-visible, :not(.btn-check) + .btn:active:focus-visible {
  box-shadow: var(--mdb-btn-active-shadow), var(--mdb-btn-focus-box-shadow);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  color: var(--mdb-btn-disabled-color);
  pointer-events: none;
  background-color: var(--mdb-btn-disabled-bg);
  border-color: var(--mdb-btn-disabled-border-color);
  opacity: var(--mdb-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --mdb-btn-border-color: #3b71ca;
  --mdb-btn-hover-border-color: #2f5aa2;
  --mdb-btn-focus-shadow-rgb: 88, 134, 210;
  --mdb-btn-active-border-color: #2c5598;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #3b71ca;
  --mdb-btn-disabled-border-color: #3b71ca;
}

.btn-secondary {
  --mdb-btn-color: #000;
  --mdb-btn-bg: #9fa6b2;
  --mdb-btn-border-color: #9fa6b2;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #adb3be;
  --mdb-btn-hover-border-color: #a9afba;
  --mdb-btn-focus-shadow-rgb: 135, 141, 151;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #b2b8c1;
  --mdb-btn-active-border-color: #a9afba;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #000;
  --mdb-btn-disabled-bg: #9fa6b2;
  --mdb-btn-disabled-border-color: #9fa6b2;
}

.btn-success {
  --mdb-btn-color: #000;
  --mdb-btn-border-color: #14a44d;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #37b268;
  --mdb-btn-hover-border-color: #2cad5f;
  --mdb-btn-focus-shadow-rgb: 17, 139, 65;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #43b671;
  --mdb-btn-active-border-color: #2cad5f;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #000;
  --mdb-btn-disabled-bg: #14a44d;
  --mdb-btn-disabled-border-color: #14a44d;
}

.btn-danger {
  --mdb-btn-color: #000;
  --mdb-btn-border-color: #dc4c64;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #e1677b;
  --mdb-btn-hover-border-color: #e05e74;
  --mdb-btn-focus-shadow-rgb: 187, 65, 85;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #e37083;
  --mdb-btn-active-border-color: #e05e74;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #000;
  --mdb-btn-disabled-bg: #dc4c64;
  --mdb-btn-disabled-border-color: #dc4c64;
}

.btn-warning {
  --mdb-btn-color: #000;
  --mdb-btn-border-color: #e4a11b;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #e8af3d;
  --mdb-btn-hover-border-color: #e7aa32;
  --mdb-btn-focus-shadow-rgb: 194, 137, 23;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #e9b449;
  --mdb-btn-active-border-color: #e7aa32;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #000;
  --mdb-btn-disabled-bg: #e4a11b;
  --mdb-btn-disabled-border-color: #e4a11b;
}

.btn-info {
  --mdb-btn-color: #000;
  --mdb-btn-border-color: #54b4d3;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #6ebfda;
  --mdb-btn-hover-border-color: #65bcd7;
  --mdb-btn-focus-shadow-rgb: 71, 153, 179;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #76c3dc;
  --mdb-btn-active-border-color: #65bcd7;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #000;
  --mdb-btn-disabled-bg: #54b4d3;
  --mdb-btn-disabled-border-color: #54b4d3;
}

.btn-light {
  --mdb-btn-color: #000;
  --mdb-btn-border-color: #fbfbfb;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-border-color: #c9c9c9;
  --mdb-btn-focus-shadow-rgb: 213, 213, 213;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-border-color: #bcbcbc;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #000;
  --mdb-btn-disabled-bg: #fbfbfb;
  --mdb-btn-disabled-border-color: #fbfbfb;
}

.btn-dark {
  --mdb-btn-border-color: #332d2d;
  --mdb-btn-hover-bg: #524d4d;
  --mdb-btn-hover-border-color: #474242;
  --mdb-btn-focus-shadow-rgb: 82, 77, 77;
  --mdb-btn-active-bg: #5c5757;
  --mdb-btn-active-border-color: #474242;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #332d2d;
  --mdb-btn-disabled-border-color: #332d2d;
}

.btn-outline-primary {
  --mdb-btn-border-color: #3b71ca;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #3b71ca;
  --mdb-btn-hover-border-color: #3b71ca;
  --mdb-btn-focus-shadow-rgb: 59, 113, 202;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #3b71ca;
  --mdb-btn-active-border-color: #3b71ca;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #3b71ca;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #3b71ca;
  --mdb-gradient: none;
}

.btn-outline-secondary {
  --mdb-btn-color: #9fa6b2;
  --mdb-btn-border-color: #9fa6b2;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #9fa6b2;
  --mdb-btn-hover-border-color: #9fa6b2;
  --mdb-btn-focus-shadow-rgb: 159, 166, 178;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #9fa6b2;
  --mdb-btn-active-border-color: #9fa6b2;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #9fa6b2;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #9fa6b2;
  --mdb-gradient: none;
}

.btn-outline-success {
  --mdb-btn-border-color: #14a44d;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #14a44d;
  --mdb-btn-hover-border-color: #14a44d;
  --mdb-btn-focus-shadow-rgb: 20, 164, 77;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #14a44d;
  --mdb-btn-active-border-color: #14a44d;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #14a44d;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #14a44d;
  --mdb-gradient: none;
}

.btn-outline-danger {
  --mdb-btn-border-color: #dc4c64;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #dc4c64;
  --mdb-btn-hover-border-color: #dc4c64;
  --mdb-btn-focus-shadow-rgb: 220, 76, 100;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #dc4c64;
  --mdb-btn-active-border-color: #dc4c64;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #dc4c64;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #dc4c64;
  --mdb-gradient: none;
}

.btn-outline-warning {
  --mdb-btn-border-color: #e4a11b;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #e4a11b;
  --mdb-btn-hover-border-color: #e4a11b;
  --mdb-btn-focus-shadow-rgb: 228, 161, 27;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #e4a11b;
  --mdb-btn-active-border-color: #e4a11b;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #e4a11b;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #e4a11b;
  --mdb-gradient: none;
}

.btn-outline-info {
  --mdb-btn-border-color: #54b4d3;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #54b4d3;
  --mdb-btn-hover-border-color: #54b4d3;
  --mdb-btn-focus-shadow-rgb: 84, 180, 211;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #54b4d3;
  --mdb-btn-active-border-color: #54b4d3;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #54b4d3;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #54b4d3;
  --mdb-gradient: none;
}

.btn-outline-light {
  --mdb-btn-border-color: #fbfbfb;
  --mdb-btn-hover-color: #000;
  --mdb-btn-hover-bg: #fbfbfb;
  --mdb-btn-hover-border-color: #fbfbfb;
  --mdb-btn-focus-shadow-rgb: 251, 251, 251;
  --mdb-btn-active-color: #000;
  --mdb-btn-active-bg: #fbfbfb;
  --mdb-btn-active-border-color: #fbfbfb;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #fbfbfb;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #fbfbfb;
  --mdb-gradient: none;
}

.btn-outline-dark {
  --mdb-btn-border-color: #332d2d;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #332d2d;
  --mdb-btn-hover-border-color: #332d2d;
  --mdb-btn-focus-shadow-rgb: 51, 45, 45;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #332d2d;
  --mdb-btn-active-border-color: #332d2d;
  --mdb-btn-active-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-disabled-color: #332d2d;
  --mdb-btn-disabled-bg: transparent;
  --mdb-btn-disabled-border-color: #332d2d;
  --mdb-gradient: none;
}

.btn-link {
  --mdb-btn-font-weight: 400;
  --mdb-btn-bg: transparent;
  --mdb-btn-border-color: transparent;
  --mdb-btn-hover-border-color: transparent;
  --mdb-btn-active-color: #386bc0;
  --mdb-btn-active-border-color: transparent;
  --mdb-btn-disabled-border-color: transparent;
  --mdb-btn-focus-shadow-rgb: 88, 134, 210;
}

.btn-link:focus-visible, .btn-link:hover {
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--mdb-btn-color);
}

.btn-link:hover {
  color: var(--mdb-btn-hover-color);
}

.btn-group-lg > .btn, .btn-lg {
  --mdb-btn-padding-y: .5rem;
  --mdb-btn-border-radius: .5rem;
}

.btn-group-sm > .btn, .btn-sm {
  --mdb-btn-padding-y: .25rem;
  --mdb-btn-border-radius: .25rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --mdb-dropdown-zindex: 1000;
  --mdb-dropdown-min-width: 10rem;
  --mdb-dropdown-padding-x: 0;
  --mdb-dropdown-padding-y: .5rem;
  --mdb-dropdown-spacer: .125rem;
  --mdb-dropdown-font-size: .875rem;
  --mdb-dropdown-color: #4f4f4f;
  --mdb-dropdown-bg: #fff;
  --mdb-dropdown-border-color: var(--mdb-border-color-translucent);
  --mdb-dropdown-border-radius: .5rem;
  --mdb-dropdown-border-width: 1px;
  --mdb-dropdown-inner-border-radius: calc(.5rem - 1px);
  --mdb-dropdown-divider-bg: #f5f5f5;
  --mdb-dropdown-divider-margin-y: .5rem;
  --mdb-dropdown-box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  --mdb-dropdown-link-color: #262626;
  --mdb-dropdown-link-hover-color: #222;
  --mdb-dropdown-link-hover-bg: #eee;
  --mdb-dropdown-link-active-color: #fff;
  --mdb-dropdown-link-active-bg: #3b71ca;
  --mdb-dropdown-link-disabled-color: #9e9e9e;
  --mdb-dropdown-item-padding-x: 1rem;
  --mdb-dropdown-item-padding-y: .5rem;
  --mdb-dropdown-header-color: #757575;
  --mdb-dropdown-header-padding-x: 1rem;
  --mdb-dropdown-header-padding-y: .5rem;
  z-index: var(--mdb-dropdown-zindex);
  min-width: var(--mdb-dropdown-min-width);
  padding: var(--mdb-dropdown-padding-y) var(--mdb-dropdown-padding-x);
  text-align: left;
  background-color: var(--mdb-dropdown-bg);
  border: var(--mdb-dropdown-border-width) solid var(--mdb-dropdown-border-color);
  border-radius: var(--mdb-dropdown-border-radius);
  background-clip: padding-box;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-mdb-popper] {
  margin-top: var(--mdb-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --mdb-position: start;
}

.dropdown-menu-start[data-mdb-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --mdb-position: end;
}

.dropdown-menu-end[data-mdb-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --mdb-position: start;
  }

  .dropdown-menu-sm-start[data-mdb-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --mdb-position: end;
  }

  .dropdown-menu-sm-end[data-mdb-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --mdb-position: start;
  }

  .dropdown-menu-md-start[data-mdb-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --mdb-position: end;
  }

  .dropdown-menu-md-end[data-mdb-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start {
    --mdb-position: start;
  }

  .dropdown-menu-lg-start[data-mdb-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --mdb-position: end;
  }

  .dropdown-menu-lg-end[data-mdb-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start {
    --mdb-position: start;
  }

  .dropdown-menu-xl-start[data-mdb-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --mdb-position: end;
  }

  .dropdown-menu-xl-end[data-mdb-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start {
    --mdb-position: start;
  }

  .dropdown-menu-xxl-start[data-mdb-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --mdb-position: end;
  }

  .dropdown-menu-xxl-end[data-mdb-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-mdb-popper] {
  margin-top: 0;
  margin-bottom: var(--mdb-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-mdb-popper] {
  margin-top: 0;
  margin-left: var(--mdb-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-mdb-popper] {
  margin-top: 0;
  margin-right: var(--mdb-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--mdb-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--mdb-dropdown-divider-bg);
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: var(--mdb-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  display: block;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: var(--mdb-dropdown-link-hover-color);
  background-color: var(--mdb-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--mdb-dropdown-link-active-color);
  background-color: var(--mdb-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--mdb-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--mdb-dropdown-header-padding-y) var(--mdb-dropdown-header-padding-x);
  color: var(--mdb-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--mdb-dropdown-item-padding-y) var(--mdb-dropdown-item-padding-x);
  color: var(--mdb-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --mdb-dropdown-color: #e0e0e0;
  --mdb-dropdown-bg: #4f4f4f;
  --mdb-dropdown-border-color: var(--mdb-border-color-translucent);
  --mdb-dropdown-link-color: #e0e0e0;
  --mdb-dropdown-link-hover-color: #fff;
  --mdb-dropdown-divider-bg: #f5f5f5;
  --mdb-dropdown-link-hover-bg: #ffffff26;
  --mdb-dropdown-link-active-color: #fff;
  --mdb-dropdown-link-active-bg: #3b71ca;
  --mdb-dropdown-link-disabled-color: #9e9e9e;
  --mdb-dropdown-header-color: #9e9e9e;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  flex: auto;
  position: relative;
}

.btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: .25rem;
}

.btn-group > .btn-group:not(:first-child), .btn-group > :not(.btn-check:first-child) + .btn {
  margin-left: -2px;
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-left: 1.26563rem;
  padding-right: 1.26563rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 8px 9px -4px #00000026, 0 4px 18px #0000001a;
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -2px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --mdb-nav-link-padding-x: 1rem;
  --mdb-nav-link-padding-y: .5rem;
  --mdb-nav-link-color: var(--mdb-link-color);
  --mdb-nav-link-hover-color: var(--mdb-link-hover-color);
  --mdb-nav-link-disabled-color: #757575;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--mdb-nav-link-padding-y) var(--mdb-nav-link-padding-x);
  font-size: var(--mdb-nav-link-font-size);
  font-weight: var(--mdb-nav-link-font-weight);
  color: var(--mdb-nav-link-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:focus, .nav-link:hover {
  color: var(--mdb-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--mdb-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --mdb-nav-tabs-border-width: 1px;
  --mdb-nav-tabs-border-color: #e0e0e0;
  --mdb-nav-tabs-border-radius: .25rem;
  --mdb-nav-tabs-link-hover-border-color: #eee #eee #e0e0e0;
  --mdb-nav-tabs-link-active-color: #3b71ca;
  --mdb-nav-tabs-link-active-bg: #fff;
  --mdb-nav-tabs-link-active-border-color: #3b71ca;
  border-bottom: var(--mdb-nav-tabs-border-width) solid var(--mdb-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(var(--mdb-nav-tabs-border-width) * -1);
  border: var(--mdb-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--mdb-nav-tabs-border-radius);
  border-top-right-radius: var(--mdb-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: var(--mdb-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--mdb-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: var(--mdb-nav-tabs-link-active-bg);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(var(--mdb-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --mdb-nav-pills-border-radius: .25rem;
  --mdb-nav-pills-link-active-color: #285192;
  --mdb-nav-pills-link-active-bg: #e3ebf7;
}

.nav-pills .nav-link {
  border-radius: var(--mdb-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--mdb-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-fill .nav-item, .nav-fill > .nav-link {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item, .nav-justified > .nav-link {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --mdb-navbar-padding-x: 0;
  --mdb-navbar-padding-y: .5rem;
  --mdb-navbar-color: #0000008c;
  --mdb-navbar-hover-color: #000000b3;
  --mdb-navbar-disabled-color: #0000004d;
  --mdb-navbar-active-color: #000000e6;
  --mdb-navbar-brand-padding-y: .3rem;
  --mdb-navbar-brand-margin-end: 1rem;
  --mdb-navbar-brand-font-size: 1.25rem;
  --mdb-navbar-brand-color: #000000e6;
  --mdb-navbar-brand-hover-color: #000000e6;
  --mdb-navbar-nav-link-padding-x: .5rem;
  --mdb-navbar-toggler-padding-y: .25rem;
  --mdb-navbar-toggler-padding-x: .75rem;
  --mdb-navbar-toggler-font-size: 1.25rem;
  --mdb-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --mdb-navbar-toggler-border-color: #0000001a;
  --mdb-navbar-toggler-border-radius: .25rem;
  --mdb-navbar-toggler-focus-width: .25rem;
  --mdb-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--mdb-navbar-padding-y) var(--mdb-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--mdb-navbar-brand-padding-y);
  padding-bottom: var(--mdb-navbar-brand-padding-y);
  margin-right: var(--mdb-navbar-brand-margin-end);
  font-size: var(--mdb-navbar-brand-font-size);
  color: var(--mdb-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:focus, .navbar-brand:hover {
  color: var(--mdb-navbar-brand-hover-color);
}

.navbar-nav {
  --mdb-nav-link-padding-x: 0;
  --mdb-nav-link-padding-y: .5rem;
  --mdb-nav-link-color: var(--mdb-navbar-color);
  --mdb-nav-link-hover-color: var(--mdb-navbar-hover-color);
  --mdb-nav-link-disabled-color: var(--mdb-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .show > .nav-link {
  color: var(--mdb-navbar-active-color);
}

.navbar-text {
  color: var(--mdb-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:focus, .navbar-text a:hover {
  color: var(--mdb-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--mdb-navbar-toggler-padding-y) var(--mdb-navbar-toggler-padding-x);
  font-size: var(--mdb-navbar-toggler-font-size);
  color: var(--mdb-navbar-color);
  border: var(--mdb-border-width) solid var(--mdb-navbar-toggler-border-color);
  border-radius: var(--mdb-navbar-toggler-border-radius);
  transition: var(--mdb-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--mdb-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--mdb-navbar-toggler-icon-bg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--mdb-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--mdb-navbar-nav-link-padding-x);
    padding-left: var(--mdb-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--mdb-navbar-nav-link-padding-x);
  padding-left: var(--mdb-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  box-shadow: none;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark {
  --mdb-navbar-color: #ffffff8c;
  --mdb-navbar-hover-color: #ffffffbf;
  --mdb-navbar-disabled-color: #ffffff40;
  --mdb-navbar-active-color: #fff;
  --mdb-navbar-brand-color: #fff;
  --mdb-navbar-brand-hover-color: #fff;
  --mdb-navbar-toggler-border-color: #ffffff1a;
  --mdb-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.card {
  --mdb-card-spacer-y: 1.5rem;
  --mdb-card-spacer-x: 1.5rem;
  --mdb-card-title-spacer-y: .5rem;
  --mdb-card-border-width: 1px;
  --mdb-card-border-color: var(--mdb-border-color-translucent);
  --mdb-card-border-radius: .5rem;
  --mdb-card-box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  --mdb-card-inner-border-radius: calc(.5rem - 1px);
  --mdb-card-cap-padding-y: .75rem;
  --mdb-card-cap-padding-x: 1.5rem;
  --mdb-card-cap-bg: #fff0;
  --mdb-card-bg: #fff;
  --mdb-card-img-overlay-padding: 1.5rem;
  --mdb-card-group-margin: .75rem;
  min-width: 0;
  height: var(--mdb-card-height);
  word-wrap: break-word;
  background-color: var(--mdb-card-bg);
  border: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
  border-radius: var(--mdb-card-border-radius);
  box-shadow: var(--mdb-card-box-shadow);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--mdb-card-inner-border-radius);
  border-top-right-radius: var(--mdb-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--mdb-card-inner-border-radius);
  border-bottom-left-radius: var(--mdb-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--mdb-card-spacer-y) var(--mdb-card-spacer-x);
  color: var(--mdb-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--mdb-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(var(--mdb-card-title-spacer-y) * -.5);
}

.card-subtitle, .card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--mdb-card-spacer-x);
}

.card-header {
  padding: var(--mdb-card-cap-padding-y) var(--mdb-card-cap-padding-x);
  color: var(--mdb-card-cap-color);
  background-color: var(--mdb-card-cap-bg);
  border-bottom: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--mdb-card-inner-border-radius) var(--mdb-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--mdb-card-cap-padding-y) var(--mdb-card-cap-padding-x);
  color: var(--mdb-card-cap-color);
  background-color: var(--mdb-card-cap-bg);
  border-top: var(--mdb-card-border-width) solid var(--mdb-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--mdb-card-inner-border-radius) var(--mdb-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(var(--mdb-card-cap-padding-x) * -.5);
  margin-bottom: calc(var(--mdb-card-cap-padding-y) * -1);
  margin-left: calc(var(--mdb-card-cap-padding-x) * -.5);
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--mdb-card-bg);
  border-bottom-color: var(--mdb-card-bg);
}

.card-header-pills {
  margin-right: calc(var(--mdb-card-cap-padding-x) * -.5);
  margin-left: calc(var(--mdb-card-cap-padding-x) * -.5);
}

.card-img-overlay {
  padding: var(--mdb-card-img-overlay-padding);
  border-radius: var(--mdb-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-bottom, .card-img-top {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--mdb-card-inner-border-radius);
  border-top-right-radius: var(--mdb-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--mdb-card-inner-border-radius);
  border-bottom-left-radius: var(--mdb-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--mdb-card-group-margin);
}

@media (width >= 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.breadcrumb {
  --mdb-breadcrumb-padding-x: 0;
  --mdb-breadcrumb-padding-y: 0;
  --mdb-breadcrumb-margin-bottom: 1rem;
  --mdb-breadcrumb-divider-color: #757575;
  --mdb-breadcrumb-item-padding-x: .5rem;
  --mdb-breadcrumb-item-active-color: #757575;
  padding: var(--mdb-breadcrumb-padding-y) var(--mdb-breadcrumb-padding-x);
  margin-bottom: var(--mdb-breadcrumb-margin-bottom);
  font-size: var(--mdb-breadcrumb-font-size);
  background-color: var(--mdb-breadcrumb-bg);
  border-radius: var(--mdb-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--mdb-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--mdb-breadcrumb-item-padding-x);
  color: var(--mdb-breadcrumb-divider-color);
  content: var(--mdb-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--mdb-breadcrumb-item-active-color);
}

.pagination {
  --mdb-pagination-padding-x: .75rem;
  --mdb-pagination-padding-y: .375rem;
  --mdb-pagination-font-size: .9rem;
  --mdb-pagination-color: #4f4f4f;
  --mdb-pagination-bg: #fff;
  --mdb-pagination-border-width: 1px;
  --mdb-pagination-border-color: #e0e0e0;
  --mdb-pagination-hover-color: #4f4f4f;
  --mdb-pagination-hover-bg: #eee;
  --mdb-pagination-hover-border-color: #e0e0e0;
  --mdb-pagination-focus-color: var(--mdb-link-hover-color);
  --mdb-pagination-focus-bg: #eee;
  --mdb-pagination-focus-box-shadow: 0 0 0 .25rem #3b71ca40;
  --mdb-pagination-active-color: #285192;
  --mdb-pagination-active-bg: #e3ebf7;
  --mdb-pagination-active-border-color: #e3ebf7;
  --mdb-pagination-disabled-color: #757575;
  --mdb-pagination-disabled-bg: #fff;
  --mdb-pagination-disabled-border-color: #e0e0e0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--mdb-pagination-padding-y) var(--mdb-pagination-padding-x);
  font-size: var(--mdb-pagination-font-size);
  color: var(--mdb-pagination-color);
  background-color: var(--mdb-pagination-bg);
  border: var(--mdb-pagination-border-width) solid var(--mdb-pagination-border-color);
  transition: all .3s linear;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--mdb-pagination-hover-color);
  background-color: var(--mdb-pagination-hover-bg);
  border-color: var(--mdb-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--mdb-pagination-focus-color);
  background-color: var(--mdb-pagination-focus-bg);
  box-shadow: var(--mdb-pagination-focus-box-shadow);
  outline: 0;
}

.active > .page-link, .page-link.active {
  z-index: 3;
  color: var(--mdb-pagination-active-color);
  background-color: var(--mdb-pagination-active-bg);
  border-color: var(--mdb-pagination-active-border-color);
}

.disabled > .page-link, .page-link.disabled {
  color: var(--mdb-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--mdb-pagination-disabled-bg);
  border-color: var(--mdb-pagination-disabled-border-color);
}

.pagination-lg {
  --mdb-pagination-padding-x: 1.5rem;
  --mdb-pagination-padding-y: .75rem;
  --mdb-pagination-font-size: 1.25rem;
  --mdb-pagination-border-radius: .5rem;
}

.pagination-sm {
  --mdb-pagination-padding-x: .5rem;
  --mdb-pagination-padding-y: .25rem;
  --mdb-pagination-font-size: .875rem;
  --mdb-pagination-border-radius: .25rem;
}

.badge {
  --mdb-badge-padding-x: .65em;
  --mdb-badge-padding-y: .35em;
  --mdb-badge-font-size: .75em;
  --mdb-badge-font-weight: 700;
  --mdb-badge-color: #fff;
  --mdb-badge-border-radius: .27rem;
  padding: var(--mdb-badge-padding-y) var(--mdb-badge-padding-x);
  font-size: var(--mdb-badge-font-size);
  font-weight: var(--mdb-badge-font-weight);
  color: var(--mdb-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--mdb-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --mdb-alert-bg: transparent;
  --mdb-alert-padding-x: 1.5rem;
  --mdb-alert-padding-y: 1.25rem;
  --mdb-alert-margin-bottom: 1rem;
  --mdb-alert-color: inherit;
  --mdb-alert-border-color: transparent;
  --mdb-alert-border: 1px solid var(--mdb-alert-border-color);
  --mdb-alert-border-radius: .5rem;
  padding: var(--mdb-alert-padding-y) var(--mdb-alert-padding-x);
  margin-bottom: var(--mdb-alert-margin-bottom);
  color: var(--mdb-alert-color);
  background-color: var(--mdb-alert-bg);
  border: var(--mdb-alert-border);
  border-radius: var(--mdb-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.5rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.5625rem 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --mdb-alert-color: #234479;
  --mdb-alert-bg: #d8e3f4;
  --mdb-alert-border-color: #c4d4ef;
}

.alert-secondary {
  --mdb-alert-color: #5f646b;
  --mdb-alert-bg: #ecedf0;
  --mdb-alert-border-color: #e2e4e8;
}

.alert-success {
  --mdb-alert-color: #0c622e;
  --mdb-alert-bg: #d0eddb;
  --mdb-alert-border-color: #b9e4ca;
}

.alert-danger {
  --mdb-alert-color: #842e3c;
  --mdb-alert-bg: #f8dbe0;
  --mdb-alert-border-color: #f5c9d1;
}

.alert-warning {
  --mdb-alert-color: #896110;
  --mdb-alert-bg: #faecd1;
  --mdb-alert-border-color: #f7e3bb;
}

.alert-info {
  --mdb-alert-color: #326c7f;
  --mdb-alert-bg: #ddf0f6;
  --mdb-alert-border-color: #cce9f2;
}

.alert-light {
  --mdb-alert-color: #646464;
  --mdb-alert-bg: #fefefe;
  --mdb-alert-border-color: #fefefe;
}

.alert-dark {
  --mdb-alert-color: #1f1b1b;
  --mdb-alert-bg: #d6d5d5;
  --mdb-alert-border-color: #c2c0c0;
}

.accordion {
  --mdb-accordion-color: #4f4f4f;
  --mdb-accordion-bg: #fff;
  --mdb-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --mdb-accordion-border-color: var(--mdb-border-color);
  --mdb-accordion-border-width: 1px;
  --mdb-accordion-border-radius: .5rem;
  --mdb-accordion-inner-border-radius: calc(.5rem - 1px);
  --mdb-accordion-btn-padding-x: 1.5rem;
  --mdb-accordion-btn-padding-y: 1.15rem;
  --mdb-accordion-btn-color: #4f4f4f;
  --mdb-accordion-btn-bg: var(--mdb-accordion-bg);
  --mdb-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234f4f4f'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
  --mdb-accordion-btn-icon-width: 1.25rem;
  --mdb-accordion-btn-icon-transform: rotate(-180deg);
  --mdb-accordion-btn-icon-transition: transform .2s ease-in-out;
  --mdb-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233b71ca'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
  --mdb-accordion-btn-focus-border-color: #3b71ca;
  --mdb-accordion-btn-focus-box-shadow: inset 0 -1px 0 #00000020;
  --mdb-accordion-body-padding-x: 1.5rem;
  --mdb-accordion-body-padding-y: 1.15rem;
  --mdb-accordion-active-color: #3b71ca;
  --mdb-accordion-active-bg: #fff;
}

.accordion-button {
  width: 100%;
  padding: var(--mdb-accordion-btn-padding-y) var(--mdb-accordion-btn-padding-x);
  color: var(--mdb-accordion-btn-color);
  text-align: left;
  background-color: var(--mdb-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--mdb-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--mdb-accordion-active-color);
  background-color: var(--mdb-accordion-active-bg);
  box-shadow: inset 0 calc(var(--mdb-accordion-border-width) * -1) 0 var(--mdb-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--mdb-accordion-btn-active-icon);
  transform: var(--mdb-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--mdb-accordion-btn-icon-width);
  height: var(--mdb-accordion-btn-icon-width);
  content: "";
  background-image: var(--mdb-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--mdb-accordion-btn-icon-width);
  transition: var(--mdb-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  box-shadow: var(--mdb-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--mdb-accordion-color);
  background-color: var(--mdb-accordion-bg);
  border: var(--mdb-accordion-border-width) solid var(--mdb-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--mdb-accordion-border-radius);
  border-top-right-radius: var(--mdb-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--mdb-accordion-inner-border-radius);
  border-top-right-radius: var(--mdb-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--mdb-accordion-border-radius);
  border-bottom-left-radius: var(--mdb-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--mdb-accordion-inner-border-radius);
  border-bottom-left-radius: var(--mdb-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--mdb-accordion-border-radius);
  border-bottom-left-radius: var(--mdb-accordion-border-radius);
}

.accordion-body {
  padding: var(--mdb-accordion-body-padding-y) var(--mdb-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 4px;
  }
}

.progress {
  --mdb-progress-height: 4px;
  --mdb-progress-font-size: .75rem;
  --mdb-progress-bg: #eee;
  --mdb-progress-border-radius: .25rem;
  --mdb-progress-box-shadow: inset 0 1px 2px #00000013;
  --mdb-progress-bar-color: #fff;
  --mdb-progress-bar-bg: #3b71ca;
  --mdb-progress-bar-transition: width .6s ease;
  height: var(--mdb-progress-height);
  font-size: var(--mdb-progress-font-size);
  background-color: var(--mdb-progress-bg);
  border-radius: var(--mdb-progress-border-radius);
  box-shadow: var(--mdb-progress-box-shadow);
}

.progress, .progress-bar {
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--mdb-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--mdb-progress-bar-bg);
  transition: var(--mdb-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 0 50%, #ffffff26 0 75%, #0000 0, #0000);
  background-size: var(--mdb-progress-height) var(--mdb-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  to {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.list-group {
  --mdb-list-group-color: #4f4f4f;
  --mdb-list-group-bg: #fff;
  --mdb-list-group-border-color: #00000020;
  --mdb-list-group-border-width: 1px;
  --mdb-list-group-border-radius: .5rem;
  --mdb-list-group-item-padding-x: 1.5rem;
  --mdb-list-group-item-padding-y: .5rem;
  --mdb-list-group-action-color: #616161;
  --mdb-list-group-action-hover-color: #616161;
  --mdb-list-group-action-hover-bg: #f5f5f5;
  --mdb-list-group-action-active-color: #4f4f4f;
  --mdb-list-group-action-active-bg: #eee;
  --mdb-list-group-disabled-color: #757575;
  --mdb-list-group-disabled-bg: #fff;
  --mdb-list-group-active-color: #2c58a0;
  --mdb-list-group-active-bg: #dfe7f6;
  --mdb-list-group-active-border-color: #dfe7f6;
  border-radius: var(--mdb-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--mdb-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: var(--mdb-list-group-action-hover-color);
  background-color: var(--mdb-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--mdb-list-group-action-active-color);
  background-color: var(--mdb-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--mdb-list-group-item-padding-y) var(--mdb-list-group-item-padding-x);
  color: var(--mdb-list-group-color);
  background-color: var(--mdb-list-group-bg);
  border: var(--mdb-list-group-border-width) solid var(--mdb-list-group-border-color);
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--mdb-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--mdb-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--mdb-list-group-active-color);
  background-color: var(--mdb-list-group-active-bg);
  border-color: var(--mdb-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(var(--mdb-list-group-border-width) * -1);
  border-top-width: var(--mdb-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--mdb-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--mdb-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--mdb-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(var(--mdb-list-group-border-width) * -1);
  border-left-width: var(--mdb-list-group-border-width);
}

@media (width >= 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--mdb-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--mdb-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--mdb-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--mdb-list-group-border-width) * -1);
    border-left-width: var(--mdb-list-group-border-width);
  }
}

@media (width >= 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--mdb-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--mdb-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--mdb-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--mdb-list-group-border-width) * -1);
    border-left-width: var(--mdb-list-group-border-width);
  }
}

@media (width >= 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--mdb-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--mdb-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--mdb-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--mdb-list-group-border-width) * -1);
    border-left-width: var(--mdb-list-group-border-width);
  }
}

@media (width >= 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--mdb-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--mdb-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--mdb-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--mdb-list-group-border-width) * -1);
    border-left-width: var(--mdb-list-group-border-width);
  }
}

@media (width >= 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--mdb-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--mdb-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--mdb-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--mdb-list-group-border-width) * -1);
    border-left-width: var(--mdb-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-left-width: 0;
  border-bottom-width: var(--mdb-list-group-border-width);
  border-top-width: 0;
  border-right-width: 0;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #234479;
  background-color: #c2ccdc;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #234479;
  border-color: #234479;
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #5f646b;
  background-color: #d4d5d8;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #5f646b;
  border-color: #5f646b;
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #0c622e;
  background-color: #bbd5c5;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0c622e;
  border-color: #0c622e;
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #842e3c;
  background-color: #dfc5ca;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842e3c;
  border-color: #842e3c;
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #896110;
  background-color: #e1d4bc;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #896110;
  border-color: #896110;
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #326c7f;
  background-color: #c7d8dd;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #326c7f;
  border-color: #326c7f;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #646464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #646464;
  border-color: #646464;
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1f1b1b;
  background-color: #c1c0c0;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1f1b1b;
  border-color: #1f1b1b;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50% / 1em no-repeat;
  border: 0;
  border-radius: .25rem;
  padding: .25em;
}

.btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #3b71ca40;
}

.btn-close.disabled, .btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.toast {
  --mdb-toast-zindex: 1060;
  --mdb-toast-padding-x: 1rem;
  --mdb-toast-padding-y: .65rem;
  --mdb-toast-spacing: 1.5rem;
  --mdb-toast-max-width: 350px;
  --mdb-toast-font-size: .875rem;
  --mdb-toast-bg: #fff;
  --mdb-toast-border-width: 1px;
  --mdb-toast-border-color: var(--mdb-border-color-translucent);
  --mdb-toast-border-radius: .5rem;
  --mdb-toast-box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  --mdb-toast-header-color: #757575;
  --mdb-toast-header-bg: #fff;
  --mdb-toast-header-border-color: #0000000d;
  width: var(--mdb-toast-max-width);
  max-width: 100%;
  font-size: var(--mdb-toast-font-size);
  color: var(--mdb-toast-color);
  pointer-events: auto;
  background-color: var(--mdb-toast-bg);
  border: var(--mdb-toast-border-width) solid var(--mdb-toast-border-color);
  box-shadow: var(--mdb-toast-box-shadow);
  border-radius: var(--mdb-toast-border-radius);
  background-clip: padding-box;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --mdb-toast-zindex: 1060;
  z-index: var(--mdb-toast-zindex);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--mdb-toast-spacing);
}

.toast-header {
  padding: var(--mdb-toast-padding-y) var(--mdb-toast-padding-x);
  color: var(--mdb-toast-header-color);
  background-color: var(--mdb-toast-header-bg);
  border-bottom: var(--mdb-toast-border-width) solid var(--mdb-toast-header-border-color);
  border-top-left-radius: calc(var(--mdb-toast-border-radius)  - var(--mdb-toast-border-width));
  border-top-right-radius: calc(var(--mdb-toast-border-radius)  - var(--mdb-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-body {
  padding: var(--mdb-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --mdb-modal-zindex: 1055;
  --mdb-modal-width: 500px;
  --mdb-modal-padding: 1rem;
  --mdb-modal-margin: .5rem;
  --mdb-modal-bg: #fff;
  --mdb-modal-border-color: var(--mdb-border-color-translucent);
  --mdb-modal-border-width: 1px;
  --mdb-modal-border-radius: .5rem;
  --mdb-modal-box-shadow: 0 .125rem .25rem #00000013;
  --mdb-modal-inner-border-radius: calc(.5rem - 1px);
  --mdb-modal-header-padding-x: 1rem;
  --mdb-modal-header-padding-y: 1rem;
  --mdb-modal-header-padding: 1rem 1rem;
  --mdb-modal-header-border-color: #f5f5f5;
  --mdb-modal-header-border-width: 2px;
  --mdb-modal-title-line-height: 1.6;
  --mdb-modal-footer-gap: .5rem;
  --mdb-modal-footer-border-color: #f5f5f5;
  --mdb-modal-footer-border-width: 2px;
  z-index: var(--mdb-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: var(--mdb-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translateY(-50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--mdb-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--mdb-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--mdb-modal-color);
  pointer-events: auto;
  background-color: var(--mdb-modal-bg);
  border: var(--mdb-modal-border-width) solid var(--mdb-modal-border-color);
  border-radius: var(--mdb-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --mdb-backdrop-zindex: 1050;
  --mdb-backdrop-bg: #000;
  --mdb-backdrop-opacity: .5;
  z-index: var(--mdb-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--mdb-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--mdb-backdrop-opacity);
}

.modal-header {
  padding: var(--mdb-modal-header-padding);
  border-bottom: var(--mdb-modal-header-border-width) solid var(--mdb-modal-header-border-color);
  border-top-left-radius: var(--mdb-modal-inner-border-radius);
  border-top-right-radius: var(--mdb-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--mdb-modal-header-padding-y) * .5) calc(var(--mdb-modal-header-padding-x) * .5);
  margin: calc(var(--mdb-modal-header-padding-y) * -.5) calc(var(--mdb-modal-header-padding-x) * -.5) calc(var(--mdb-modal-header-padding-y) * -.5) auto;
}

.modal-title {
  line-height: var(--mdb-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--mdb-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--mdb-modal-padding)  - var(--mdb-modal-footer-gap) * .5);
  background-color: var(--mdb-modal-footer-bg);
  border-top: var(--mdb-modal-footer-border-width) solid var(--mdb-modal-footer-border-color);
  border-bottom-right-radius: var(--mdb-modal-inner-border-radius);
  border-bottom-left-radius: var(--mdb-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--mdb-modal-footer-gap) * .5);
}

@media (width >= 576px) {
  .modal {
    --mdb-modal-margin: 1.75rem;
    --mdb-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .modal-dialog {
    max-width: var(--mdb-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --mdb-modal-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    --mdb-modal-width: 800px;
  }
}

@media (width >= 1200px) {
  .modal-xl {
    --mdb-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-footer, .modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-footer, .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-footer, .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-footer, .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-footer, .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-footer, .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.popover {
  --mdb-popover-zindex: 1080;
  --mdb-popover-max-width: 276px;
  --mdb-popover-font-size: .875rem;
  --mdb-popover-bg: #fff;
  --mdb-popover-border-width: 1px;
  --mdb-popover-border-color: #f5f5f5;
  --mdb-popover-border-radius: .5rem;
  --mdb-popover-inner-border-radius: calc(.5rem - 1px);
  --mdb-popover-box-shadow: 0 0px 3px 0 #00000012, 0 2px 2px 0 #0000000a;
  --mdb-popover-header-padding-x: 1rem;
  --mdb-popover-header-padding-y: .5rem;
  --mdb-popover-header-font-size: 1rem;
  --mdb-popover-header-bg: #fff;
  --mdb-popover-body-padding-x: 1rem;
  --mdb-popover-body-padding-y: 1rem;
  --mdb-popover-body-color: #4f4f4f;
  --mdb-popover-arrow-width: 1rem;
  --mdb-popover-arrow-height: .5rem;
  --mdb-popover-arrow-border: var(--mdb-popover-border-color);
  z-index: var(--mdb-popover-zindex);
  max-width: var(--mdb-popover-max-width);
  font-family: var(--mdb-font-roboto);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  font-size: var(--mdb-popover-font-size);
  word-wrap: break-word;
  background-color: var(--mdb-popover-bg);
  border: var(--mdb-popover-border-width) solid var(--mdb-popover-border-color);
  border-radius: var(--mdb-popover-border-radius);
  box-shadow: var(--mdb-popover-box-shadow);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--mdb-popover-arrow-width);
  height: var(--mdb-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:after, .popover .popover-arrow:before {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow, .bs-popover-top > .popover-arrow {
  bottom: calc(var(--mdb-popover-arrow-height) * -1 - var(--mdb-popover-border-width));
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-top > .popover-arrow:before {
  border-left-width: calc(var(--mdb-popover-arrow-width) * .5);
  border-bottom-width: 0;
  border-right-width: calc(var(--mdb-popover-arrow-width) * .5);
  border-top-width: var(--mdb-popover-arrow-height);
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:before {
  border-top-color: var(--mdb-popover-arrow-border);
  bottom: 0;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after, .bs-popover-top > .popover-arrow:after {
  bottom: var(--mdb-popover-border-width);
  border-top-color: var(--mdb-popover-bg);
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow, .bs-popover-end > .popover-arrow {
  left: calc(var(--mdb-popover-arrow-height) * -1 - var(--mdb-popover-border-width));
  width: var(--mdb-popover-arrow-height);
  height: var(--mdb-popover-arrow-width);
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-end > .popover-arrow:before {
  border-left-width: 0;
  border-bottom-width: calc(var(--mdb-popover-arrow-width) * .5);
  border-right-width: var(--mdb-popover-arrow-height);
  border-top-width: calc(var(--mdb-popover-arrow-width) * .5);
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:before {
  border-right-color: var(--mdb-popover-arrow-border);
  left: 0;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after, .bs-popover-end > .popover-arrow:after {
  left: var(--mdb-popover-border-width);
  border-right-color: var(--mdb-popover-bg);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow, .bs-popover-bottom > .popover-arrow {
  top: calc(var(--mdb-popover-arrow-height) * -1 - var(--mdb-popover-border-width));
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-bottom > .popover-arrow:before {
  border-left-width: calc(var(--mdb-popover-arrow-width) * .5);
  border-bottom-width: var(--mdb-popover-arrow-height);
  border-right-width: calc(var(--mdb-popover-arrow-width) * .5);
  border-top-width: 0;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: var(--mdb-popover-arrow-border);
  top: 0;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after, .bs-popover-bottom > .popover-arrow:after {
  top: var(--mdb-popover-border-width);
  border-bottom-color: var(--mdb-popover-bg);
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before, .bs-popover-bottom .popover-header:before {
  width: var(--mdb-popover-arrow-width);
  margin-left: calc(var(--mdb-popover-arrow-width) * -.5);
  content: "";
  border-bottom: var(--mdb-popover-border-width) solid var(--mdb-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow, .bs-popover-start > .popover-arrow {
  right: calc(var(--mdb-popover-arrow-height) * -1 - var(--mdb-popover-border-width));
  width: var(--mdb-popover-arrow-height);
  height: var(--mdb-popover-arrow-width);
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-start > .popover-arrow:before {
  border-left-width: var(--mdb-popover-arrow-height);
  border-bottom-width: calc(var(--mdb-popover-arrow-width) * .5);
  border-right-width: 0;
  border-top-width: calc(var(--mdb-popover-arrow-width) * .5);
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:before {
  border-left-color: var(--mdb-popover-arrow-border);
  right: 0;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after, .bs-popover-start > .popover-arrow:after {
  right: var(--mdb-popover-border-width);
  border-left-color: var(--mdb-popover-bg);
}

.popover-header {
  padding: var(--mdb-popover-header-padding-y) var(--mdb-popover-header-padding-x);
  font-size: var(--mdb-popover-header-font-size);
  color: var(--mdb-popover-header-color);
  background-color: var(--mdb-popover-header-bg);
  border-bottom: var(--mdb-popover-border-width) solid var(--mdb-popover-border-color);
  border-top-left-radius: var(--mdb-popover-inner-border-radius);
  border-top-right-radius: var(--mdb-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--mdb-popover-body-padding-y) var(--mdb-popover-body-padding-x);
  color: var(--mdb-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

.active.carousel-item-end, .carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%);
}

.active.carousel-item-start, .carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}

.carousel-control-next, .carousel-control-prev {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 2rem;
  height: 2rem;
  background-image: none;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-mdb-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-mdb-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-mdb-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-border, .spinner-grow {
  width: var(--mdb-spinner-width);
  height: var(--mdb-spinner-height);
  vertical-align: var(--mdb-spinner-vertical-align);
  animation: var(--mdb-spinner-animation-speed) linear infinite var(--mdb-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

.spinner-border {
  --mdb-spinner-width: 2rem;
  --mdb-spinner-height: 2rem;
  --mdb-spinner-vertical-align: -.125em;
  --mdb-spinner-border-width: .25em;
  --mdb-spinner-animation-speed: .75s;
  --mdb-spinner-animation-name: spinner-border;
  border: var(--mdb-spinner-border-width) solid;
  border-right-color: #0000;
}

.spinner-border-sm {
  --mdb-spinner-width: 1rem;
  --mdb-spinner-height: 1rem;
  --mdb-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --mdb-spinner-width: 2rem;
  --mdb-spinner-height: 2rem;
  --mdb-spinner-vertical-align: -.125em;
  --mdb-spinner-animation-speed: .75s;
  --mdb-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --mdb-spinner-width: 1rem;
  --mdb-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --mdb-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xl, .offcanvas-xxl {
  --mdb-offcanvas-zindex: 1045;
  --mdb-offcanvas-width: 400px;
  --mdb-offcanvas-height: 30vh;
  --mdb-offcanvas-padding-x: 1rem;
  --mdb-offcanvas-padding-y: 1rem;
  --mdb-offcanvas-bg: #fff;
  --mdb-offcanvas-border-width: 1px;
  --mdb-offcanvas-border-color: var(--mdb-border-color-translucent);
  --mdb-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (width <= 575.98px) {
  .offcanvas-sm {
    z-index: var(--mdb-offcanvas-zindex);
    max-width: 100%;
    color: var(--mdb-offcanvas-color);
    visibility: hidden;
    background-color: var(--mdb-offcanvas-bg);
    box-shadow: var(--mdb-offcanvas-box-shadow);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--mdb-offcanvas-width);
    border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--mdb-offcanvas-width);
    border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom, .offcanvas-sm.offcanvas-top {
    height: var(--mdb-offcanvas-height);
    max-height: 100%;
    left: 0;
    right: 0;
  }

  .offcanvas-sm.offcanvas-bottom {
    border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-sm.show:not(.hiding), .offcanvas-sm.showing {
    transform: none;
  }

  .offcanvas-sm.hiding, .offcanvas-sm.show, .offcanvas-sm.showing {
    visibility: visible;
  }
}

@media (width >= 576px) {
  .offcanvas-sm {
    --mdb-offcanvas-height: auto;
    --mdb-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md {
    z-index: var(--mdb-offcanvas-zindex);
    max-width: 100%;
    color: var(--mdb-offcanvas-color);
    visibility: hidden;
    background-color: var(--mdb-offcanvas-bg);
    box-shadow: var(--mdb-offcanvas-box-shadow);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--mdb-offcanvas-width);
    border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--mdb-offcanvas-width);
    border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom, .offcanvas-md.offcanvas-top {
    height: var(--mdb-offcanvas-height);
    max-height: 100%;
    left: 0;
    right: 0;
  }

  .offcanvas-md.offcanvas-bottom {
    border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-md.show:not(.hiding), .offcanvas-md.showing {
    transform: none;
  }

  .offcanvas-md.hiding, .offcanvas-md.show, .offcanvas-md.showing {
    visibility: visible;
  }
}

@media (width >= 768px) {
  .offcanvas-md {
    --mdb-offcanvas-height: auto;
    --mdb-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg {
    z-index: var(--mdb-offcanvas-zindex);
    max-width: 100%;
    color: var(--mdb-offcanvas-color);
    visibility: hidden;
    background-color: var(--mdb-offcanvas-bg);
    box-shadow: var(--mdb-offcanvas-box-shadow);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--mdb-offcanvas-width);
    border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--mdb-offcanvas-width);
    border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom, .offcanvas-lg.offcanvas-top {
    height: var(--mdb-offcanvas-height);
    max-height: 100%;
    left: 0;
    right: 0;
  }

  .offcanvas-lg.offcanvas-bottom {
    border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-lg.show:not(.hiding), .offcanvas-lg.showing {
    transform: none;
  }

  .offcanvas-lg.hiding, .offcanvas-lg.show, .offcanvas-lg.showing {
    visibility: visible;
  }
}

@media (width >= 992px) {
  .offcanvas-lg {
    --mdb-offcanvas-height: auto;
    --mdb-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl {
    z-index: var(--mdb-offcanvas-zindex);
    max-width: 100%;
    color: var(--mdb-offcanvas-color);
    visibility: hidden;
    background-color: var(--mdb-offcanvas-bg);
    box-shadow: var(--mdb-offcanvas-box-shadow);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--mdb-offcanvas-width);
    border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--mdb-offcanvas-width);
    border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom, .offcanvas-xl.offcanvas-top {
    height: var(--mdb-offcanvas-height);
    max-height: 100%;
    left: 0;
    right: 0;
  }

  .offcanvas-xl.offcanvas-bottom {
    border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xl.show:not(.hiding), .offcanvas-xl.showing {
    transform: none;
  }

  .offcanvas-xl.hiding, .offcanvas-xl.show, .offcanvas-xl.showing {
    visibility: visible;
  }
}

@media (width >= 1200px) {
  .offcanvas-xl {
    --mdb-offcanvas-height: auto;
    --mdb-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--mdb-offcanvas-zindex);
    max-width: 100%;
    color: var(--mdb-offcanvas-color);
    visibility: hidden;
    background-color: var(--mdb-offcanvas-bg);
    box-shadow: var(--mdb-offcanvas-box-shadow);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--mdb-offcanvas-width);
    border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--mdb-offcanvas-width);
    border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom, .offcanvas-xxl.offcanvas-top {
    height: var(--mdb-offcanvas-height);
    max-height: 100%;
    left: 0;
    right: 0;
  }

  .offcanvas-xxl.offcanvas-bottom {
    border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xxl.show:not(.hiding), .offcanvas-xxl.showing {
    transform: none;
  }

  .offcanvas-xxl.hiding, .offcanvas-xxl.show, .offcanvas-xxl.showing {
    visibility: visible;
  }
}

@media (width >= 1400px) {
  .offcanvas-xxl {
    --mdb-offcanvas-height: auto;
    --mdb-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--mdb-offcanvas-zindex);
  max-width: 100%;
  color: var(--mdb-offcanvas-color);
  visibility: hidden;
  background-color: var(--mdb-offcanvas-bg);
  box-shadow: var(--mdb-offcanvas-box-shadow);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--mdb-offcanvas-width);
  border-right: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--mdb-offcanvas-width);
  border-left: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  border-bottom: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
  top: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom, .offcanvas.offcanvas-top {
  height: var(--mdb-offcanvas-height);
  max-height: 100%;
  left: 0;
  right: 0;
}

.offcanvas.offcanvas-bottom {
  border-top: var(--mdb-offcanvas-border-width) solid var(--mdb-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.show:not(.hiding), .offcanvas.showing {
  transform: none;
}

.offcanvas.hiding, .offcanvas.show, .offcanvas.showing {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--mdb-offcanvas-padding-y) var(--mdb-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--mdb-offcanvas-padding-y) * .5) calc(var(--mdb-offcanvas-padding-x) * .5);
  margin-top: calc(var(--mdb-offcanvas-padding-y) * -.5);
  margin-right: calc(var(--mdb-offcanvas-padding-x) * -.5);
  margin-bottom: calc(var(--mdb-offcanvas-padding-y) * -.5);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.offcanvas-body {
  padding: var(--mdb-offcanvas-padding-y) var(--mdb-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.tooltip {
  --mdb-tooltip-zindex: 1090;
  --mdb-tooltip-max-width: 200px;
  --mdb-tooltip-padding-x: 16px;
  --mdb-tooltip-padding-y: 6px;
  --mdb-tooltip-font-size: .875rem;
  --mdb-tooltip-color: #fff;
  --mdb-tooltip-bg: #6d6d6d;
  --mdb-tooltip-border-radius: .25rem;
  --mdb-tooltip-opacity: .9;
  --mdb-tooltip-arrow-width: .8rem;
  --mdb-tooltip-arrow-height: .4rem;
  z-index: var(--mdb-tooltip-zindex);
  padding: var(--mdb-tooltip-arrow-height);
  margin: var(--mdb-tooltip-margin);
  font-family: var(--mdb-font-roboto);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  font-size: var(--mdb-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip .tooltip-arrow {
  width: var(--mdb-tooltip-arrow-width);
  height: var(--mdb-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before, .bs-tooltip-top .tooltip-arrow:before {
  border-left-width: calc(var(--mdb-tooltip-arrow-width) * .5);
  border-bottom-width: 0;
  border-right-width: calc(var(--mdb-tooltip-arrow-width) * .5);
  border-top-width: var(--mdb-tooltip-arrow-height);
  border-top-color: var(--mdb-tooltip-bg);
  top: -1px;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow, .bs-tooltip-end .tooltip-arrow {
  width: var(--mdb-tooltip-arrow-height);
  height: var(--mdb-tooltip-arrow-width);
  left: 0;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before, .bs-tooltip-end .tooltip-arrow:before {
  border-left-width: 0;
  border-bottom-width: calc(var(--mdb-tooltip-arrow-width) * .5);
  border-right-width: var(--mdb-tooltip-arrow-height);
  border-top-width: calc(var(--mdb-tooltip-arrow-width) * .5);
  border-right-color: var(--mdb-tooltip-bg);
  right: -1px;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before, .bs-tooltip-bottom .tooltip-arrow:before {
  border-left-width: calc(var(--mdb-tooltip-arrow-width) * .5);
  border-bottom-width: var(--mdb-tooltip-arrow-height);
  border-right-width: calc(var(--mdb-tooltip-arrow-width) * .5);
  border-top-width: 0;
  border-bottom-color: var(--mdb-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow {
  width: var(--mdb-tooltip-arrow-height);
  height: var(--mdb-tooltip-arrow-width);
  right: 0;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before, .bs-tooltip-start .tooltip-arrow:before {
  border-left-width: var(--mdb-tooltip-arrow-height);
  border-bottom-width: calc(var(--mdb-tooltip-arrow-width) * .5);
  border-right-width: 0;
  border-top-width: calc(var(--mdb-tooltip-arrow-width) * .5);
  border-left-color: var(--mdb-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--mdb-tooltip-max-width);
  padding: var(--mdb-tooltip-padding-y) var(--mdb-tooltip-padding-x);
  color: var(--mdb-tooltip-color);
  text-align: center;
  background-color: var(--mdb-tooltip-bg);
  border-radius: var(--mdb-tooltip-border-radius);
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(59, 113, 202, var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(159, 166, 178, var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(20, 164, 77, var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(220, 76, 100, var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(228, 161, 27, var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(84, 180, 211, var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(251, 251, 251, var(--mdb-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(51, 45, 45, var(--mdb-bg-opacity, 1)) !important;
}

.link-primary {
  color: #3b71ca !important;
}

.link-primary:focus, .link-primary:hover {
  color: #386bc0 !important;
}

.link-secondary {
  color: #9fa6b2 !important;
}

.link-secondary:focus, .link-secondary:hover {
  color: #a4aab6 !important;
}

.link-success {
  color: #14a44d !important;
}

.link-success:focus, .link-success:hover {
  color: #20a956 !important;
}

.link-danger {
  color: #dc4c64 !important;
}

.link-danger:focus, .link-danger:hover {
  color: #de556c !important;
}

.link-warning {
  color: #e4a11b !important;
}

.link-warning:focus, .link-warning:hover {
  color: #e5a626 !important;
}

.link-info {
  color: #54b4d3 !important;
}

.link-info:focus, .link-info:hover {
  color: #5db8d5 !important;
}

.link-light, .link-light:focus, .link-light:hover {
  color: #fbfbfb !important;
}

.link-dark {
  color: #332d2d !important;
}

.link-dark:focus, .link-dark:hover {
  color: #302b2b !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--mdb-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --mdb-aspect-ratio: 100%;
}

.ratio-4x3 {
  --mdb-aspect-ratio: 75%;
}

.ratio-16x9 {
  --mdb-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --mdb-aspect-ratio: 42.8571%;
}

.fixed-top {
  top: 0;
}

.fixed-bottom, .fixed-top {
  z-index: 1030;
  position: fixed;
  left: 0;
  right: 0;
}

.fixed-bottom {
  bottom: 0;
}

.sticky-top {
  top: 0;
}

.sticky-bottom, .sticky-top {
  z-index: 1020;
  position: sticky;
}

.sticky-bottom {
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    top: 0;
  }

  .sticky-sm-bottom, .sticky-sm-top {
    z-index: 1020;
    position: sticky;
  }

  .sticky-sm-bottom {
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    top: 0;
  }

  .sticky-md-bottom, .sticky-md-top {
    z-index: 1020;
    position: sticky;
  }

  .sticky-md-bottom {
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    top: 0;
  }

  .sticky-lg-bottom, .sticky-lg-top {
    z-index: 1020;
    position: sticky;
  }

  .sticky-lg-bottom {
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    top: 0;
  }

  .sticky-xl-bottom, .sticky-xl-top {
    z-index: 1020;
    position: sticky;
  }

  .sticky-xl-bottom {
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    top: 0;
  }

  .sticky-xxl-bottom, .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
  }

  .sticky-xxl-bottom {
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-items: center;
}

.hstack, .vstack {
  align-self: stretch;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: .05 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-15 {
  opacity: .15 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-35 {
  opacity: .35 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-45 {
  opacity: .45 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-55 {
  opacity: .55 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-65 {
  opacity: .65 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-85 {
  opacity: .85 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-95 {
  opacity: .95 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-0, .shadow-none {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: 0 0 2px #00000012, 0 1px 1px #0000000a !important;
}

.shadow-2 {
  box-shadow: 0 0 3px #00000012, 0 2px 2px #0000000a !important;
}

.shadow-3 {
  box-shadow: 0 2px 6px -1px #00000012, 0 6px 18px -1px #0000000a !important;
}

.shadow-4 {
  box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a !important;
}

.shadow-5 {
  box-shadow: 0 2px 25px -5px #00000012, 0 25px 21px -5px #0000000a !important;
}

.shadow-6 {
  box-shadow: 0 2px 35px -12px #00000036, 0 50px 40px -5px #0000000a !important;
}

.shadow-1-soft {
  box-shadow: 0 1px 5px #0000000d !important;
}

.shadow-2-soft {
  box-shadow: 0 2px 10px #0000000d !important;
}

.shadow-3-soft {
  box-shadow: 0 5px 15px #0000000d !important;
}

.shadow-4-soft {
  box-shadow: 0 10px 20px #0000000d !important;
}

.shadow-5-soft {
  box-shadow: 0 15px 30px #0000000d !important;
}

.shadow-6-soft {
  box-shadow: 0 20px 40px #0000000d !important;
}

.shadow-1-strong {
  box-shadow: 0 0 2px #00000029, 0 1px 1px #0000001a !important;
}

.shadow-2-strong {
  box-shadow: 0 0 3px #00000029, 0 2px 2px #0000001a !important;
}

.shadow-3-strong {
  box-shadow: 0 2px 6px -1px #00000029, 0 6px 18px -1px #0000001a !important;
}

.shadow-4-strong {
  box-shadow: 0 2px 15px -3px #00000029, 0 10px 20px -2px #0000001a !important;
}

.shadow-5-strong {
  box-shadow: 0 2px 25px -5px #00000029, 0 25px 21px -5px #0000001a !important;
}

.shadow-6-strong {
  box-shadow: 0 2px 35px -12px #00000042, 0 50px 40px -5px #0000001a !important;
}

.shadow-inner {
  box-shadow: inset 0 2px 4px #0000000f !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--mdb-border-width) var(--mdb-border-style) var(--mdb-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-primary-rgb), var(--mdb-border-opacity)) !important;
}

.border-secondary {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-secondary-rgb), var(--mdb-border-opacity)) !important;
}

.border-success {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-success-rgb), var(--mdb-border-opacity)) !important;
}

.border-danger {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-danger-rgb), var(--mdb-border-opacity)) !important;
}

.border-warning {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-warning-rgb), var(--mdb-border-opacity)) !important;
}

.border-info {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-info-rgb), var(--mdb-border-opacity)) !important;
}

.border-light {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-light-rgb), var(--mdb-border-opacity)) !important;
}

.border-dark {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-dark-rgb), var(--mdb-border-opacity)) !important;
}

.border-white {
  --mdb-border-opacity: 1;
  border-color: rgba(var(--mdb-white-rgb), var(--mdb-border-opacity)) !important;
}

.border-1 {
  --mdb-border-width: 1px;
}

.border-2 {
  --mdb-border-width: 2px;
}

.border-3 {
  --mdb-border-width: 3px;
}

.border-4 {
  --mdb-border-width: 4px;
}

.border-5 {
  --mdb-border-width: 5px;
}

.border-opacity-10 {
  --mdb-border-opacity: .1;
}

.border-opacity-25 {
  --mdb-border-opacity: .25;
}

.border-opacity-50 {
  --mdb-border-opacity: .5;
}

.border-opacity-75 {
  --mdb-border-opacity: .75;
}

.border-opacity-100 {
  --mdb-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mb-6 {
  margin-bottom: 3.5rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.mb-9 {
  margin-bottom: 6rem !important;
}

.mb-10 {
  margin-bottom: 8rem !important;
}

.mb-11 {
  margin-bottom: 10rem !important;
}

.mb-12 {
  margin-bottom: 12rem !important;
}

.mb-13 {
  margin-bottom: 14rem !important;
}

.mb-14 {
  margin-bottom: 16rem !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--mdb-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-primary-rgb), var(--mdb-text-opacity)) !important;
}

.text-secondary {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-secondary-rgb), var(--mdb-text-opacity)) !important;
}

.text-success {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-success-rgb), var(--mdb-text-opacity)) !important;
}

.text-danger {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-danger-rgb), var(--mdb-text-opacity)) !important;
}

.text-warning {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-warning-rgb), var(--mdb-text-opacity)) !important;
}

.text-info {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-info-rgb), var(--mdb-text-opacity)) !important;
}

.text-light {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-light-rgb), var(--mdb-text-opacity)) !important;
}

.text-dark {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-dark-rgb), var(--mdb-text-opacity)) !important;
}

.text-black {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-black-rgb), var(--mdb-text-opacity)) !important;
}

.text-white {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-white-rgb), var(--mdb-text-opacity)) !important;
}

.text-body {
  --mdb-text-opacity: 1;
  color: rgba(var(--mdb-body-color-rgb), var(--mdb-text-opacity)) !important;
}

.text-muted {
  --mdb-text-opacity: 1;
  color: #757575 !important;
}

.text-black-50 {
  --mdb-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --mdb-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-reset {
  --mdb-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --mdb-text-opacity: .25;
}

.text-opacity-50 {
  --mdb-text-opacity: .5;
}

.text-opacity-75 {
  --mdb-text-opacity: .75;
}

.text-opacity-100 {
  --mdb-text-opacity: 1;
}

.bg-primary {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-primary-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-secondary {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-secondary-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-success {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-success-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-danger {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-danger-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-warning {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-warning-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-info {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-info-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-light {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-light-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-dark {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-dark-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-black {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-black-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-white {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-white-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-body {
  --mdb-bg-opacity: 1;
  background-color: rgba(var(--mdb-body-bg-rgb), var(--mdb-bg-opacity)) !important;
}

.bg-transparent {
  --mdb-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-opacity-10 {
  --mdb-bg-opacity: .1;
}

.bg-opacity-25 {
  --mdb-bg-opacity: .25;
}

.bg-opacity-50 {
  --mdb-bg-opacity: .5;
}

.bg-opacity-75 {
  --mdb-bg-opacity: .75;
}

.bg-opacity-100 {
  --mdb-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--mdb-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--mdb-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--mdb-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--mdb-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--mdb-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: .375rem !important;
}

.rounded-5 {
  border-radius: .5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--mdb-border-radius-pill) !important;
}

.rounded-6 {
  border-radius: .75rem !important;
}

.rounded-7 {
  border-radius: 1rem !important;
}

.rounded-8 {
  border-radius: 1.25rem !important;
}

.rounded-9 {
  border-radius: 1.5rem !important;
}

.rounded-top {
  border-top-left-radius: var(--mdb-border-radius) !important;
}

.rounded-end, .rounded-top {
  border-top-right-radius: var(--mdb-border-radius) !important;
}

.rounded-bottom, .rounded-end {
  border-bottom-right-radius: var(--mdb-border-radius) !important;
}

.rounded-bottom, .rounded-start {
  border-bottom-left-radius: var(--mdb-border-radius) !important;
}

.rounded-start {
  border-top-left-radius: var(--mdb-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.ls-tighter {
  letter-spacing: -.05em !important;
}

.ls-tight {
  letter-spacing: -.025em !important;
}

.ls-normal {
  letter-spacing: 0 !important;
}

.ls-wide {
  letter-spacing: .025em !important;
}

.ls-wider {
  letter-spacing: .05em !important;
}

.ls-widest {
  letter-spacing: .1em !important;
}

.object-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.object-top {
  -o-object-position: top !important;
  object-position: top !important;
}

.object-center {
  -o-object-position: center !important;
  object-position: center !important;
}

.object-bottom {
  -o-object-position: bottom !important;
  object-position: bottom !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .mb-sm-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 12rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 14rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 16rem !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .mb-md-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 4rem !important;
  }

  .mb-md-8 {
    margin-bottom: 5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 6rem !important;
  }

  .mb-md-10 {
    margin-bottom: 8rem !important;
  }

  .mb-md-11 {
    margin-bottom: 10rem !important;
  }

  .mb-md-12 {
    margin-bottom: 12rem !important;
  }

  .mb-md-13 {
    margin-bottom: 14rem !important;
  }

  .mb-md-14 {
    margin-bottom: 16rem !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .mb-lg-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 12rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 14rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 16rem !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .mb-xl-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 12rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 14rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 16rem !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .mb-xxl-6 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 12rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 14rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 16rem !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.25rem !important;
  }

  .m-xxl-n2 {
    margin: -.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .me-xxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.diagonal-fractions {
  font-variant-numeric: diagonal-fractions;
}

.bg-super-light {
  --mdb-bg-super-light: #fbfbfb;
  background-color: var(--mdb-bg-super-light);
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.table-fixed {
  table-layout: fixed;
}

.table-auto {
  table-layout: auto;
}

.link-danger, .link-dark, .link-info, .link-light, .link-primary, .link-secondary, .link-success, .link-warning {
  transition: color .15s;
}

:root {
  --mdb-font-roboto: "Roboto", sans-serif;
  --mdb-bg-opacity: 1;
}

hr:not([size]).hr {
  --mdb-divider-height: 2px;
  --mdb-divider-bg: #f5f5f5;
  --mdb-divider-opacity: 1;
  --mdb-divider-blurry-bg: transparent;
  --mdb-divider-blurry-bg-image: linear-gradient(90deg, transparent, #666, transparent);
  --mdb-divider-blurry-height: 1px;
  --mdb-divider-blurry-opacity: .25;
  height: var(--mdb-divider-height);
  background-color: var(--mdb-divider-bg);
  opacity: var(--mdb-divider-opacity);
}

hr:not([size]).hr.hr-blurry {
  background-color: var(--mdb-divider-blurry-bg);
  background-image: var(--mdb-divider-blurry-bg-image);
  height: var(--mdb-divider-blurry-height);
  opacity: var(--mdb-divider-blurry-opacity);
}

hr:not([size]).vr {
  height: auto;
}

hr.hr, hr.vr {
  border-top: none !important;
}

.vr {
  --mdb-divider-width: 2px;
  --mdb-divider-bg: #f5f5f5;
  --mdb-divider-opacity: 1;
  width: var(--mdb-divider-width);
  background-color: var(--mdb-divider-bg);
  opacity: var(--mdb-divider-opacity);
}

.vr-blurry {
  --mdb-divider-blurry-vr-bg-image: linear-gradient(180deg, transparent, #666, transparent);
  --mdb-divider-blurry-vr-width: 1px;
  --mdb-divider-blurry-opacity: .25;
  background-image: var(--mdb-divider-blurry-vr-bg-image);
  width: var(--mdb-divider-blurry-vr-width);
  opacity: var(--mdb-divider-blurry-opacity);
}

a {
  text-decoration: none;
}

.note {
  --mdb-note-padding: 10px;
  --mdb-note-border-width: 6px;
  --mdb-note-border-radius: 5px;
  --mdb-note-strong-font-weight: 600;
  padding: var(--mdb-note-padding);
  border-left: var(--mdb-note-border-width) solid;
  border-radius: var(--mdb-note-border-radius);
}

.note strong {
  font-weight: var(--mdb-note-strong-font-weight);
}

.note-primary {
  background-color: #dfe7f6;
  border-color: #376fc8;
}

.note-secondary {
  background-color: #ebedef;
  border-color: #565e6c;
}

.note-success {
  background-color: #d6f0e0;
  border-color: #139647;
}

.note-danger {
  background-color: #f9e1e5;
  border-color: #d62e4a;
}

.note-warning {
  background-color: #fbf0da;
  border-color: #a17112;
}

.note-info {
  background-color: #def1f7;
  border-color: #2686a6;
}

.note-light {
  background-color: #f5f5f5;
  border-color: #8c8c8c;
}

.note-dark {
  background-color: #332e2e;
  border-color: #e8e8e8;
}

@media (width >= 1199px) {
  .w-responsive {
    width: 75%;
  }
}

.bg-primary {
  --mdb--bg-opacity: 1;
  background-color: rgba(59, 113, 202, var(--mdb--bg-opacity));
}

.bg-secondary {
  --mdb--bg-opacity: 1;
  background-color: rgba(159, 166, 178, var(--mdb--bg-opacity));
}

.bg-success {
  --mdb--bg-opacity: 1;
  background-color: rgba(20, 164, 77, var(--mdb--bg-opacity));
}

.bg-danger {
  --mdb--bg-opacity: 1;
  background-color: rgba(220, 76, 100, var(--mdb--bg-opacity));
}

.bg-warning {
  --mdb--bg-opacity: 1;
  background-color: rgba(228, 161, 27, var(--mdb--bg-opacity));
}

.bg-info {
  background-color: rgba(84, 180, 211, var(--mdb--bg-opacity));
}

.bg-info, .bg-light {
  --mdb--bg-opacity: 1;
}

.bg-light {
  background-color: rgba(251, 251, 251, var(--mdb--bg-opacity));
}

.bg-dark {
  --mdb--bg-opacity: 1;
  background-color: rgba(51, 45, 45, var(--mdb--bg-opacity));
}

#mdb-table-flag tr {
  cursor: pointer;
}

.mdb-flag-selected {
  text-align: center;
  max-width: 150px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 10px auto 0;
}

.mdb-selected-flag-text {
  max-width: 150px;
  margin: 0 auto;
}

i.flag:not(.icon) {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: baseline;
  backface-visibility: hidden;
  margin: 0 .5em 0 0;
  line-height: 11px;
}

i.flag:before, i.flag:not(.icon) {
  width: 16px;
  height: 11px;
  display: inline-block;
}

i.flag:before {
  content: "";
  background: url("https://mdbootstrap.com/img/svg/flags.png") -108px -1976px no-repeat;
}

i.flag-ad:before, i.flag-andorra:before {
  background-position: 0 0 !important;
}

i.flag-ae:before, i.flag-uae:before, i.flag-united-arab-emirates:before {
  background-position: 0 -26px !important;
}

i.flag-af:before, i.flag-afghanistan:before {
  background-position: 0 -52px !important;
}

i.flag-ag:before, i.flag-antigua:before {
  background-position: 0 -78px !important;
}

i.flag-ai:before, i.flag-anguilla:before {
  background-position: 0 -104px !important;
}

i.flag-al:before, i.flag-albania:before {
  background-position: 0 -130px !important;
}

i.flag-am:before, i.flag-armenia:before {
  background-position: 0 -156px !important;
}

i.flag-an:before, i.flag-netherlands-antilles:before {
  background-position: 0 -182px !important;
}

i.flag-angola:before, i.flag-ao:before {
  background-position: 0 -208px !important;
}

i.flag-ar:before, i.flag-argentina:before {
  background-position: 0 -234px !important;
}

i.flag-american-samoa:before, i.flag-as:before {
  background-position: 0 -260px !important;
}

i.flag-at:before, i.flag-austria:before {
  background-position: 0 -286px !important;
}

i.flag-au:before, i.flag-australia:before {
  background-position: 0 -312px !important;
}

i.flag-aruba:before, i.flag-aw:before {
  background-position: 0 -338px !important;
}

i.flag-aland-islands:before, i.flag-ax:before {
  background-position: 0 -364px !important;
}

i.flag-az:before, i.flag-azerbaijan:before {
  background-position: 0 -390px !important;
}

i.flag-ba:before, i.flag-bosnia:before {
  background-position: 0 -416px !important;
}

i.flag-barbados:before, i.flag-bb:before {
  background-position: 0 -442px !important;
}

i.flag-bangladesh:before, i.flag-bd:before {
  background-position: 0 -468px !important;
}

i.flag-be:before, i.flag-belgium:before {
  background-position: 0 -494px !important;
}

i.flag-bf:before, i.flag-burkina-faso:before {
  background-position: 0 -520px !important;
}

i.flag-bg:before, i.flag-bulgaria:before {
  background-position: 0 -546px !important;
}

i.flag-bahrain:before, i.flag-bh:before {
  background-position: 0 -572px !important;
}

i.flag-bi:before, i.flag-burundi:before {
  background-position: 0 -598px !important;
}

i.flag-benin:before, i.flag-bj:before {
  background-position: 0 -624px !important;
}

i.flag-bermuda:before, i.flag-bm:before {
  background-position: 0 -650px !important;
}

i.flag-bn:before, i.flag-brunei:before {
  background-position: 0 -676px !important;
}

i.flag-bo:before, i.flag-bolivia:before {
  background-position: 0 -702px !important;
}

i.flag-br:before, i.flag-brazil:before {
  background-position: 0 -728px !important;
}

i.flag-bahamas:before, i.flag-bs:before {
  background-position: 0 -754px !important;
}

i.flag-bhutan:before, i.flag-bt:before {
  background-position: 0 -780px !important;
}

i.flag-bouvet-island:before, i.flag-bv:before {
  background-position: 0 -806px !important;
}

i.flag-botswana:before, i.flag-bw:before {
  background-position: 0 -832px !important;
}

i.flag-belarus:before, i.flag-by:before {
  background-position: 0 -858px !important;
}

i.flag-belize:before, i.flag-bz:before {
  background-position: 0 -884px !important;
}

i.flag-ca:before, i.flag-canada:before {
  background-position: 0 -910px !important;
}

i.flag-cc:before, i.flag-cocos-islands:before {
  background-position: 0 -962px !important;
}

i.flag-cd:before, i.flag-congo:before {
  background-position: 0 -988px !important;
}

i.flag-central-african-republic:before, i.flag-cf:before {
  background-position: 0 -1014px !important;
}

i.flag-cg:before, i.flag-congo-brazzaville:before {
  background-position: 0 -1040px !important;
}

i.flag-ch:before, i.flag-switzerland:before {
  background-position: 0 -1066px !important;
}

i.flag-ci:before, i.flag-cote-divoire:before {
  background-position: 0 -1092px !important;
}

i.flag-ck:before, i.flag-cook-islands:before {
  background-position: 0 -1118px !important;
}

i.flag-chile:before, i.flag-cl:before {
  background-position: 0 -1144px !important;
}

i.flag-cameroon:before, i.flag-cm:before {
  background-position: 0 -1170px !important;
}

i.flag-china:before, i.flag-cn:before {
  background-position: 0 -1196px !important;
}

i.flag-co:before, i.flag-colombia:before {
  background-position: 0 -1222px !important;
}

i.flag-costa-rica:before, i.flag-cr:before {
  background-position: 0 -1248px !important;
}

i.flag-cs:before, i.flag-serbia:before {
  background-position: 0 -1274px !important;
}

i.flag-cu:before, i.flag-cuba:before {
  background-position: 0 -1300px !important;
}

i.flag-cape-verde:before, i.flag-cv:before {
  background-position: 0 -1326px !important;
}

i.flag-christmas-island:before, i.flag-cx:before {
  background-position: 0 -1352px !important;
}

i.flag-cy:before, i.flag-cyprus:before {
  background-position: 0 -1378px !important;
}

i.flag-cz:before, i.flag-czech-republic:before {
  background-position: 0 -1404px !important;
}

i.flag-de:before, i.flag-germany:before {
  background-position: 0 -1430px !important;
}

i.flag-dj:before, i.flag-djibouti:before {
  background-position: 0 -1456px !important;
}

i.flag-denmark:before, i.flag-dk:before {
  background-position: 0 -1482px !important;
}

i.flag-dm:before, i.flag-dominica:before {
  background-position: 0 -1508px !important;
}

i.flag-do:before, i.flag-dominican-republic:before {
  background-position: 0 -1534px !important;
}

i.flag-algeria:before, i.flag-dz:before {
  background-position: 0 -1560px !important;
}

i.flag-ec:before, i.flag-ecuador:before {
  background-position: 0 -1586px !important;
}

i.flag-ee:before, i.flag-estonia:before {
  background-position: 0 -1612px !important;
}

i.flag-eg:before, i.flag-egypt:before {
  background-position: 0 -1638px !important;
}

i.flag-eh:before, i.flag-western-sahara:before {
  background-position: 0 -1664px !important;
}

i.flag-england:before, i.flag-gb-eng:before {
  background-position: 0 -1690px !important;
}

i.flag-er:before, i.flag-eritrea:before {
  background-position: 0 -1716px !important;
}

i.flag-es:before, i.flag-spain:before {
  background-position: 0 -1742px !important;
}

i.flag-et:before, i.flag-ethiopia:before {
  background-position: 0 -1768px !important;
}

i.flag-eu:before, i.flag-european-union:before {
  background-position: 0 -1794px !important;
}

i.flag-fi:before, i.flag-finland:before {
  background-position: 0 -1846px !important;
}

i.flag-fiji:before, i.flag-fj:before {
  background-position: 0 -1872px !important;
}

i.flag-falkland-islands:before, i.flag-fk:before {
  background-position: 0 -1898px !important;
}

i.flag-fm:before, i.flag-micronesia:before {
  background-position: 0 -1924px !important;
}

i.flag-faroe-islands:before, i.flag-fo:before {
  background-position: 0 -1950px !important;
}

i.flag-fr:before, i.flag-france:before {
  background-position: 0 -1976px !important;
}

i.flag-ga:before, i.flag-gabon:before {
  background-position: -36px 0 !important;
}

i.flag-gb:before, i.flag-uk:before, i.flag-united-kingdom:before {
  background-position: -36px -26px !important;
}

i.flag-gd:before, i.flag-grenada:before {
  background-position: -36px -52px !important;
}

i.flag-ge:before, i.flag-georgia:before {
  background-position: -36px -78px !important;
}

i.flag-french-guiana:before, i.flag-gf:before {
  background-position: -36px -104px !important;
}

i.flag-gh:before, i.flag-ghana:before {
  background-position: -36px -130px !important;
}

i.flag-gi:before, i.flag-gibraltar:before {
  background-position: -36px -156px !important;
}

i.flag-gl:before, i.flag-greenland:before {
  background-position: -36px -182px !important;
}

i.flag-gambia:before, i.flag-gm:before {
  background-position: -36px -208px !important;
}

i.flag-gn:before, i.flag-guinea:before {
  background-position: -36px -234px !important;
}

i.flag-gp:before, i.flag-guadeloupe:before {
  background-position: -36px -260px !important;
}

i.flag-equatorial-guinea:before, i.flag-gq:before {
  background-position: -36px -286px !important;
}

i.flag-gr:before, i.flag-greece:before {
  background-position: -36px -312px !important;
}

i.flag-gs:before, i.flag-sandwich-islands:before {
  background-position: -36px -338px !important;
}

i.flag-gt:before, i.flag-guatemala:before {
  background-position: -36px -364px !important;
}

i.flag-gu:before, i.flag-guam:before {
  background-position: -36px -390px !important;
}

i.flag-guinea-bissau:before, i.flag-gw:before {
  background-position: -36px -416px !important;
}

i.flag-guyana:before, i.flag-gy:before {
  background-position: -36px -442px !important;
}

i.flag-hk:before, i.flag-hong-kong:before {
  background-position: -36px -468px !important;
}

i.flag-heard-island:before, i.flag-hm:before {
  background-position: -36px -494px !important;
}

i.flag-hn:before, i.flag-honduras:before {
  background-position: -36px -520px !important;
}

i.flag-croatia:before, i.flag-hr:before {
  background-position: -36px -546px !important;
}

i.flag-haiti:before, i.flag-ht:before {
  background-position: -36px -572px !important;
}

i.flag-hu:before, i.flag-hungary:before {
  background-position: -36px -598px !important;
}

i.flag-id:before, i.flag-indonesia:before {
  background-position: -36px -624px !important;
}

i.flag-ie:before, i.flag-ireland:before {
  background-position: -36px -650px !important;
}

i.flag-il:before, i.flag-israel:before {
  background-position: -36px -676px !important;
}

i.flag-in:before, i.flag-india:before {
  background-position: -36px -702px !important;
}

i.flag-indian-ocean-territory:before, i.flag-io:before {
  background-position: -36px -728px !important;
}

i.flag-iq:before, i.flag-iraq:before {
  background-position: -36px -754px !important;
}

i.flag-ir:before, i.flag-iran:before {
  background-position: -36px -780px !important;
}

i.flag-iceland:before, i.flag-is:before {
  background-position: -36px -806px !important;
}

i.flag-it:before, i.flag-italy:before {
  background-position: -36px -832px !important;
}

i.flag-jamaica:before, i.flag-jm:before {
  background-position: -36px -858px !important;
}

i.flag-jo:before, i.flag-jordan:before {
  background-position: -36px -884px !important;
}

i.flag-japan:before, i.flag-jp:before {
  background-position: -36px -910px !important;
}

i.flag-ke:before, i.flag-kenya:before {
  background-position: -36px -936px !important;
}

i.flag-kg:before, i.flag-kyrgyzstan:before {
  background-position: -36px -962px !important;
}

i.flag-cambodia:before, i.flag-kh:before {
  background-position: -36px -988px !important;
}

i.flag-ki:before, i.flag-kiribati:before {
  background-position: -36px -1014px !important;
}

i.flag-comoros:before, i.flag-km:before {
  background-position: -36px -1040px !important;
}

i.flag-kn:before, i.flag-saint-kitts-and-nevis:before {
  background-position: -36px -1066px !important;
}

i.flag-kp:before, i.flag-north-korea:before {
  background-position: -36px -1092px !important;
}

i.flag-kr:before, i.flag-south-korea:before {
  background-position: -36px -1118px !important;
}

i.flag-kuwait:before, i.flag-kw:before {
  background-position: -36px -1144px !important;
}

i.flag-cayman-islands:before, i.flag-ky:before {
  background-position: -36px -1170px !important;
}

i.flag-kazakhstan:before, i.flag-kz:before {
  background-position: -36px -1196px !important;
}

i.flag-la:before, i.flag-laos:before {
  background-position: -36px -1222px !important;
}

i.flag-lb:before, i.flag-lebanon:before {
  background-position: -36px -1248px !important;
}

i.flag-lc:before, i.flag-saint-lucia:before {
  background-position: -36px -1274px !important;
}

i.flag-li:before, i.flag-liechtenstein:before {
  background-position: -36px -1300px !important;
}

i.flag-lk:before, i.flag-sri-lanka:before {
  background-position: -36px -1326px !important;
}

i.flag-liberia:before, i.flag-lr:before {
  background-position: -36px -1352px !important;
}

i.flag-lesotho:before, i.flag-ls:before {
  background-position: -36px -1378px !important;
}

i.flag-lithuania:before, i.flag-lt:before {
  background-position: -36px -1404px !important;
}

i.flag-lu:before, i.flag-luxembourg:before {
  background-position: -36px -1430px !important;
}

i.flag-latvia:before, i.flag-lv:before {
  background-position: -36px -1456px !important;
}

i.flag-libya:before, i.flag-ly:before {
  background-position: -36px -1482px !important;
}

i.flag-ma:before, i.flag-morocco:before {
  background-position: -36px -1508px !important;
}

i.flag-mc:before, i.flag-monaco:before {
  background-position: -36px -1534px !important;
}

i.flag-md:before, i.flag-moldova:before {
  background-position: -36px -1560px !important;
}

i.flag-me:before, i.flag-montenegro:before {
  background-position: -36px -1586px !important;
}

i.flag-madagascar:before, i.flag-mg:before {
  background-position: -36px -1613px !important;
}

i.flag-marshall-islands:before, i.flag-mh:before {
  background-position: -36px -1639px !important;
}

i.flag-macedonia:before, i.flag-mk:before {
  background-position: -36px -1665px !important;
}

i.flag-mali:before, i.flag-ml:before {
  background-position: -36px -1691px !important;
}

i.flag-burma:before, i.flag-mm:before, i.flag-myanmar:before {
  background-position: -73px -1821px !important;
}

i.flag-mn:before, i.flag-mongolia:before {
  background-position: -36px -1743px !important;
}

i.flag-macau:before, i.flag-mo:before {
  background-position: -36px -1769px !important;
}

i.flag-mp:before, i.flag-northern-mariana-islands:before {
  background-position: -36px -1795px !important;
}

i.flag-martinique:before, i.flag-mq:before {
  background-position: -36px -1821px !important;
}

i.flag-mauritania:before, i.flag-mr:before {
  background-position: -36px -1847px !important;
}

i.flag-montserrat:before, i.flag-ms:before {
  background-position: -36px -1873px !important;
}

i.flag-malta:before, i.flag-mt:before {
  background-position: -36px -1899px !important;
}

i.flag-mauritius:before, i.flag-mu:before {
  background-position: -36px -1925px !important;
}

i.flag-maldives:before, i.flag-mv:before {
  background-position: -36px -1951px !important;
}

i.flag-malawi:before, i.flag-mw:before {
  background-position: -36px -1977px !important;
}

i.flag-mexico:before, i.flag-mx:before {
  background-position: -72px 0 !important;
}

i.flag-malaysia:before, i.flag-my:before {
  background-position: -72px -26px !important;
}

i.flag-mozambique:before, i.flag-mz:before {
  background-position: -72px -52px !important;
}

i.flag-na:before, i.flag-namibia:before {
  background-position: -72px -78px !important;
}

i.flag-nc:before, i.flag-new-caledonia:before {
  background-position: -72px -104px !important;
}

i.flag-ne:before, i.flag-niger:before {
  background-position: -72px -130px !important;
}

i.flag-nf:before, i.flag-norfolk-island:before {
  background-position: -72px -156px !important;
}

i.flag-ng:before, i.flag-nigeria:before {
  background-position: -72px -182px !important;
}

i.flag-ni:before, i.flag-nicaragua:before {
  background-position: -72px -208px !important;
}

i.flag-netherlands:before, i.flag-nl:before {
  background-position: -72px -234px !important;
}

i.flag-no:before, i.flag-norway:before {
  background-position: -72px -260px !important;
}

i.flag-nepal:before, i.flag-np:before {
  background-position: -72px -286px !important;
}

i.flag-nauru:before, i.flag-nr:before {
  background-position: -72px -312px !important;
}

i.flag-niue:before, i.flag-nu:before {
  background-position: -72px -338px !important;
}

i.flag-new-zealand:before, i.flag-nz:before {
  background-position: -72px -364px !important;
}

i.flag-om:before, i.flag-oman:before {
  background-position: -72px -390px !important;
}

i.flag-pa:before, i.flag-panama:before {
  background-position: -72px -416px !important;
}

i.flag-pe:before, i.flag-peru:before {
  background-position: -72px -442px !important;
}

i.flag-french-polynesia:before, i.flag-pf:before {
  background-position: -72px -468px !important;
}

i.flag-new-guinea:before, i.flag-pg:before {
  background-position: -72px -494px !important;
}

i.flag-ph:before, i.flag-philippines:before {
  background-position: -72px -520px !important;
}

i.flag-pakistan:before, i.flag-pk:before {
  background-position: -72px -546px !important;
}

i.flag-pl:before, i.flag-poland:before {
  background-position: -72px -572px !important;
}

i.flag-pm:before, i.flag-saint-pierre:before {
  background-position: -72px -598px !important;
}

i.flag-pitcairn-islands:before, i.flag-pn:before {
  background-position: -72px -624px !important;
}

i.flag-pr:before, i.flag-puerto-rico:before {
  background-position: -72px -650px !important;
}

i.flag-palestine:before, i.flag-ps:before {
  background-position: -72px -676px !important;
}

i.flag-portugal:before, i.flag-pt:before {
  background-position: -72px -702px !important;
}

i.flag-palau:before, i.flag-pw:before {
  background-position: -72px -728px !important;
}

i.flag-paraguay:before, i.flag-py:before {
  background-position: -72px -754px !important;
}

i.flag-qa:before, i.flag-qatar:before {
  background-position: -72px -780px !important;
}

i.flag-re:before, i.flag-reunion:before {
  background-position: -72px -806px !important;
}

i.flag-ro:before, i.flag-romania:before {
  background-position: -72px -832px !important;
}

i.flag-rs:before, i.flag-serbia:before {
  background-position: -72px -858px !important;
}

i.flag-ru:before, i.flag-russia:before {
  background-position: -72px -884px !important;
}

i.flag-rw:before, i.flag-rwanda:before {
  background-position: -72px -910px !important;
}

i.flag-sa:before, i.flag-saudi-arabia:before {
  background-position: -72px -936px !important;
}

i.flag-sb:before, i.flag-solomon-islands:before {
  background-position: -72px -962px !important;
}

i.flag-sc:before, i.flag-seychelles:before {
  background-position: -72px -988px !important;
}

i.flag-gb-sct:before, i.flag-scotland:before {
  background-position: -72px -1014px !important;
}

i.flag-sd:before, i.flag-sudan:before {
  background-position: -72px -1040px !important;
}

i.flag-se:before, i.flag-sweden:before {
  background-position: -72px -1066px !important;
}

i.flag-sg:before, i.flag-singapore:before {
  background-position: -72px -1092px !important;
}

i.flag-saint-helena:before, i.flag-sh:before {
  background-position: -72px -1118px !important;
}

i.flag-si:before, i.flag-slovenia:before {
  background-position: -72px -1144px !important;
}

i.flag-jan-mayen:before, i.flag-sj:before, i.flag-svalbard:before {
  background-position: -72px -1170px !important;
}

i.flag-sk:before, i.flag-slovakia:before {
  background-position: -72px -1196px !important;
}

i.flag-sierra-leone:before, i.flag-sl:before {
  background-position: -72px -1222px !important;
}

i.flag-san-marino:before, i.flag-sm:before {
  background-position: -72px -1248px !important;
}

i.flag-senegal:before, i.flag-sn:before {
  background-position: -72px -1274px !important;
}

i.flag-so:before, i.flag-somalia:before {
  background-position: -72px -1300px !important;
}

i.flag-sr:before, i.flag-suriname:before {
  background-position: -72px -1326px !important;
}

i.flag-sao-tome:before, i.flag-st:before {
  background-position: -72px -1352px !important;
}

i.flag-el-salvador:before, i.flag-sv:before {
  background-position: -72px -1378px !important;
}

i.flag-sy:before, i.flag-syria:before {
  background-position: -72px -1404px !important;
}

i.flag-swaziland:before, i.flag-sz:before {
  background-position: -72px -1430px !important;
}

i.flag-caicos-islands:before, i.flag-tc:before {
  background-position: -72px -1456px !important;
}

i.flag-chad:before, i.flag-td:before {
  background-position: -72px -1482px !important;
}

i.flag-french-territories:before, i.flag-tf:before {
  background-position: -72px -1508px !important;
}

i.flag-tg:before, i.flag-togo:before {
  background-position: -72px -1534px !important;
}

i.flag-th:before, i.flag-thailand:before {
  background-position: -72px -1560px !important;
}

i.flag-tajikistan:before, i.flag-tj:before {
  background-position: -72px -1586px !important;
}

i.flag-tk:before, i.flag-tokelau:before {
  background-position: -72px -1612px !important;
}

i.flag-timorleste:before, i.flag-tl:before {
  background-position: -72px -1638px !important;
}

i.flag-tm:before, i.flag-turkmenistan:before {
  background-position: -72px -1664px !important;
}

i.flag-tn:before, i.flag-tunisia:before {
  background-position: -72px -1690px !important;
}

i.flag-to:before, i.flag-tonga:before {
  background-position: -72px -1716px !important;
}

i.flag-tr:before, i.flag-turkey:before {
  background-position: -72px -1742px !important;
}

i.flag-trinidad:before, i.flag-tt:before {
  background-position: -72px -1768px !important;
}

i.flag-tuvalu:before, i.flag-tv:before {
  background-position: -72px -1794px !important;
}

i.flag-taiwan:before, i.flag-tw:before {
  background-position: -72px -1820px !important;
}

i.flag-tanzania:before, i.flag-tz:before {
  background-position: -72px -1846px !important;
}

i.flag-ua:before, i.flag-ukraine:before {
  background-position: -72px -1872px !important;
}

i.flag-ug:before, i.flag-uganda:before {
  background-position: -72px -1898px !important;
}

i.flag-um:before, i.flag-us-minor-islands:before {
  background-position: -72px -1924px !important;
}

i.flag-america:before, i.flag-united-states:before, i.flag-us:before {
  background-position: -72px -1950px !important;
}

i.flag-uruguay:before, i.flag-uy:before {
  background-position: -72px -1976px !important;
}

i.flag-uz:before, i.flag-uzbekistan:before {
  background-position: -108px 0 !important;
}

i.flag-va:before, i.flag-vatican-city:before {
  background-position: -108px -26px !important;
}

i.flag-saint-vincent:before, i.flag-vc:before {
  background-position: -108px -52px !important;
}

i.flag-ve:before, i.flag-venezuela:before {
  background-position: -108px -78px !important;
}

i.flag-british-virgin-islands:before, i.flag-vg:before {
  background-position: -108px -104px !important;
}

i.flag-us-virgin-islands:before, i.flag-vi:before {
  background-position: -108px -130px !important;
}

i.flag-vietnam:before, i.flag-vn:before {
  background-position: -108px -156px !important;
}

i.flag-vanuatu:before, i.flag-vu:before {
  background-position: -108px -182px !important;
}

i.flag-gb-wls:before, i.flag-wales:before {
  background-position: -108px -208px !important;
}

i.flag-wallis-and-futuna:before, i.flag-wf:before {
  background-position: -108px -234px !important;
}

i.flag-samoa:before, i.flag-ws:before {
  background-position: -108px -260px !important;
}

i.flag-ye:before, i.flag-yemen:before {
  background-position: -108px -286px !important;
}

i.flag-mayotte:before, i.flag-yt:before {
  background-position: -108px -312px !important;
}

i.flag-south-africa:before, i.flag-za:before {
  background-position: -108px -338px !important;
}

i.flag-zambia:before, i.flag-zm:before {
  background-position: -108px -364px !important;
}

i.flag-zimbabwe:before, i.flag-zw:before {
  background-position: -108px -390px !important;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.mask {
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.hover-overlay .mask {
  --mdb-image-hover-transition: all .3s ease-in-out;
  opacity: 0;
  transition: var(--mdb-image-hover-transition);
}

.hover-overlay .mask:hover {
  opacity: 1;
}

.hover-zoom {
  --mdb-image-hover-zoom-transition: all .3s linear;
  --mdb-image-hover-zoom-transform: scale(1.1);
}

.hover-zoom img, .hover-zoom video {
  transition: var(--mdb-image-hover-zoom-transition);
}

.hover-zoom:hover img, .hover-zoom:hover video {
  transform: var(--mdb-image-hover-zoom-transform);
}

.card.hover-shadow, .card.hover-shadow-soft, .hover-shadow, .hover-shadow-soft {
  --mdb-image-hover-shadow-transition: all .3s ease-in-out;
  transition: var(--mdb-image-hover-shadow-transition);
}

.card.hover-shadow-soft:hover, .card.hover-shadow:hover, .hover-shadow-soft:hover, .hover-shadow:hover {
  transition: var(--mdb-image-hover-shadow-transition);
}

.card.hover-shadow, .hover-shadow {
  --mdb-image-hover-shadow-box-shadow: 0 2px 15px -3px #00000029, 0 10px 20px -2px #0000001a;
  box-shadow: none;
}

.card.hover-shadow:hover, .hover-shadow:hover {
  box-shadow: var(--mdb-image-hover-shadow-box-shadow);
}

.card.hover-shadow-soft, .hover-shadow-soft {
  --mdb-image-hover-shadow-box-shadow-soft: 0 2px 25px -5px #00000012, 0 25px 21px -5px #0000000a;
  box-shadow: none;
}

.card.hover-shadow-soft:hover, .hover-shadow-soft:hover {
  box-shadow: var(--mdb-image-hover-shadow-box-shadow-soft);
}

.form-control {
  min-height: auto;
  padding: 4.5px 12px 3.68px;
}

.form-control, .form-control:focus {
  box-shadow: none;
  transition: all .1s linear;
}

.form-control:focus {
  border-color: #3b71ca;
  box-shadow: inset 0 0 0 1px #3b71ca;
}

.form-control.form-control-sm {
  font-size: .775rem;
  line-height: 1.5;
}

.form-control.form-control-lg {
  border-radius: .25rem;
  line-height: 2.15;
}

.form-outline {
  width: 100%;
  position: relative;
}

.form-outline .form-helper {
  width: 100%;
  color: #757575;
  font-size: .875em;
  position: absolute;
}

.form-outline .form-helper .form-counter {
  text-align: right;
}

.form-outline .trailing {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: auto;
  right: 10px;
  transform: translateY(-50%);
}

.form-outline .form-icon-trailing {
  padding-right: 2rem !important;
}

.form-outline .form-control {
  min-height: auto;
  background: none;
  border: 0;
  padding: .32rem .75rem;
  transition: all .2s linear;
}

.form-outline .form-control ~ .form-label {
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  transform-origin: 0 0;
  color: #0009;
  margin-bottom: 0;
  padding-top: .37rem;
  transition: all .2s ease-out;
  position: absolute;
  top: 0;
  left: .75rem;
  overflow: hidden;
}

.form-outline .form-control ~ .form-notch {
  width: 100%;
  max-width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.form-outline .form-control ~ .form-notch div {
  pointer-events: none;
  box-sizing: border-box;
  background: none;
  border: 1px solid #bdbdbd;
  transition: all .2s linear;
}

.form-outline .form-control ~ .form-notch .form-notch-leading {
  height: 100%;
  width: .5rem;
  border-right: none;
  border-radius: .25rem 0 0 .25rem;
  top: 0;
  left: 0;
}

.form-outline .form-control ~ .form-notch .form-notch-middle {
  width: auto;
  max-width: calc(100% - 1rem);
  height: 100%;
  border-left: none;
  border-right: none;
  flex: none;
}

.form-outline .form-control ~ .form-notch .form-notch-trailing {
  height: 100%;
  border-left: none;
  border-radius: 0 .25rem .25rem 0;
  flex-grow: 1;
}

.form-outline .form-control:not(.placeholder-active)::-moz-placeholder {
  opacity: 0;
}

.form-outline .form-control:not(.placeholder-active)::placeholder {
  opacity: 0;
}

.form-outline .form-control.active::placeholder, .form-outline .form-control:focus::placeholder {
  opacity: 1;
}

.form-outline .form-control:focus {
  box-shadow: none !important;
}

.form-outline .form-control.active ~ .form-label, .form-outline .form-control:focus ~ .form-label {
  transform: translateY(-1rem)translateY(.1rem)scale(.8);
}

.form-outline .form-control:focus ~ .form-label {
  color: #3b71ca;
}

.form-outline .form-control.active ~ .form-notch .form-notch-middle, .form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
  border-left: none;
  border-right: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border-color: #0000 #3b71ca #3b71ca;
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: 0 1px #3b71ca;
}

.form-outline .form-control.active ~ .form-notch .form-notch-leading, .form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border-right: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border-color: #3b71ca;
  box-shadow: -1px 0 #3b71ca, 0 1px #3b71ca, 0 -1px #3b71ca;
}

.form-outline .form-control.active ~ .form-notch .form-notch-trailing, .form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-left: none;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: #3b71ca;
  box-shadow: 1px 0 #3b71ca, 0 -1px #3b71ca, 0 1px #3b71ca;
}

.form-outline .form-control.disabled, .form-outline .form-control:disabled, .form-outline .form-control[readonly] {
  background-color: #e9ecef;
}

.form-outline .form-control.form-control-lg {
  font-size: 1rem;
  line-height: 2.15;
}

.form-outline .form-control.form-control-lg ~ .form-label {
  padding-top: .7rem;
}

.form-outline .form-control.form-control-lg.active ~ .form-label, .form-outline .form-control.form-control-lg:focus ~ .form-label {
  transform: translateY(-1.25rem)translateY(.1rem)scale(.8);
}

.form-outline .form-control.form-control-sm {
  padding-top: .32rem;
  padding-bottom: .32rem;
  font-size: .775rem;
  line-height: 1.5;
}

.form-outline .form-control.form-control-sm ~ .form-label {
  padding-top: .33rem;
  font-size: .775rem;
}

.form-outline .form-control.form-control-sm.active ~ .form-label, .form-outline .form-control.form-control-sm:focus ~ .form-label {
  transform: translateY(-.85rem)translateY(.1rem)scale(.8);
}

.form-outline.form-white .form-control {
  color: #fff;
}

.form-outline.form-white .form-control ~ .form-label {
  color: #fbfbfb;
}

.form-outline.form-white .form-control ~ .form-notch div {
  border-color: #fbfbfb;
}

.form-outline.form-white .form-control:focus ~ .form-label {
  color: #fff;
}

.form-outline.form-white .form-control:focus ~ .form-notch .form-notch-middle {
  border-color: #0000 #fff #fff;
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: 0 1px #fff;
}

.form-outline.form-white .form-control:focus ~ .form-notch .form-notch-leading {
  border-color: #fff;
  box-shadow: -1px 0 #fff, 0 1px #fff, 0 -1px #fff;
}

.form-outline.form-white .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: #fff;
  box-shadow: 1px 0 #fff, 0 -1px #fff, 0 1px #fff;
}

.form-outline.form-white .form-control::placeholder {
  color: #ffffffb3;
}

.form-outline.form-white .form-control.disabled, .form-outline.form-white .form-control:disabled, .form-outline.form-white .form-control[readonly] {
  background-color: #ffffff73;
}

.select-input.form-control[readonly]:not([disabled]) {
  background-color: #0000;
}

.form-select {
  transition: all .2s linear;
}

.form-select:focus {
  border-color: #3b71ca;
  outline: 0;
  box-shadow: inset 0 0 0 1px #3b71ca;
}

.form-check {
  min-height: 1.5rem;
}

.form-check-input {
  width: 1.125rem;
  height: 1.125rem;
  background-color: #fff;
  border: .125rem solid #00000040;
  position: relative;
}

.form-check-input:before {
  content: "";
  width: .875rem;
  height: .875rem;
  opacity: 0;
  pointer-events: none;
  background-color: #0000;
  border-radius: 50%;
  position: absolute;
  transform: scale(0);
  box-shadow: 0 0 0 13px #0000;
}

.form-check-input:hover {
  cursor: pointer;
}

.form-check-input:hover:before {
  opacity: .04;
  box-shadow: 0 0 0 13px #0009;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: #00000040;
  transition: border-color .2s;
}

.form-check-input:focus:before {
  opacity: .12;
  transition: box-shadow .2s, transform .2s;
  transform: scale(1);
  box-shadow: 0 0 0 13px #0009;
}

.form-check-input:checked {
  border-color: #3b71ca;
}

.form-check-input:checked:before {
  opacity: .16;
}

.form-check-input:checked:after {
  content: "";
  position: absolute;
}

.form-check-input:checked:focus {
  border-color: #3b71ca;
}

.form-check-input:checked:focus:before {
  transition: box-shadow .2s, transform .2s;
  transform: scale(1);
  box-shadow: 0 0 0 13px #3b71ca;
}

.form-check-input:indeterminate:focus:before {
  box-shadow: 0 0 0 13px #3b71ca;
}

.form-check-input[type="checkbox"] {
  border-radius: .25rem;
  margin-top: .19em;
  margin-right: 6px;
}

.form-check-input[type="checkbox"]:focus:after {
  content: "";
  width: .875rem;
  height: .875rem;
  z-index: 1;
  background-color: #fff;
  border-radius: 0;
  display: block;
  position: absolute;
}

.form-check-input[type="checkbox"]:checked {
  background-color: #3b71ca;
  background-image: none;
}

.form-check-input[type="checkbox"]:checked:after {
  width: .375rem;
  height: .8125rem;
  background-color: #0000;
  border: .125rem solid #fff;
  border-top: 0;
  border-left: 0;
  margin-top: -1px;
  margin-left: .25rem;
  display: block;
  transform: rotate(45deg);
}

.form-check-input[type="checkbox"]:checked:focus {
  background-color: #3b71ca;
}

.form-check-input[type="checkbox"]:indeterminate {
  border-color: #3b71ca;
}

.form-check-input[type="radio"] {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  margin-top: .125em;
  margin-right: 4px;
}

.form-check-input[type="radio"]:before {
  width: 1rem;
  height: 1rem;
}

.form-check-input[type="radio"]:after {
  content: "";
  width: 1rem;
  height: 1rem;
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  display: block;
  position: absolute;
}

.form-check-input[type="radio"]:checked {
  background-color: #fff;
  background-image: none;
}

.form-check-input[type="radio"]:checked:after {
  width: .625rem;
  height: .625rem;
  background-color: #3b71ca;
  border-color: #3b71ca;
  border-radius: 50%;
  transition: border-color;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-check-input[type="radio"]:checked:focus {
  background-color: #fff;
}

.form-check-label {
  padding-left: .15rem;
}

.form-check-label:hover {
  cursor: pointer;
}

.form-switch .form-check-input {
  width: 2rem;
  height: .875rem;
  background-color: #00000040;
  background-image: none;
  border-width: 0;
  border-radius: .4375rem;
  margin-top: .3em;
  margin-right: 8px;
}

.form-switch .form-check-input:after {
  content: "";
  z-index: 2;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  margin-top: -.1875rem;
  transition: background-color .2s, transform .2s;
  position: absolute;
  box-shadow: 0 0 3px #00000012, 0 2px 2px #0000000a;
}

.form-switch .form-check-input:focus {
  background-image: none;
}

.form-switch .form-check-input:focus:before {
  transition: box-shadow .2s, transform .2s;
  transform: scale(1);
  box-shadow: 3px -1px 0 13px #0009;
}

.form-switch .form-check-input:focus:after {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
}

.form-switch .form-check-input:checked, .form-switch .form-check-input:checked:focus {
  background-image: none;
}

.form-switch .form-check-input:checked:focus:before {
  margin-left: 1.0625rem;
  transition: box-shadow .2s, transform .2s;
  transform: scale(1);
  box-shadow: 3px -1px 0 13px #3b71ca;
}

.form-switch .form-check-input:checked[type="checkbox"] {
  background-image: none;
}

.form-switch .form-check-input:checked[type="checkbox"]:after {
  content: "";
  z-index: 2;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #3b71ca;
  border: none;
  border-radius: 50%;
  margin-top: -3px;
  margin-left: 1.0625rem;
  transition: background-color .2s, transform .2s;
  position: absolute;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .form-control[type="file"]::-webkit-file-upload-button {
  background-color: #0000;
}

.input-group {
  flex-wrap: nowrap;
}

.input-group > .form-control {
  min-height: calc(2.08rem + 2px);
  height: calc(2.08rem + 2px);
  padding-top: .27rem;
  padding-bottom: .27rem;
  transition: all .2s linear;
}

.input-group > .form-control:focus {
  border-color: #3b71ca;
  outline: 0;
  transition: all .2s linear;
  box-shadow: inset 0 0 0 1px #3b71ca;
}

.input-group-text {
  background-color: #0000;
  padding-top: .26rem;
  padding-bottom: .26rem;
}

.input-group-text > .form-check-input[type="checkbox"] {
  margin-left: 1px;
  margin-right: 1px;
}

.input-group-text > .form-check-input[type="radio"] {
  margin-right: 0;
}

.input-group-lg > .form-control {
  height: calc(2.645rem + 2px);
  padding-top: .33rem;
  padding-bottom: .33rem;
  font-size: 1rem;
}

.input-group-lg .input-group-text {
  font-size: 1rem;
}

.input-group-sm > .form-control {
  min-height: calc(1.66rem + 2px);
  height: calc(1.66rem + 2px);
  padding-top: .33rem;
  padding-bottom: .33rem;
  font-size: .775rem;
}

.input-group-sm .input-group-text {
  font-size: .775rem;
  line-height: 1.5;
}

.input-group.form-outline .input-group-text {
  border-left: 0;
}

.input-group.form-outline input + .input-group-text {
  border: 0;
  border-left: 1px solid #bdbdbd;
}

.input-group .form-outline:not(:first-child), .input-group .form-outline:not(:first-child) .form-notch-leading, .input-group .select-wrapper:not(:first-child), .input-group .select-wrapper:not(:first-child) .form-notch-leading {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.input-group .form-outline:not(:last-child), .input-group .form-outline:not(:last-child) .form-notch-trailing, .input-group .select-wrapper:not(:last-child), .input-group .select-wrapper:not(:last-child) .form-notch-trailing {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group > [class*="btn-outline-"] + [class*="btn-outline-"] {
  border-left: 0;
}

.input-group > .btn[class*="btn-outline-"] {
  padding-top: .47rem;
}

.input-group > .btn {
  padding-top: .59rem;
}

.input-group.input-group-lg .input-group-text {
  height: calc(2.645rem + 2px);
}

.input-group .input-group-text {
  height: calc(2.08rem + 2px);
}

.input-group .btn {
  line-height: 1;
}

.input-group.input-group-sm .input-group-text {
  height: calc(1.66rem + 2px);
}

.input-group .invalid-feedback, .input-group .valid-feedback, .was-validated .input-group .invalid-feedback, .was-validated .input-group .valid-feedback {
  margin-top: 2.5rem;
}

.valid-feedback {
  width: auto;
  color: #14a44d;
  margin-top: -.75rem;
}

.valid-feedback, .valid-tooltip {
  font-size: .875rem;
  display: none;
  position: absolute;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #14a44de6;
  margin-top: .1rem;
  padding: 6px 16px;
  top: 100%;
  border-radius: .25rem !important;
}

.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: none;
  border-color: #14a44d;
  margin-bottom: 1rem;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #14a44d;
  box-shadow: 0 0 0 .25rem #14a44d40;
}

.form-outline .form-control.is-valid ~ .form-label, .was-validated .form-outline .form-control:valid ~ .form-label {
  color: #14a44d;
}

.form-outline .form-control.is-valid ~ .form-notch .form-notch-leading, .form-outline .form-control.is-valid ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid ~ .form-notch .form-notch-trailing, .was-validated .form-outline .form-control:valid ~ .form-notch .form-notch-leading, .was-validated .form-outline .form-control:valid ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:valid ~ .form-notch .form-notch-trailing {
  border-color: #14a44d;
}

.form-outline .form-control.is-valid.active ~ .form-notch .form-notch-middle, .form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:valid.active ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
}

.form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-middle {
  box-shadow: 0 1px #14a44d;
}

.form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-leading, .was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-leading {
  box-shadow: -1px 0 #14a44d, 0 1px #14a44d, 0 -1px #14a44d;
}

.form-outline .form-control.is-valid:focus ~ .form-notch .form-notch-trailing, .was-validated .form-outline .form-control:valid:focus ~ .form-notch .form-notch-trailing {
  box-shadow: 1px 0 #14a44d, 0 -1px #14a44d, 0 1px #14a44d;
}

.form-outline .form-control.is-valid.select-input.focused ~ .form-notch .form-notch-leading, .was-validated .form-outline .form-control:valid.select-input.focused ~ .form-notch .form-notch-leading {
  box-shadow: -1px 0 #14a44d, 0 1px #14a44d, 0 -1px #14a44d;
}

.form-outline .form-control.is-valid.select-input.focused ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:valid.select-input.focused ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
  box-shadow: 0 1px #14a44d;
}

.form-outline .form-control.is-valid.select-input.focused ~ .form-notch .form-notch-trailing, .was-validated .form-outline .form-control:valid.select-input.focused ~ .form-notch .form-notch-trailing {
  box-shadow: 1px 0 #14a44d, 0 -1px #14a44d, 0 1px #14a44d;
}

.form-select.is-valid, .was-validated .form-select:valid {
  border-color: #14a44d;
}

.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
  border-color: #14a44d;
  box-shadow: 0 0 0 .25rem #14a44d40;
}

.form-select.is-valid ~ .valid-feedback, .was-validated .form-select:valid ~ .valid-feedback {
  margin-top: 0;
}

.input-group .form-control.is-valid, .was-validated .input-group .form-control:valid {
  margin-bottom: 0;
}

.was-validated input[type="file"].form-control:valid .valid-feedback, input[type="file"].form-control.is-valid .valid-feedback {
  margin-top: 0;
}

.was-validated input[type="file"].form-control:valid:focus, input[type="file"].form-control.is-valid:focus {
  border-color: #14a44d;
  box-shadow: inset 0 0 0 1px #14a44d;
}

.was-validated input[type="file"].form-control:valid:focus ~ .form-file-label, input[type="file"].form-control.is-valid:focus ~ .form-file-label {
  box-shadow: none;
}

.was-validated input[type="file"].form-control:valid:focus-within ~ .form-file-label .form-file-button, .was-validated input[type="file"].form-control:valid:focus-within ~ .form-file-label .form-file-text, input[type="file"].form-control.is-valid:focus-within ~ .form-file-label .form-file-button, input[type="file"].form-control.is-valid:focus-within ~ .form-file-label .form-file-text, .form-check-input.is-valid, .was-validated .form-check-input:valid {
  border-color: #14a44d;
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: #14a44d;
}

.form-check-input.is-valid:checked:focus:before, .was-validated .form-check-input:valid:checked:focus:before {
  box-shadow: 0 0 0 13px #14a44d;
}

.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
  box-shadow: none;
}

.form-check-input.is-valid:focus:before, .was-validated .form-check-input:valid:focus:before {
  box-shadow: 0 0 0 13px #14a44d;
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #14a44d;
  margin-bottom: 1rem;
}

.form-check-input.is-valid[type="checkbox"]:checked:focus, .was-validated .form-check-input:valid[type="checkbox"]:checked:focus {
  background-color: #14a44d;
  border-color: #14a44d;
}

.form-check-input.is-valid[type="radio"]:checked, .was-validated .form-check-input:valid[type="radio"]:checked {
  background-color: #fff;
  border-color: #14a44d;
}

.form-check-input.is-valid[type="radio"]:checked:focus:before, .was-validated .form-check-input:valid[type="radio"]:checked:focus:before {
  box-shadow: 0 0 0 13px #14a44d;
}

.form-check-input.is-valid[type="radio"]:checked:after, .was-validated .form-check-input:valid[type="radio"]:checked:after {
  background-color: #14a44d;
  border-color: #14a44d;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.form-switch .form-check-input.is-valid:focus:before, .was-validated .form-switch .form-check-input:valid:focus:before {
  box-shadow: 3px -1px 0 13px #0009;
}

.form-switch .form-check-input.is-valid:checked[type="checkbox"]:after, .was-validated .form-switch .form-check-input:valid:checked[type="checkbox"]:after {
  background-color: #14a44d;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.form-switch .form-check-input.is-valid:checked:focus:before, .was-validated .form-switch .form-check-input:valid:checked:focus:before {
  box-shadow: 3px -1px 0 13px #14a44d;
}

.invalid-feedback {
  width: auto;
  color: #dc4c64;
  margin-top: -.75rem;
}

.invalid-feedback, .invalid-tooltip {
  font-size: .875rem;
  display: none;
  position: absolute;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc4c64e6;
  margin-top: .1rem;
  padding: 6px 16px;
  top: 100%;
  border-radius: .25rem !important;
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
  border-color: #dc4c64;
  margin-bottom: 1rem;
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc4c64;
  box-shadow: 0 0 0 .25rem #dc4c6440;
}

.form-outline .form-control.is-invalid ~ .form-label, .was-validated .form-outline .form-control:invalid ~ .form-label {
  color: #dc4c64;
}

.form-outline .form-control.is-invalid ~ .form-notch .form-notch-leading, .form-outline .form-control.is-invalid ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid ~ .form-notch .form-notch-trailing, .was-validated .form-outline .form-control:invalid ~ .form-notch .form-notch-leading, .was-validated .form-outline .form-control:invalid ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:invalid ~ .form-notch .form-notch-trailing {
  border-color: #dc4c64;
}

.form-outline .form-control.is-invalid.active ~ .form-notch .form-notch-middle, .form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:invalid.active ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
}

.form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-middle {
  box-shadow: 0 1px #dc4c64;
}

.form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-leading, .was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-leading {
  box-shadow: -1px 0 #dc4c64, 0 1px #dc4c64, 0 -1px #dc4c64;
}

.form-outline .form-control.is-invalid:focus ~ .form-notch .form-notch-trailing, .was-validated .form-outline .form-control:invalid:focus ~ .form-notch .form-notch-trailing {
  box-shadow: 1px 0 #dc4c64, 0 -1px #dc4c64, 0 1px #dc4c64;
}

.form-outline .form-control.is-invalid.select-input.focused ~ .form-notch .form-notch-leading, .was-validated .form-outline .form-control:invalid.select-input.focused ~ .form-notch .form-notch-leading {
  box-shadow: -1px 0 #dc4c64, 0 1px #dc4c64, 0 -1px #dc4c64;
}

.form-outline .form-control.is-invalid.select-input.focused ~ .form-notch .form-notch-middle, .was-validated .form-outline .form-control:invalid.select-input.focused ~ .form-notch .form-notch-middle {
  border-top: 1px solid #0000;
  box-shadow: 0 1px #dc4c64;
}

.form-outline .form-control.is-invalid.select-input.focused ~ .form-notch .form-notch-trailing, .was-validated .form-outline .form-control:invalid.select-input.focused ~ .form-notch .form-notch-trailing {
  box-shadow: 1px 0 #dc4c64, 0 -1px #dc4c64, 0 1px #dc4c64;
}

.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #dc4c64;
}

.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
  border-color: #dc4c64;
  box-shadow: 0 0 0 .25rem #dc4c6440;
}

.form-select.is-invalid ~ .invalid-feedback, .was-validated .form-select:invalid ~ .invalid-feedback {
  margin-top: 0;
}

.input-group .form-control.is-invalid, .was-validated .input-group .form-control:invalid {
  margin-bottom: 0;
}

.was-validated input[type="file"].form-control:invalid .invalid-feedback, input[type="file"].form-control.is-invalid .invalid-feedback {
  margin-top: 0;
}

.was-validated input[type="file"].form-control:invalid:focus, input[type="file"].form-control.is-invalid:focus {
  border-color: #dc4c64;
  box-shadow: inset 0 0 0 1px #dc4c64;
}

.was-validated input[type="file"].form-control:invalid:focus ~ .form-file-label, input[type="file"].form-control.is-invalid:focus ~ .form-file-label {
  box-shadow: none;
}

.was-validated input[type="file"].form-control:invalid:focus-within ~ .form-file-label .form-file-button, .was-validated input[type="file"].form-control:invalid:focus-within ~ .form-file-label .form-file-text, input[type="file"].form-control.is-invalid:focus-within ~ .form-file-label .form-file-button, input[type="file"].form-control.is-invalid:focus-within ~ .form-file-label .form-file-text, .form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #dc4c64;
}

.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
  background-color: #dc4c64;
}

.form-check-input.is-invalid:checked:focus:before, .was-validated .form-check-input:invalid:checked:focus:before {
  box-shadow: 0 0 0 13px #dc4c64;
}

.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  box-shadow: none;
}

.form-check-input.is-invalid:focus:before, .was-validated .form-check-input:invalid:focus:before {
  box-shadow: 0 0 0 13px #dc4c64;
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc4c64;
  margin-bottom: 1rem;
}

.form-check-input.is-invalid[type="checkbox"]:checked:focus, .was-validated .form-check-input:invalid[type="checkbox"]:checked:focus {
  background-color: #dc4c64;
  border-color: #dc4c64;
}

.form-check-input.is-invalid[type="radio"]:checked, .was-validated .form-check-input:invalid[type="radio"]:checked {
  background-color: #fff;
  border-color: #dc4c64;
}

.form-check-input.is-invalid[type="radio"]:checked:focus:before, .was-validated .form-check-input:invalid[type="radio"]:checked:focus:before {
  box-shadow: 0 0 0 13px #dc4c64;
}

.form-check-input.is-invalid[type="radio"]:checked:after, .was-validated .form-check-input:invalid[type="radio"]:checked:after {
  background-color: #dc4c64;
  border-color: #dc4c64;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.form-switch .form-check-input.is-invalid:focus:before, .was-validated .form-switch .form-check-input:invalid:focus:before {
  box-shadow: 3px -1px 0 13px #0009;
}

.form-switch .form-check-input.is-invalid:checked[type="checkbox"]:after, .was-validated .form-switch .form-check-input:invalid:checked[type="checkbox"]:after {
  background-color: #dc4c64;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.form-switch .form-check-input.is-invalid:checked:focus:before, .was-validated .form-switch .form-check-input:invalid:checked:focus:before {
  box-shadow: 3px -1px 0 13px #dc4c64;
}

.form-range:focus {
  box-shadow: none;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: none;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: none;
}

.form-range:focus::-ms-thumb {
  box-shadow: none;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  box-shadow: none;
  appearance: none;
  margin-top: -6px;
}

.form-range::-webkit-slider-runnable-track {
  height: 4px;
  box-shadow: none;
  border-radius: 0;
}

.form-range::-moz-range-thumb {
  box-shadow: none;
  appearance: none;
}

.form-range::-moz-range-track {
  box-shadow: none;
}

.table {
  --mdb-table-font-size: .9rem;
  --mdb-table-divider-color: #0000001a;
  font-size: var(--mdb-table-font-size);
}

.table th {
  font-weight: 500;
}

.table tbody {
  font-weight: 400;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}

.table-primary {
  --mdb-table-color: #000;
  --mdb-table-bg: #d8e3f4;
  --mdb-table-border-color: #c2ccdc;
  --mdb-table-striped-bg: #cdd8e8;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #c2ccdc;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #c8d2e2;
  --mdb-table-hover-color: #000;
}

.table-primary, .table-secondary {
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-secondary {
  --mdb-table-color: #000;
  --mdb-table-bg: #ecedf0;
  --mdb-table-border-color: #d4d5d8;
  --mdb-table-striped-bg: #e0e1e4;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #d4d5d8;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #dadbde;
  --mdb-table-hover-color: #000;
}

.table-success {
  --mdb-table-color: #000;
  --mdb-table-bg: #d0eddb;
  --mdb-table-border-color: #bbd5c5;
  --mdb-table-striped-bg: #c6e1d0;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #bbd5c5;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #c0dbcb;
  --mdb-table-hover-color: #000;
}

.table-info, .table-success {
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-info {
  --mdb-table-color: #000;
  --mdb-table-bg: #ddf0f6;
  --mdb-table-border-color: #c7d8dd;
  --mdb-table-striped-bg: #d2e4ea;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #c7d8dd;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #ccdee4;
  --mdb-table-hover-color: #000;
}

.table-warning {
  --mdb-table-color: #000;
  --mdb-table-bg: #faecd1;
  --mdb-table-border-color: #e1d4bc;
  --mdb-table-striped-bg: #eee0c7;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e1d4bc;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #e7dac1;
  --mdb-table-hover-color: #000;
}

.table-danger, .table-warning {
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-danger {
  --mdb-table-color: #000;
  --mdb-table-bg: #f8dbe0;
  --mdb-table-border-color: #dfc5ca;
  --mdb-table-striped-bg: #ecd0d5;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #dfc5ca;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #e5cbcf;
  --mdb-table-hover-color: #000;
}

.table-light {
  --mdb-table-color: #000;
  --mdb-table-bg: #fbfbfb;
  --mdb-table-border-color: #e2e2e2;
  --mdb-table-striped-bg: #eee;
  --mdb-table-striped-color: #000;
  --mdb-table-active-bg: #e2e2e2;
  --mdb-table-active-color: #000;
  --mdb-table-hover-bg: #e8e8e8;
  --mdb-table-hover-color: #000;
}

.table-dark, .table-light {
  color: var(--mdb-table-color);
  border-color: var(--mdb-table-border-color);
}

.table-dark {
  --mdb-table-color: #fff;
  --mdb-table-bg: #332d2d;
  --mdb-table-border-color: #474242;
  --mdb-table-striped-bg: #3d3838;
  --mdb-table-striped-color: #fff;
  --mdb-table-active-bg: #474242;
  --mdb-table-active-color: #fff;
  --mdb-table-hover-bg: #423d3d;
  --mdb-table-hover-color: #fff;
}

.table-hover > tbody > tr {
  transition: all .5s;
}

.table-hover > tbody > tr:hover {
  --mdb-table-accent-bg: transparent;
  background-color: var(--mdb-table-hover-bg);
}

.table-group-divider {
  border-top: 2px solid;
  border-top-color: inherit;
}

.table-divider-color {
  border-top-color: var(--mdb-table-divider-color);
}

.btn {
  --mdb-btn-padding-top: .625rem;
  --mdb-btn-padding-bottom: .5rem;
  --mdb-btn-border-width: 0;
  --mdb-btn-border-color: none;
  --mdb-btn-border-radius: .25rem;
  --mdb-btn-box-shadow: 0 4px 9px -4px #00000059;
  --mdb-btn-hover-box-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-focus-box-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-active-box-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  padding-top: var(--mdb-btn-padding-top);
  padding-bottom: var(--mdb-btn-padding-bottom);
  text-transform: uppercase;
  vertical-align: bottom;
  border-radius: var(--mdb-btn-border-radius);
  box-shadow: var(--mdb-btn-box-shadow);
  border: 0;
}

.btn:first-child:hover, .btn:focus-visible, .btn:hover, :not(.btn-check) + .btn:hover {
  box-shadow: var(--mdb-btn-hover-box-shadow);
}

.btn-check:focus + .btn, .btn-check:focus-visible + .btn, .btn:focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn-check:active + .btn, .btn-check:checked + .btn, .btn.active, .btn.show, .btn:active {
  box-shadow: var(--mdb-btn-active-box-shadow);
}

.btn-check:active + .btn:focus, .btn-check:checked + .btn:focus, .btn.active:focus, .btn.show:focus, .btn:active:focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  box-shadow: var(--mdb-btn-box-shadow);
}

[class*="btn-outline-"] {
  --mdb-btn-padding-top: .5rem;
  --mdb-btn-padding-bottom: .375rem;
  --mdb-btn-padding-x: 1.375rem;
  --mdb-btn-border-width: 2px;
  --mdb-btn-line-height: 1.5;
  padding: var(--mdb-btn-padding-top) var(--mdb-btn-padding-x) var(--mdb-btn-padding-bottom);
  border-width: var(--mdb-btn-border-width);
  box-shadow: none;
  border-style: solid;
}

.btn-check:active + [class*="btn-outline-"], .btn-check:active + [class*="btn-outline-"]:focus, .btn-check:checked + [class*="btn-outline-"], .btn-check:checked + [class*="btn-outline-"]:focus, .btn-check:focus + [class*="btn-outline-"], .btn-check:focus-visible + [class*="btn-outline-"], :not(.btn-check) + [class*="btn-outline-"]:hover, [class*="btn-outline-"].active, [class*="btn-outline-"].active:focus, [class*="btn-outline-"].disabled, [class*="btn-outline-"].show, [class*="btn-outline-"].show:focus, [class*="btn-outline-"]:active, [class*="btn-outline-"]:active:focus, [class*="btn-outline-"]:disabled, [class*="btn-outline-"]:first-child:hover, [class*="btn-outline-"]:focus, [class*="btn-outline-"]:focus-visible, [class*="btn-outline-"]:hover, fieldset:disabled [class*="btn-outline-"] {
  box-shadow: none;
}

.btn-group-lg > [class*="btn-outline-"].btn, [class*="btn-outline-"].btn-lg {
  --mdb-btn-padding-top: .625rem;
  --mdb-btn-padding-bottom: .5625rem;
  --mdb-btn-padding-x: 1.5625rem;
  --mdb-btn-font-size: .875rem;
  --mdb-btn-line-height: 1.6;
}

.btn-group-sm > [class*="btn-outline-"].btn, [class*="btn-outline-"].btn-sm {
  --mdb-btn-padding-top: .25rem;
  --mdb-btn-padding-bottom: .1875rem;
  --mdb-btn-padding-x: .875rem;
  --mdb-btn-font-size: .75rem;
  --mdb-btn-line-height: 1.5;
}

.btn-secondary {
  box-shadow: none;
}

.btn-check:active + .btn-secondary, .btn-check:active + .btn-secondary:focus, .btn-check:checked + .btn-secondary, .btn-check:checked + .btn-secondary:focus, .btn-check:focus + .btn-secondary, .btn-check:focus-visible + .btn-secondary, .btn-secondary.active, .btn-secondary.active:focus, .btn-secondary.disabled, .btn-secondary.show, .btn-secondary.show:focus, .btn-secondary:active, .btn-secondary:active:focus, .btn-secondary:disabled, .btn-secondary:focus, fieldset:disabled .btn-secondary {
  box-shadow: none;
}

.btn-primary {
  --mdb-btn-bg: #3b71ca;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #3b71ca;
  --mdb-btn-hover-bg: #386bc0;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #386bc0;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #3566b6;
  --mdb-btn-active-color: #fff;
}

.btn-primary:first-child:hover, .btn-primary:focus-visible, .btn-primary:hover, :not(.btn-check) + .btn-primary:hover {
  box-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px #3b71ca33;
}

.btn-check:focus + .btn-primary, .btn-check:focus-visible + .btn-primary, .btn-primary:focus {
  background-color: #386bc0;
  box-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px #3b71ca33;
}

.btn-check:active + .btn-primary, .btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary, .btn-check:checked + .btn-primary:focus, .btn-primary.active, .btn-primary.active:focus, .btn-primary.show, .btn-primary.show:focus, .btn-primary:active, .btn-primary:active:focus {
  box-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px #3b71ca33;
}

.btn-check:active + .btn-primary:hover, .btn-check:checked + .btn-primary:hover, .btn-primary.active:hover, .btn-primary.show:hover, .btn-primary:active:hover {
  background-color: #3566b6;
}

.btn-primary.disabled, .btn-primary:disabled, fieldset:disabled .btn-primary {
  box-shadow: 0 4px 9px -4px #3b71ca;
}

.btn-secondary {
  --mdb-btn-bg: #e3ebf7;
  --mdb-btn-color: #285192;
  --mdb-btn-box-shadow: 0 4px 9px -4px #e3ebf7;
  --mdb-btn-hover-bg: #d8dfeb;
  --mdb-btn-hover-color: #285192;
  --mdb-btn-focus-bg: #d8dfeb;
  --mdb-btn-focus-color: #285192;
  --mdb-btn-active-bg: #ccd4de;
  --mdb-btn-active-color: #285192;
}

.btn-secondary:first-child:hover, .btn-secondary:focus-visible, .btn-secondary:hover, :not(.btn-check) + .btn-secondary:hover {
  box-shadow: 0 8px 9px -4px #e3ebf74d, 0 4px 18px #e3ebf733;
}

.btn-check:focus + .btn-secondary, .btn-check:focus-visible + .btn-secondary, .btn-secondary:focus {
  background-color: #d8dfeb;
  box-shadow: 0 8px 9px -4px #e3ebf74d, 0 4px 18px #e3ebf733;
}

.btn-check:active + .btn-secondary, .btn-check:active + .btn-secondary:focus, .btn-check:checked + .btn-secondary, .btn-check:checked + .btn-secondary:focus, .btn-secondary.active, .btn-secondary.active:focus, .btn-secondary.show, .btn-secondary.show:focus, .btn-secondary:active, .btn-secondary:active:focus {
  box-shadow: 0 8px 9px -4px #e3ebf74d, 0 4px 18px #e3ebf733;
}

.btn-check:active + .btn-secondary:hover, .btn-check:checked + .btn-secondary:hover, .btn-secondary.active:hover, .btn-secondary.show:hover, .btn-secondary:active:hover {
  background-color: #ccd4de;
}

.btn-secondary.disabled, .btn-secondary:disabled, fieldset:disabled .btn-secondary {
  box-shadow: 0 4px 9px -4px #e3ebf7;
}

.btn-success {
  --mdb-btn-bg: #14a44d;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #14a44d;
  --mdb-btn-hover-bg: #139c49;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #139c49;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #129445;
  --mdb-btn-active-color: #fff;
}

.btn-success:first-child:hover, .btn-success:focus-visible, .btn-success:hover, :not(.btn-check) + .btn-success:hover {
  box-shadow: 0 8px 9px -4px #14a44d4d, 0 4px 18px #14a44d33;
}

.btn-check:focus + .btn-success, .btn-check:focus-visible + .btn-success, .btn-success:focus {
  background-color: #139c49;
  box-shadow: 0 8px 9px -4px #14a44d4d, 0 4px 18px #14a44d33;
}

.btn-check:active + .btn-success, .btn-check:active + .btn-success:focus, .btn-check:checked + .btn-success, .btn-check:checked + .btn-success:focus, .btn-success.active, .btn-success.active:focus, .btn-success.show, .btn-success.show:focus, .btn-success:active, .btn-success:active:focus {
  box-shadow: 0 8px 9px -4px #14a44d4d, 0 4px 18px #14a44d33;
}

.btn-check:active + .btn-success:hover, .btn-check:checked + .btn-success:hover, .btn-success.active:hover, .btn-success.show:hover, .btn-success:active:hover {
  background-color: #129445;
}

.btn-success.disabled, .btn-success:disabled, fieldset:disabled .btn-success {
  box-shadow: 0 4px 9px -4px #14a44d;
}

.btn-danger {
  --mdb-btn-bg: #dc4c64;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #dc4c64;
  --mdb-btn-hover-bg: #d1485f;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #d1485f;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #c6445a;
  --mdb-btn-active-color: #fff;
}

.btn-danger:first-child:hover, .btn-danger:focus-visible, .btn-danger:hover, :not(.btn-check) + .btn-danger:hover {
  box-shadow: 0 8px 9px -4px #dc4c644d, 0 4px 18px #dc4c6433;
}

.btn-check:focus + .btn-danger, .btn-check:focus-visible + .btn-danger, .btn-danger:focus {
  background-color: #d1485f;
  box-shadow: 0 8px 9px -4px #dc4c644d, 0 4px 18px #dc4c6433;
}

.btn-check:active + .btn-danger, .btn-check:active + .btn-danger:focus, .btn-check:checked + .btn-danger, .btn-check:checked + .btn-danger:focus, .btn-danger.active, .btn-danger.active:focus, .btn-danger.show, .btn-danger.show:focus, .btn-danger:active, .btn-danger:active:focus {
  box-shadow: 0 8px 9px -4px #dc4c644d, 0 4px 18px #dc4c6433;
}

.btn-check:active + .btn-danger:hover, .btn-check:checked + .btn-danger:hover, .btn-danger.active:hover, .btn-danger.show:hover, .btn-danger:active:hover {
  background-color: #c6445a;
}

.btn-danger.disabled, .btn-danger:disabled, fieldset:disabled .btn-danger {
  box-shadow: 0 4px 9px -4px #dc4c64;
}

.btn-warning {
  --mdb-btn-bg: #e4a11b;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #e4a11b;
  --mdb-btn-hover-bg: #d9991a;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #d9991a;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #cd9118;
  --mdb-btn-active-color: #fff;
}

.btn-warning:first-child:hover, .btn-warning:focus-visible, .btn-warning:hover, :not(.btn-check) + .btn-warning:hover {
  box-shadow: 0 8px 9px -4px #e4a11b4d, 0 4px 18px #e4a11b33;
}

.btn-check:focus + .btn-warning, .btn-check:focus-visible + .btn-warning, .btn-warning:focus {
  background-color: #d9991a;
  box-shadow: 0 8px 9px -4px #e4a11b4d, 0 4px 18px #e4a11b33;
}

.btn-check:active + .btn-warning, .btn-check:active + .btn-warning:focus, .btn-check:checked + .btn-warning, .btn-check:checked + .btn-warning:focus, .btn-warning.active, .btn-warning.active:focus, .btn-warning.show, .btn-warning.show:focus, .btn-warning:active, .btn-warning:active:focus {
  box-shadow: 0 8px 9px -4px #e4a11b4d, 0 4px 18px #e4a11b33;
}

.btn-check:active + .btn-warning:hover, .btn-check:checked + .btn-warning:hover, .btn-warning.active:hover, .btn-warning.show:hover, .btn-warning:active:hover {
  background-color: #cd9118;
}

.btn-warning.disabled, .btn-warning:disabled, fieldset:disabled .btn-warning {
  box-shadow: 0 4px 9px -4px #e4a11b;
}

.btn-info {
  --mdb-btn-bg: #54b4d3;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #54b4d3;
  --mdb-btn-hover-bg: #50abc8;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #50abc8;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #4ca2be;
  --mdb-btn-active-color: #fff;
}

.btn-check:focus + .btn-info, .btn-check:focus-visible + .btn-info, .btn-info:first-child:hover, .btn-info:focus, .btn-info:focus-visible, .btn-info:hover, :not(.btn-check) + .btn-info:hover {
  box-shadow: 0 8px 9px -4px #54b4d34d, 0 4px 18px #54b4d333;
}

.btn-check:focus + .btn-info, .btn-check:focus-visible + .btn-info, .btn-info:focus {
  background-color: #50abc8;
}

.btn-check:active + .btn-info, .btn-check:active + .btn-info:focus, .btn-check:checked + .btn-info, .btn-check:checked + .btn-info:focus, .btn-info.active, .btn-info.active:focus, .btn-info.show, .btn-info.show:focus, .btn-info:active, .btn-info:active:focus {
  box-shadow: 0 8px 9px -4px #54b4d34d, 0 4px 18px #54b4d333;
}

.btn-check:active + .btn-info:hover, .btn-check:checked + .btn-info:hover, .btn-info.active:hover, .btn-info.show:hover, .btn-info:active:hover {
  background-color: #4ca2be;
}

.btn-info.disabled, .btn-info:disabled, fieldset:disabled .btn-info {
  box-shadow: 0 4px 9px -4px #54b4d3;
}

.btn-light {
  --mdb-btn-bg: #fbfbfb;
  --mdb-btn-color: #4f4f4f;
  --mdb-btn-box-shadow: 0 4px 9px -4px #fbfbfb;
  --mdb-btn-hover-bg: #eee;
  --mdb-btn-hover-color: #4f4f4f;
  --mdb-btn-focus-bg: #eee;
  --mdb-btn-focus-color: #4f4f4f;
  --mdb-btn-active-bg: #e2e2e2;
  --mdb-btn-active-color: #4f4f4f;
}

.btn-light:first-child:hover, .btn-light:focus-visible, .btn-light:hover, :not(.btn-check) + .btn-light:hover {
  box-shadow: 0 8px 9px -4px #fbfbfb4d, 0 4px 18px #fbfbfb33;
}

.btn-check:focus + .btn-light, .btn-check:focus-visible + .btn-light, .btn-light:focus {
  background-color: #eee;
  box-shadow: 0 8px 9px -4px #fbfbfb4d, 0 4px 18px #fbfbfb33;
}

.btn-check:active + .btn-light, .btn-check:active + .btn-light:focus, .btn-check:checked + .btn-light, .btn-check:checked + .btn-light:focus, .btn-light.active, .btn-light.active:focus, .btn-light.show, .btn-light.show:focus, .btn-light:active, .btn-light:active:focus {
  box-shadow: 0 8px 9px -4px #fbfbfb4d, 0 4px 18px #fbfbfb33;
}

.btn-check:active + .btn-light:hover, .btn-check:checked + .btn-light:hover, .btn-light.active:hover, .btn-light.show:hover, .btn-light:active:hover {
  background-color: #e2e2e2;
}

.btn-light.disabled, .btn-light:disabled, fieldset:disabled .btn-light {
  box-shadow: 0 4px 9px -4px #fbfbfb;
}

.btn-dark {
  --mdb-btn-bg: #332d2d;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #332d2d;
  --mdb-btn-hover-bg: #302b2b;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #302b2b;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #2e2929;
  --mdb-btn-active-color: #fff;
}

.btn-dark:first-child:hover, .btn-dark:focus-visible, .btn-dark:hover, :not(.btn-check) + .btn-dark:hover {
  box-shadow: 0 8px 9px -4px #332d2d4d, 0 4px 18px #332d2d33;
}

.btn-check:focus + .btn-dark, .btn-check:focus-visible + .btn-dark, .btn-dark:focus {
  background-color: #302b2b;
  box-shadow: 0 8px 9px -4px #332d2d4d, 0 4px 18px #332d2d33;
}

.btn-check:active + .btn-dark, .btn-check:active + .btn-dark:focus, .btn-check:checked + .btn-dark, .btn-check:checked + .btn-dark:focus, .btn-dark.active, .btn-dark.active:focus, .btn-dark.show, .btn-dark.show:focus, .btn-dark:active, .btn-dark:active:focus {
  box-shadow: 0 8px 9px -4px #332d2d4d, 0 4px 18px #332d2d33;
}

.btn-check:active + .btn-dark:hover, .btn-check:checked + .btn-dark:hover, .btn-dark.active:hover, .btn-dark.show:hover, .btn-dark:active:hover {
  background-color: #2e2929;
}

.btn-dark.disabled, .btn-dark:disabled, fieldset:disabled .btn-dark {
  box-shadow: 0 4px 9px -4px #332d2d;
}

.btn-white {
  --mdb-btn-bg: #fff;
  --mdb-btn-color: #000;
  --mdb-btn-box-shadow: 0 4px 9px -4px #fff;
  --mdb-btn-hover-bg: #f2f2f2;
  --mdb-btn-hover-color: #000;
  --mdb-btn-focus-bg: #f2f2f2;
  --mdb-btn-focus-color: #000;
  --mdb-btn-active-bg: #e6e6e6;
  --mdb-btn-active-color: #000;
}

.btn-white:first-child:hover, .btn-white:focus-visible, .btn-white:hover, :not(.btn-check) + .btn-white:hover {
  box-shadow: 0 8px 9px -4px #ffffff4d, 0 4px 18px #fff3;
}

.btn-check:focus + .btn-white, .btn-check:focus-visible + .btn-white, .btn-white:focus {
  background-color: #f2f2f2;
  box-shadow: 0 8px 9px -4px #ffffff4d, 0 4px 18px #fff3;
}

.btn-check:active + .btn-white, .btn-check:active + .btn-white:focus, .btn-check:checked + .btn-white, .btn-check:checked + .btn-white:focus, .btn-white.active, .btn-white.active:focus, .btn-white.show, .btn-white.show:focus, .btn-white:active, .btn-white:active:focus {
  box-shadow: 0 8px 9px -4px #ffffff4d, 0 4px 18px #fff3;
}

.btn-check:active + .btn-white:hover, .btn-check:checked + .btn-white:hover, .btn-white.active:hover, .btn-white.show:hover, .btn-white:active:hover {
  background-color: #e6e6e6;
}

.btn-white.disabled, .btn-white:disabled, fieldset:disabled .btn-white {
  box-shadow: 0 4px 9px -4px #fff;
}

.btn-black {
  --mdb-btn-bg: #000;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #000;
  --mdb-btn-hover-bg: #000;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #000;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #000;
  --mdb-btn-active-color: #fff;
}

.btn-black:first-child:hover, .btn-black:focus-visible, .btn-black:hover, :not(.btn-check) + .btn-black:hover {
  box-shadow: 0 8px 9px -4px #0000004d, 0 4px 18px #0003;
}

.btn-black:focus, .btn-check:focus + .btn-black, .btn-check:focus-visible + .btn-black {
  background-color: #000;
  box-shadow: 0 8px 9px -4px #0000004d, 0 4px 18px #0003;
}

.btn-black.active, .btn-black.active:focus, .btn-black.show, .btn-black.show:focus, .btn-black:active, .btn-black:active:focus, .btn-check:active + .btn-black, .btn-check:active + .btn-black:focus, .btn-check:checked + .btn-black, .btn-check:checked + .btn-black:focus {
  box-shadow: 0 8px 9px -4px #0000004d, 0 4px 18px #0003;
}

.btn-black.active:hover, .btn-black.show:hover, .btn-black:active:hover, .btn-check:active + .btn-black:hover, .btn-check:checked + .btn-black:hover {
  background-color: #000;
}

.btn-black.disabled, .btn-black:disabled, fieldset:disabled .btn-black {
  box-shadow: 0 4px 9px -4px #000;
}

.btn-outline-primary {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #3b71ca;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-hover-color: #386bc0;
  --mdb-btn-focus-bg: transparent;
  --mdb-btn-focus-color: #386bc0;
  --mdb-btn-active-bg: transparent;
  --mdb-btn-active-color: #3566b6;
  border-color: #3b71ca;
}

.btn-check:focus + .btn-outline-primary, .btn-check:focus-visible + .btn-outline-primary, .btn-outline-primary:first-child:hover, .btn-outline-primary:focus, .btn-outline-primary:focus-visible, .btn-outline-primary:hover, :not(.btn-check) + .btn-outline-primary:hover {
  border-color: #386bc0;
}

.btn-check:active + .btn-outline-primary, .btn-check:checked + .btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.show, .btn-outline-primary:active {
  border-color: #3566b6;
}

.btn-check:active + .btn-outline-primary:focus, .btn-check:checked + .btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.show:focus, .btn-outline-primary:active:focus {
  border-color: #386bc0;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled, fieldset:disabled .btn-outline-primary {
  border-color: #3b71ca;
}

.btn-outline-secondary {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #285192;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-hover-color: #264d8b;
  --mdb-btn-focus-bg: transparent;
  --mdb-btn-focus-color: #264d8b;
  --mdb-btn-active-bg: transparent;
  --mdb-btn-active-color: #244983;
  border-color: #e3ebf7;
}

.btn-check:focus + .btn-outline-secondary, .btn-check:focus-visible + .btn-outline-secondary, .btn-outline-secondary:first-child:hover, .btn-outline-secondary:focus, .btn-outline-secondary:focus-visible, .btn-outline-secondary:hover, :not(.btn-check) + .btn-outline-secondary:hover {
  border-color: #d8dfeb;
}

.btn-check:active + .btn-outline-secondary, .btn-check:checked + .btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.show, .btn-outline-secondary:active {
  border-color: #ccd4de;
}

.btn-check:active + .btn-outline-secondary:focus, .btn-check:checked + .btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.show:focus, .btn-outline-secondary:active:focus {
  border-color: #d8dfeb;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled, fieldset:disabled .btn-outline-secondary {
  border-color: #e3ebf7;
}

.btn-outline-success {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #14a44d;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-hover-color: #139c49;
  --mdb-btn-focus-bg: transparent;
  --mdb-btn-focus-color: #139c49;
  --mdb-btn-active-bg: transparent;
  --mdb-btn-active-color: #129445;
  border-color: #14a44d;
}

.btn-check:focus + .btn-outline-success, .btn-check:focus-visible + .btn-outline-success, .btn-outline-success:first-child:hover, .btn-outline-success:focus, .btn-outline-success:focus-visible, .btn-outline-success:hover, :not(.btn-check) + .btn-outline-success:hover {
  border-color: #139c49;
}

.btn-check:active + .btn-outline-success, .btn-check:checked + .btn-outline-success, .btn-outline-success.active, .btn-outline-success.show, .btn-outline-success:active {
  border-color: #129445;
}

.btn-check:active + .btn-outline-success:focus, .btn-check:checked + .btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.show:focus, .btn-outline-success:active:focus {
  border-color: #139c49;
}

.btn-outline-success.disabled, .btn-outline-success:disabled, fieldset:disabled .btn-outline-success {
  border-color: #14a44d;
}

.btn-outline-danger {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #dc4c64;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-hover-color: #d1485f;
  --mdb-btn-focus-bg: transparent;
  --mdb-btn-focus-color: #d1485f;
  --mdb-btn-active-bg: transparent;
  --mdb-btn-active-color: #c6445a;
  border-color: #dc4c64;
}

.btn-check:focus + .btn-outline-danger, .btn-check:focus-visible + .btn-outline-danger, .btn-outline-danger:first-child:hover, .btn-outline-danger:focus, .btn-outline-danger:focus-visible, .btn-outline-danger:hover, :not(.btn-check) + .btn-outline-danger:hover {
  border-color: #d1485f;
}

.btn-check:active + .btn-outline-danger, .btn-check:checked + .btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.show, .btn-outline-danger:active {
  border-color: #c6445a;
}

.btn-check:active + .btn-outline-danger:focus, .btn-check:checked + .btn-outline-danger:focus, .btn-outline-danger.active:focus, .btn-outline-danger.show:focus, .btn-outline-danger:active:focus {
  border-color: #d1485f;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled, fieldset:disabled .btn-outline-danger {
  border-color: #dc4c64;
}

.btn-outline-warning {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #e4a11b;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-hover-color: #d9991a;
  --mdb-btn-focus-bg: transparent;
  --mdb-btn-focus-color: #d9991a;
  --mdb-btn-active-bg: transparent;
  --mdb-btn-active-color: #cd9118;
  border-color: #e4a11b;
}

.btn-check:focus + .btn-outline-warning, .btn-check:focus-visible + .btn-outline-warning, .btn-outline-warning:first-child:hover, .btn-outline-warning:focus, .btn-outline-warning:focus-visible, .btn-outline-warning:hover, :not(.btn-check) + .btn-outline-warning:hover {
  border-color: #d9991a;
}

.btn-check:active + .btn-outline-warning, .btn-check:checked + .btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.show, .btn-outline-warning:active {
  border-color: #cd9118;
}

.btn-check:active + .btn-outline-warning:focus, .btn-check:checked + .btn-outline-warning:focus, .btn-outline-warning.active:focus, .btn-outline-warning.show:focus, .btn-outline-warning:active:focus {
  border-color: #d9991a;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled, fieldset:disabled .btn-outline-warning {
  border-color: #e4a11b;
}

.btn-outline-info {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #54b4d3;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-hover-color: #50abc8;
  --mdb-btn-focus-bg: transparent;
  --mdb-btn-focus-color: #50abc8;
  --mdb-btn-active-bg: transparent;
  --mdb-btn-active-color: #4ca2be;
  border-color: #54b4d3;
}

.btn-check:focus + .btn-outline-info, .btn-check:focus-visible + .btn-outline-info, .btn-outline-info:first-child:hover, .btn-outline-info:focus, .btn-outline-info:focus-visible, .btn-outline-info:hover, :not(.btn-check) + .btn-outline-info:hover {
  border-color: #50abc8;
}

.btn-check:active + .btn-outline-info, .btn-check:checked + .btn-outline-info, .btn-outline-info.active, .btn-outline-info.show, .btn-outline-info:active {
  border-color: #4ca2be;
}

.btn-check:active + .btn-outline-info:focus, .btn-check:checked + .btn-outline-info:focus, .btn-outline-info.active:focus, .btn-outline-info.show:focus, .btn-outline-info:active:focus {
  border-color: #50abc8;
}

.btn-outline-info.disabled, .btn-outline-info:disabled, fieldset:disabled .btn-outline-info {
  border-color: #54b4d3;
}

.btn-outline-light {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #fbfbfb;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-hover-color: #eee;
  --mdb-btn-focus-bg: transparent;
  --mdb-btn-focus-color: #eee;
  --mdb-btn-active-bg: transparent;
  --mdb-btn-active-color: #e2e2e2;
  border-color: #fbfbfb;
}

.btn-check:focus + .btn-outline-light, .btn-check:focus-visible + .btn-outline-light, .btn-outline-light:first-child:hover, .btn-outline-light:focus, .btn-outline-light:focus-visible, .btn-outline-light:hover, :not(.btn-check) + .btn-outline-light:hover {
  border-color: #eee;
}

.btn-check:active + .btn-outline-light, .btn-check:checked + .btn-outline-light, .btn-outline-light.active, .btn-outline-light.show, .btn-outline-light:active {
  border-color: #e2e2e2;
}

.btn-check:active + .btn-outline-light:focus, .btn-check:checked + .btn-outline-light:focus, .btn-outline-light.active:focus, .btn-outline-light.show:focus, .btn-outline-light:active:focus {
  border-color: #eee;
}

.btn-outline-light.disabled, .btn-outline-light:disabled, fieldset:disabled .btn-outline-light {
  border-color: #fbfbfb;
}

.btn-outline-dark {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #332d2d;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-hover-color: #302b2b;
  --mdb-btn-focus-bg: transparent;
  --mdb-btn-focus-color: #302b2b;
  --mdb-btn-active-bg: transparent;
  --mdb-btn-active-color: #2e2929;
  border-color: #332d2d;
}

.btn-check:focus + .btn-outline-dark, .btn-check:focus-visible + .btn-outline-dark, .btn-outline-dark:first-child:hover, .btn-outline-dark:focus, .btn-outline-dark:focus-visible, .btn-outline-dark:hover, :not(.btn-check) + .btn-outline-dark:hover {
  border-color: #302b2b;
}

.btn-check:active + .btn-outline-dark, .btn-check:checked + .btn-outline-dark, .btn-outline-dark.active, .btn-outline-dark.show, .btn-outline-dark:active {
  border-color: #2e2929;
}

.btn-check:active + .btn-outline-dark:focus, .btn-check:checked + .btn-outline-dark:focus, .btn-outline-dark.active:focus, .btn-outline-dark.show:focus, .btn-outline-dark:active:focus {
  border-color: #302b2b;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled, fieldset:disabled .btn-outline-dark {
  border-color: #332d2d;
}

.btn-outline-white {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #fff;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-hover-color: #f2f2f2;
  --mdb-btn-focus-bg: transparent;
  --mdb-btn-focus-color: #f2f2f2;
  --mdb-btn-active-bg: transparent;
  --mdb-btn-active-color: #e6e6e6;
  border-color: #fff;
}

.btn-check:focus + .btn-outline-white, .btn-check:focus-visible + .btn-outline-white, .btn-outline-white:first-child:hover, .btn-outline-white:focus, .btn-outline-white:focus-visible, .btn-outline-white:hover, :not(.btn-check) + .btn-outline-white:hover {
  border-color: #f2f2f2;
}

.btn-check:active + .btn-outline-white, .btn-check:checked + .btn-outline-white, .btn-outline-white.active, .btn-outline-white.show, .btn-outline-white:active {
  border-color: #e6e6e6;
}

.btn-check:active + .btn-outline-white:focus, .btn-check:checked + .btn-outline-white:focus, .btn-outline-white.active:focus, .btn-outline-white.show:focus, .btn-outline-white:active:focus {
  border-color: #f2f2f2;
}

.btn-outline-white.disabled, .btn-outline-white:disabled, fieldset:disabled .btn-outline-white {
  border-color: #fff;
}

.btn-outline-black {
  --mdb-btn-bg: transparent;
  --mdb-btn-color: #000;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-hover-color: #000;
  --mdb-btn-focus-bg: transparent;
  --mdb-btn-focus-color: #000;
  --mdb-btn-active-bg: transparent;
  --mdb-btn-active-color: #000;
  border-color: #000;
}

.btn-check:active + .btn-outline-black, .btn-check:active + .btn-outline-black:focus, .btn-check:checked + .btn-outline-black, .btn-check:checked + .btn-outline-black:focus, .btn-check:focus + .btn-outline-black, .btn-check:focus-visible + .btn-outline-black, .btn-outline-black.active, .btn-outline-black.active:focus, .btn-outline-black.disabled, .btn-outline-black.show, .btn-outline-black.show:focus, .btn-outline-black:active, .btn-outline-black:active:focus, .btn-outline-black:disabled, .btn-outline-black:first-child:hover, .btn-outline-black:focus, .btn-outline-black:focus-visible, .btn-outline-black:hover, :not(.btn-check) + .btn-outline-black:hover, fieldset:disabled .btn-outline-black {
  border-color: #000;
}

.btn-link {
  --mdb-btn-font-weight: 500;
  --mdb-btn-color: #3b71ca;
  --mdb-btn-hover-color: #386bc0;
  --mdb-btn-hover-bg: #f5f5f5;
  --mdb-btn-focus-color: #3566b6;
  --mdb-btn-active-color: #3260ac;
  --mdb-btn-disabled-color: #9e9e9e;
  --mdb-btn-box-shadow: none;
}

.btn-link, .btn-link:first-child:hover, .btn-link:focus-visible, .btn-link:hover, :not(.btn-check) + .btn-link:hover {
  box-shadow: var(--mdb-btn-box-shadow);
  text-decoration: none;
}

.btn-check:focus + .btn-link, .btn-check:focus-visible + .btn-link, .btn-link:focus {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:active + .btn-link, .btn-check:checked + .btn-link, .btn-link.active, .btn-link.show, .btn-link:active {
  color: var(--mdb-btn-active-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:active + .btn-link:focus, .btn-check:checked + .btn-link:focus, .btn-link.active:focus, .btn-link.show:focus, .btn-link:active:focus {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-link.disabled, .btn-link:disabled, fieldset:disabled .btn-link {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-tertiary {
  --mdb-btn-font-weight: 500;
  --mdb-btn-color: #3b71ca;
  --mdb-btn-hover-color: #386bc0;
  --mdb-btn-hover-bg: transparent;
  --mdb-btn-focus-color: #3566b6;
  --mdb-btn-active-color: #3260ac;
  --mdb-btn-disabled-color: #9e9e9e;
  --mdb-btn-box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}

.btn-tertiary, .btn-tertiary:first-child:hover, .btn-tertiary:focus-visible, .btn-tertiary:hover, :not(.btn-check) + .btn-tertiary:hover {
  box-shadow: var(--mdb-btn-box-shadow);
  text-decoration: none;
}

.btn-check:focus + .btn-tertiary, .btn-check:focus-visible + .btn-tertiary, .btn-tertiary:focus {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:active + .btn-tertiary, .btn-check:checked + .btn-tertiary, .btn-tertiary.active, .btn-tertiary.show, .btn-tertiary:active {
  color: var(--mdb-btn-active-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-check:active + .btn-tertiary:focus, .btn-check:checked + .btn-tertiary:focus, .btn-tertiary.active:focus, .btn-tertiary.show:focus, .btn-tertiary:active:focus {
  color: var(--mdb-btn-focus-color);
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-tertiary.disabled, .btn-tertiary:disabled, fieldset:disabled .btn-tertiary {
  box-shadow: var(--mdb-btn-box-shadow);
}

.btn-group-lg > .btn, .btn-lg {
  --mdb-btn-padding-top: .75rem;
  --mdb-btn-padding-bottom: .6875rem;
  --mdb-btn-padding-x: 1.6875rem;
  --mdb-btn-font-size: .875rem;
  --mdb-btn-line-height: 1.6;
}

.btn-group-sm > .btn, .btn-sm {
  --mdb-btn-padding-top: .375rem;
  --mdb-btn-padding-bottom: .3125rem;
  --mdb-btn-padding-x: 1rem;
  --mdb-btn-font-size: .75rem;
  --mdb-btn-line-height: 1.5;
}

.btn-rounded {
  --mdb-btn-border-radius: 10rem;
  border-radius: var(--mdb-btn-border-radius);
}

.btn-floating, [class*="btn-outline-"].btn-floating {
  --mdb-btn-border-radius: 50%;
  border-radius: var(--mdb-btn-border-radius);
  padding: 0;
  position: relative;
}

.btn-floating {
  --mdb-btn-width: 2.3125rem;
  --mdb-btn-height: 2.3125rem;
  --mdb-btn-icon-width: 2.3125rem;
  --mdb-btn-icon-line-height: 2.3125rem;
  --mdb-btn-width-lg: 2.8125rem;
  --mdb-btn-height-lg: 2.8125rem;
  --mdb-btn-icon-width-lg: 2.8125rem;
  --mdb-btn-icon-line-height-lg: 2.8125rem;
  --mdb-btn-width-sm: 1.8125rem;
  --mdb-btn-height-sm: 1.8125rem;
  --mdb-btn-icon-width-sm: 1.8125rem;
  --mdb-btn-icon-line-height-sm: 1.8125rem;
  width: var(--mdb-btn-width);
  height: var(--mdb-btn-height);
}

.btn-floating .fab, .btn-floating .far, .btn-floating .fas {
  width: var(--mdb-btn-icon-width);
  line-height: var(--mdb-btn-icon-line-height);
}

.btn-floating.btn-lg, .btn-group-lg > .btn-floating.btn {
  width: var(--mdb-btn-width-lg);
  height: var(--mdb-btn-height-lg);
}

.btn-floating.btn-lg .fab, .btn-floating.btn-lg .far, .btn-floating.btn-lg .fas, .btn-group-lg > .btn-floating.btn .fab, .btn-group-lg > .btn-floating.btn .far, .btn-group-lg > .btn-floating.btn .fas {
  width: var(--mdb-btn-icon-width-lg);
  line-height: var(--mdb-btn-icon-line-height-lg);
}

.btn-floating.btn-sm, .btn-group-sm > .btn-floating.btn {
  width: var(--mdb-btn-width-sm);
  height: var(--mdb-btn-height-sm);
}

.btn-floating.btn-sm .fab, .btn-floating.btn-sm .far, .btn-floating.btn-sm .fas, .btn-group-sm > .btn-floating.btn .fab, .btn-group-sm > .btn-floating.btn .far, .btn-group-sm > .btn-floating.btn .fas {
  width: var(--mdb-btn-icon-width-sm);
  line-height: var(--mdb-btn-icon-line-height-sm);
}

[class*="btn-outline-"].btn-floating {
  --mdb-btn-icon-width: 2.0625rem;
  --mdb-btn-icon-width-lg: 2.5625rem;
  --mdb-btn-icon-width-sm: 1.5625rem;
  --mdb-btn-icon-line-height: 2.0625rem;
  --mdb-btn-icon-line-height-lg: 2.5625rem;
  --mdb-btn-icon-line-height-sm: 1.5625rem;
}

[class*="btn-outline-"].btn-floating .fab, [class*="btn-outline-"].btn-floating .far, [class*="btn-outline-"].btn-floating .fas {
  width: var(--mdb-btn-icon-width);
  line-height: var(--mdb-btn-icon-line-height);
}

.btn-group-lg > [class*="btn-outline-"].btn-floating.btn .fab, .btn-group-lg > [class*="btn-outline-"].btn-floating.btn .far, .btn-group-lg > [class*="btn-outline-"].btn-floating.btn .fas, [class*="btn-outline-"].btn-floating.btn-lg .fab, [class*="btn-outline-"].btn-floating.btn-lg .far, [class*="btn-outline-"].btn-floating.btn-lg .fas {
  width: var(--mdb-btn-icon-width-lg);
  line-height: var(--mdb-btn-icon-line-height-lg);
}

.btn-group-sm > [class*="btn-outline-"].btn-floating.btn .fab, .btn-group-sm > [class*="btn-outline-"].btn-floating.btn .far, .btn-group-sm > [class*="btn-outline-"].btn-floating.btn .fas, [class*="btn-outline-"].btn-floating.btn-sm .fab, [class*="btn-outline-"].btn-floating.btn-sm .far, [class*="btn-outline-"].btn-floating.btn-sm .fas {
  width: var(--mdb-btn-icon-width-sm);
  line-height: var(--mdb-btn-icon-line-height-sm);
}

.fixed-action-btn {
  --mdb-btn-right: 2.1875rem;
  --mdb-btn-bottom: 2.1875rem;
  --mdb-btn-zindex: 1030;
  --mdb-btn-padding-top: .9375rem;
  --mdb-btn-padding-bottom: 1.25rem;
  --mdb-btn-padding-x: 1.25rem;
  --mdb-btn-margin-bottom: 1.5rem;
  right: var(--mdb-btn-right);
  bottom: var(--mdb-btn-bottom);
  z-index: var(--mdb-btn-zindex);
  padding: var(--mdb-btn-padding-top) var(--mdb-btn-padding-x) var(--mdb-btn-padding-bottom);
  height: auto;
  flex-flow: column-reverse;
  align-items: center;
  margin-bottom: 0;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.fixed-action-btn > .btn-floating {
  z-index: 10;
  position: relative;
  transform: scale(1.2);
}

.fixed-action-btn ul {
  text-align: center;
  opacity: 0;
  z-index: -1;
  flex-direction: column;
  margin: 0;
  padding: 0;
  transition: transform .4s, opacity .4s;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.fixed-action-btn ul li {
  z-index: 0;
  margin-right: auto;
  margin-bottom: var(--mdb-btn-margin-bottom);
  margin-left: auto;
  display: flex;
}

.fixed-action-btn ul li:first-of-type {
  margin-top: calc(var(--mdb-btn-margin-bottom) * .5);
}

.fixed-action-btn ul a.btn {
  opacity: 0;
  transition: opacity .4s ease-in;
}

.fixed-action-btn.active ul, .fixed-action-btn ul a.btn.shown {
  opacity: 1;
}

.btn-block {
  --mdb-btn-margin-top: .5rem;
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: var(--mdb-btn-margin-top);
}

hr.divider-horizontal:not([size]) {
  height: 2px;
}

.divider-horizontal {
  opacity: 1;
  height: 2px;
  background-color: #f5f5f5;
}

.divider-vertical {
  opacity: 1;
  width: 2px;
  background-color: #f5f5f5;
  margin: 0 1rem;
  display: inline-block;
}

hr.divider-horizontal-blurry {
  background-color: #0000;
  background-image: linear-gradient(90deg, #0000, #666, #0000);
}

hr.divider-vertical-blurry {
  width: 1px;
  background-color: #0000;
  background-image: linear-gradient(#0000, #666, #0000);
  top: 0;
  right: 0;
}

.dropdown-menu {
  --mdb-dropdown-item-border-radius: .5rem;
  color: var(--mdb-dropdown-color);
  box-shadow: var(--mdb-dropdown-box-shadow);
  font-size: var(--mdb-dropdown-font-size);
  margin: 0;
  margin-top: var(--mdb-dropdown-spacer);
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  top: 100%;
  left: 0;
}

.dropdown-menu > li {
  border-radius: 0;
}

.dropdown-menu > li:first-child, .dropdown-menu > li:first-child .dropdown-item {
  border-top-left-radius: var(--mdb-dropdown-item-border-radius);
  border-top-right-radius: var(--mdb-dropdown-item-border-radius);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-menu > li:not(:first-child):not(:last-child) .dropdown-item {
  border-radius: 0;
}

.dropdown-menu > li:last-child, .dropdown-menu > li:last-child .dropdown-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--mdb-dropdown-item-border-radius);
  border-bottom-right-radius: var(--mdb-dropdown-item-border-radius);
}

.dropdown-menu.animation {
  --mdb-dropdown-menu-animated-animation-duration: .55s;
  --mdb-dropdown-menu-animated-animation-timing-function: ease;
  animation-duration: var(--mdb-dropdown-menu-animated-animation-duration);
  animation-timing-function: var(--mdb-dropdown-menu-animated-animation-timing-function);
  display: block;
}

.dropdown-item {
  --mdb-dropdown-state-color: #16181b;
  --mdb-dropdown-state-background-color: #eee;
  padding: var(--mdb-dropdown-item-padding-y) var(--mdb-dropdown-item-padding-x);
  color: var(--mdb-dropdown-color);
  border-radius: 0;
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
  color: var(--mdb-dropdown-state-color);
  background-color: var(--mdb-dropdown-state-background-color);
}

.hidden-arrow.dropdown-toggle:after {
  display: none;
}

.animation {
  padding: auto;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@media (prefers-reduced-motion) {
  .animation {
    animation: unset !important;
    transition: none !important;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fade-in;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fade-out;
}

.dropdown-divider {
  --mdb-dropdown-divider-border-top-width: 2px;
  --mdb-dropdown-divider-border-top-bg: #f5f5f5;
  border-top: var(--mdb-dropdown-divider-border-top-width) solid var(--mdb-dropdown-divider-border-top-bg);
  opacity: 1;
}

.dropdown-menu INPUT:not(:-webkit-autofill), .dropdown-menu SELECT:not(:-webkit-autofill), .dropdown-menu TEXTAREA:not(:-webkit-autofill) {
  animation-name: none !important;
}

.btn-group, .btn-group-vertical {
  --mdb-btn-box-shadow: 0 4px 9px -4px #00000059;
  --mdb-btn-hover-box-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-focus-box-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-active-box-shadow: 0 8px 9px -4px #00000026, 0 4px 18px 0 #0000001a;
  --mdb-btn-group-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: var(--mdb-btn-box-shadow);
  transition: var(--mdb-btn-group-transition);
}

.btn-group-vertical:hover, .btn-group:hover {
  box-shadow: var(--mdb-btn-hover-box-shadow);
}

.btn-group-vertical.focus, .btn-group-vertical:focus, .btn-group.focus, .btn-group:focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn-group-vertical.active, .btn-group-vertical:active, .btn-group.active, .btn-group:active {
  box-shadow: var(--mdb-btn-active-box-shadow);
}

.btn-group-vertical.active:focus, .btn-group-vertical:active:focus, .btn-group.active:focus, .btn-group:active:focus {
  box-shadow: var(--mdb-btn-focus-box-shadow);
}

.btn-group-vertical.disabled, .btn-group-vertical:disabled, .btn-group.disabled, .btn-group:disabled, fieldset:disabled .btn-group, fieldset:disabled .btn-group-vertical {
  box-shadow: var(--mdb-btn-box-shadow);
  border: 0;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  box-shadow: none;
}

.btn-group-vertical > .btn:first-child:hover, .btn-group-vertical > .btn:focus-visible, .btn-group-vertical > .btn:hover, .btn-group > .btn:first-child:hover, .btn-group > .btn:focus-visible, .btn-group > .btn:hover, :not(.btn-check) + .btn-group-vertical > .btn:hover, :not(.btn-check) + .btn-group > .btn:hover {
  box-shadow: none !important;
}

.btn-check:active + .btn-group-vertical > .btn, .btn-check:active + .btn-group-vertical > .btn:focus, .btn-check:active + .btn-group > .btn, .btn-check:active + .btn-group > .btn:focus, .btn-check:checked + .btn-group-vertical > .btn, .btn-check:checked + .btn-group-vertical > .btn:focus, .btn-check:checked + .btn-group > .btn, .btn-check:checked + .btn-group > .btn:focus, .btn-check:focus + .btn-group-vertical > .btn, .btn-check:focus + .btn-group > .btn, .btn-check:focus-visible + .btn-group-vertical > .btn, .btn-check:focus-visible + .btn-group > .btn, .btn-group-vertical > .btn-group, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn.active:focus, .btn-group-vertical > .btn.disabled, .btn-group-vertical > .btn.show, .btn-group-vertical > .btn.show:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:active:focus, .btn-group-vertical > .btn:disabled, .btn-group-vertical > .btn:focus, .btn-group > .btn-group, .btn-group > .btn.active, .btn-group > .btn.active:focus, .btn-group > .btn.disabled, .btn-group > .btn.show, .btn-group > .btn.show:focus, .btn-group > .btn:active, .btn-group > .btn:active:focus, .btn-group > .btn:disabled, .btn-group > .btn:focus, fieldset:disabled .btn-group-vertical > .btn, fieldset:disabled .btn-group > .btn {
  box-shadow: none;
}

.btn-group-vertical > .btn-link:first-child, .btn-group-vertical > .btn-tertiary:first-child, .btn-group > .btn-link:first-child, .btn-group > .btn-tertiary:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-link:last-child, .btn-group-vertical > .btn-tertiary:last-child, .btn-group > .btn-link:last-child, .btn-group > .btn-tertiary:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group, .btn-group-lg > .btn, .btn-group-sm > .btn {
  --mdb-btn-border-radius: .25rem;
  border-radius: var(--mdb-btn-border-radius);
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link {
  --mdb-nav-tabs-link-font-weight: 500;
  --mdb-nav-tabs-link-font-size: 12px;
  --mdb-nav-tabs-link-color: #0000008c;
  --mdb-nav-tabs-link-padding-top: 17px;
  --mdb-nav-tabs-link-padding-bottom: 16px;
  --mdb-nav-tabs-link-padding-x: 29px;
  --mdb-nav-tabs-link-hover-bgc: #f7f7f7;
  --mdb-nav-tabs-link-border-bottom-width: 2px;
  --mdb-nav-tabs-link-active-color: #3b71ca;
  --mdb-nav-tabs-link-active-border-color: #3b71ca;
  border-width: 0;
  border-bottom: var(--mdb-nav-tabs-link-border-bottom-width) solid transparent;
  text-transform: uppercase;
  line-height: 1;
  font-weight: var(--mdb-nav-tabs-link-font-weight);
  font-size: var(--mdb-nav-tabs-link-font-size);
  color: var(--mdb-nav-tabs-link-color);
  padding: var(--mdb-nav-tabs-link-padding-top) var(--mdb-nav-tabs-link-padding-x) var(--mdb-nav-tabs-link-padding-bottom) var(--mdb-nav-tabs-link-padding-x);
  border-radius: 0;
}

.nav-tabs .nav-link:hover {
  background-color: var(--mdb-nav-tabs-link-hover-bgc);
  border-color: #0000;
}

.nav-tabs .nav-link:focus {
  border-color: #0000;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: var(--mdb-nav-tabs-link-active-color);
  border-color: var(--mdb-nav-tabs-link-active-border-color);
}

.nav-pills {
  margin-left: -.5rem;
}

.nav-pills .nav-link {
  --mdb-nav-pills-link-border-radius: .25rem;
  --mdb-nav-pills-link-font-size: 12px;
  --mdb-nav-pills-link-padding-top: 17px;
  --mdb-nav-pills-link-padding-bottom: 16px;
  --mdb-nav-pills-link-padding-x: 29px;
  --mdb-nav-pills-link-line-height: 1;
  --mdb-nav-pills-link-hover-bg: #f7f7f7;
  --mdb-nav-pills-link-font-weight: 500;
  --mdb-nav-pills-link-color: #0000008c;
  --mdb-nav-pills-margin: .5rem;
  border-radius: var(--mdb-nav-pills-link-border-radius);
  font-size: var(--mdb-nav-pills-link-font-size);
  text-transform: uppercase;
  padding: var(--mdb-nav-pills-link-padding-top) var(--mdb-nav-pills-link-padding-x) var(--mdb-nav-pills-link-padding-bottom) var(--mdb-nav-pills-link-padding-x);
  line-height: var(--mdb-nav-pills-link-line-height);
  background-color: var(--mdb-nav-pills-link-hover-bg);
  font-weight: var(--mdb-nav-pills-link-font-weight);
  color: var(--mdb-nav-pills-link-color);
  margin: var(--mdb-nav-pills-margin);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  --mdb-nav-pills-link-active-bg: #e3ebf7;
  --mdb-nav-pills-link-active-color: #285192;
  background-color: var(--mdb-nav-pills-link-active-bg);
  color: var(--mdb-nav-pills-link-active-color);
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: auto;
}

.navbar {
  --mdb-navbar-box-shadow: 0 4px 12px 0 #00000012, 0 2px 4px #0000000d;
  --mdb-navbar-padding-top: .5625rem;
  --mdb-navbar-brand-img-margin-right: .25rem;
  box-shadow: var(--mdb-navbar-box-shadow);
  padding-top: var(--mdb-navbar-padding-top);
}

.navbar-toggler {
  border: 0;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-dark .navbar-toggler, .navbar-light .navbar-toggler {
  border: 0;
}

.navbar-brand {
  align-items: center;
  display: flex;
}

.navbar-brand img {
  margin-right: var(--mdb-navbar-brand-img-margin-right);
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-dark .navbar-toggler-icon, .navbar-light .navbar-toggler-icon {
  background-image: none;
}

.card {
  border: 0;
}

.card .bg-image {
  border-top-left-radius: var(--mdb-card-border-radius);
  border-top-right-radius: var(--mdb-card-border-radius);
}

.card[class*="bg-"] .card-header {
  --mdb-card-header-border-bottom-color: var(--mdb-border-color-translucent);
  border-bottom-color: var(--mdb-card-header-border-bottom-color);
}

.card[class*="bg-"] .card-footer {
  --mdb-card-footer-border-top-color: var(--mdb-border-color-translucent);
  border-top-color: var(--mdb-card-footer-border-top-color);
}

.card-header {
  --mdb-card-header-border-width: 2px;
  --mdb-card-header-border-color: #f5f5f5;
  border-bottom: var(--mdb-card-header-border-width) solid var(--mdb-card-header-border-color);
}

.card-body[class*="bg-"] {
  border-bottom-left-radius: var(--mdb-card-border-radius);
  border-bottom-right-radius: var(--mdb-card-border-radius);
}

.card-footer {
  --mdb-card-footer-border-color: #f5f5f5;
  --mdb-card-footer-border-width: 2px;
  border-top: var(--mdb-card-footer-border-width) solid var(--mdb-card-footer-border-color);
}

.card-img-left {
  border-top-left-radius: var(--mdb-card-border-radius);
  border-bottom-left-radius: var(--mdb-card-border-radius);
}

.navbar .breadcrumb {
  --mdb-breadcrumb-item-color: #0000008c;
  --mdb-breadcrumb-item-hover-color: #000000b3;
  --mdb-breadcrumb-item-before-color: #0000008c;
  --mdb-breadcrumb-item-transition: color .15s ease-in-out;
  background-color: #0000;
  margin-bottom: 0;
}

.navbar .breadcrumb .breadcrumb-item a {
  color: var(--mdb-breadcrumb-item-color);
  transition: var(--mdb-breadcrumb-item-transition);
}

.navbar .breadcrumb .breadcrumb-item a:focus, .navbar .breadcrumb .breadcrumb-item a:hover {
  color: var(--mdb-breadcrumb-item-hover-color);
}

.navbar .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: var(--mdb-breadcrumb-item-before-color);
}

.pagination {
  --mdb-pagination-border-radius: .25rem;
  --mdb-pagination-active-box-shadow: 0 4px 9px -4px #e3ebf7;
  --mdb-pagination-active-transition: all .2s linear;
  --mdb-pagination-active-font-weight: 500;
  --mdb-pagination-circle-border-radius: 50%;
  --mdb-pagination-circle-padding-x: .841rem;
  --mdb-pagination-circle-padding-l-lg: 1.39941rem;
  --mdb-pagination-circle-padding-r-lg: 1.39942rem;
  --mdb-pagination-circle-padding-l-sm: .696rem;
  --mdb-pagination-circle-padding-r-sm: .688rem;
}

.page-link {
  border-radius: var(--mdb-pagination-border-radius);
  background-color: #0000;
  border: 0;
  outline: 0;
}

.page-link:focus {
  box-shadow: none;
}

.active > .page-link, .page-link.active {
  box-shadow: var(--mdb-pagination-active-box-shadow);
  transition: var(--mdb-pagination-active-transition);
  font-weight: var(--mdb-pagination-active-font-weight);
  border: 0;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--mdb-pagination-border-radius);
  border-bottom-left-radius: var(--mdb-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--mdb-pagination-border-radius);
  border-bottom-right-radius: var(--mdb-pagination-border-radius);
}

.pagination-circle .page-item:first-child .page-link, .pagination-circle .page-item:last-child .page-link, .pagination-circle .page-link {
  border-radius: var(--mdb-pagination-circle-border-radius);
}

.pagination-circle .page-link {
  padding-left: var(--mdb-pagination-circle-padding-x);
  padding-right: var(--mdb-pagination-circle-padding-x);
}

.pagination-circle.pagination-lg .page-link {
  padding-left: var(--mdb-pagination-circle-padding-l-lg);
  padding-right: var(--mdb-pagination-circle-padding-r-lg);
}

.pagination-circle.pagination-sm .page-link {
  padding-left: var(--mdb-pagination-circle-padding-l-sm);
  padding-right: var(--mdb-pagination-circle-padding-r-sm);
}

.badge-dot {
  --mdb-badge-border-radius: 4.5px;
  --mdb-badge-height: 9px;
  --mdb-badge-width: 9px;
  --mdb-badge-margin-left: -.3125rem;
  min-width: 0;
  width: var(--mdb-badge-width);
  height: var(--mdb-badge-height);
  border-radius: var(--mdb-badge-border-radius);
  margin-left: var(--mdb-badge-margin-left);
  padding: 0;
  position: absolute;
}

.badge-dot:empty {
  display: inline-block;
}

.badge-notification {
  --mdb-badge-font-size: .6rem;
  --mdb-badge-padding-x: .45em;
  --mdb-badge-padding-y: .2em;
  --mdb-badge-margin-top: -.1rem;
  --mdb-badge-margin-left: -.5rem;
  font-size: var(--mdb-badge-font-size);
  padding: var(--mdb-badge-padding-y) var(--mdb-badge-padding-x);
  margin-top: var(--mdb-badge-margin-top);
  margin-left: var(--mdb-badge-margin-left);
  position: absolute;
}

.badge-primary {
  color: #2c58a0;
  background-color: #dfe7f6;
}

.badge-primary i {
  color: #376fc8;
}

.badge-secondary {
  color: #40464f;
  background-color: #ebedef;
}

.badge-secondary i {
  color: #565e6c;
}

.badge-success {
  color: #0d6832;
  background-color: #d6f0e0;
}

.badge-success i {
  color: #139647;
}

.badge-danger {
  color: #af233a;
  background-color: #f9e1e5;
}

.badge-danger i {
  color: #d62e4a;
}

.badge-warning {
  color: #73510d;
  background-color: #fbf0da;
}

.badge-warning i {
  color: #a17112;
}

.badge-info {
  color: #1c657d;
  background-color: #def1f7;
}

.badge-info i {
  color: #2686a6;
}

.badge-light {
  color: #4f4f4f;
  background-color: #f5f5f5;
}

.badge-light i {
  color: #8c8c8c;
}

.badge-dark {
  color: #f5f5f5;
  background-color: #332e2e;
}

.badge-dark i {
  color: #e8e8e8;
}

.alert {
  border: 0;
}

.alert-absolute {
  position: absolute;
}

.alert-fixed {
  --mdb-alert-fixed-z-index: 1070;
  z-index: var(--mdb-alert-fixed-z-index);
  position: fixed;
}

.parent-alert-relative {
  position: relative;
}

.alert-primary {
  color: #2c58a0;
  background-color: #dfe7f6;
}

.alert-primary i {
  color: #376fc8;
}

.alert-primary .alert-link {
  color: #2c58a0;
}

.alert-primary .alert-link:hover {
  color: #234680;
}

.alert-secondary {
  color: #40464f;
  background-color: #ebedef;
}

.alert-secondary i {
  color: #565e6c;
}

.alert-secondary .alert-link {
  color: #40464f;
}

.alert-secondary .alert-link:hover {
  color: #33383f;
}

.alert-success {
  color: #0d6832;
  background-color: #d6f0e0;
}

.alert-success i {
  color: #139647;
}

.alert-success .alert-link {
  color: #0d6832;
}

.alert-success .alert-link:hover {
  color: #0a5328;
}

.alert-danger {
  color: #af233a;
  background-color: #f9e1e5;
}

.alert-danger i {
  color: #d62e4a;
}

.alert-danger .alert-link {
  color: #af233a;
}

.alert-danger .alert-link:hover {
  color: #8c1c2e;
}

.alert-warning {
  color: #73510d;
  background-color: #fbf0da;
}

.alert-warning i {
  color: #a17112;
}

.alert-warning .alert-link {
  color: #73510d;
}

.alert-warning .alert-link:hover {
  color: #5c410a;
}

.alert-info {
  color: #1c657d;
  background-color: #def1f7;
}

.alert-info i {
  color: #2686a6;
}

.alert-info .alert-link {
  color: #1c657d;
}

.alert-info .alert-link:hover {
  color: #165164;
}

.alert-light {
  color: #4f4f4f;
  background-color: #f5f5f5;
}

.alert-light i {
  color: #8c8c8c;
}

.alert-light .alert-link {
  color: #4f4f4f;
}

.alert-light .alert-link:hover {
  color: #3f3f3f;
}

.alert-dark {
  color: #f5f5f5;
  background-color: #332e2e;
}

.alert-dark i {
  color: #e8e8e8;
}

.alert-dark .alert-link {
  color: #f5f5f5;
}

.alert-dark .alert-link:hover {
  color: #c4c4c4;
}

.progress {
  box-shadow: none;
  border-radius: 0;
}

.list-group {
  --mdb-list-group-item-transition-time: .5s;
}

.list-group-item-action, .list-group-item-action:hover {
  transition: var(--mdb-list-group-item-transition-time);
}

.list-group-light {
  --mdb-list-group-light-item-py: 1rem;
  --mdb-list-group-light-item-border: 2px solid #f5f5f5;
  --mdb-list-group-light-item-border-width: 2px;
  --mdb-list-group-light-active-border-radius: .5rem;
  --mdb-list-group-light-active-bg: #dfe7f6;
  --mdb-list-group-light-active-color: #2c58a0;
}

.list-group-light .list-group-item {
  padding: var(--mdb-list-group-light-item-py) 0;
  border: var(--mdb-list-group-light-item-border);
}

.list-group-light > .list-group-item {
  border-left-width: 0;
  border-bottom-width: var(--mdb-list-group-light-item-border-width);
  border-top-width: 0;
  border-right-width: 0;
}

.list-group-light > .list-group-item:last-of-type {
  border: none;
}

.list-group-light .active {
  border-radius: var(--mdb-list-group-light-active-border-radius);
  background-color: var(--mdb-list-group-light-active-bg);
  color: var(--mdb-list-group-light-active-color);
  border: none;
}

.list-group-light .list-group-item-action:focus, .list-group-light .list-group-item-action:hover {
  border-radius: var(--mdb-list-group-light-active-border-radius);
}

.list-group-small {
  --mdb-list-group-small-item-py: .5rem;
}

.list-group-small .list-group-item {
  padding: var(--mdb-list-group-small-item-py) 0;
}

.list-group-item-primary {
  color: #2c58a0;
  background-color: #dfe7f6;
}

.list-group-item-primary i {
  color: #376fc8;
}

.list-group-item-secondary {
  color: #40464f;
  background-color: #ebedef;
}

.list-group-item-secondary i {
  color: #565e6c;
}

.list-group-item-success {
  color: #0d6832;
  background-color: #d6f0e0;
}

.list-group-item-success i {
  color: #139647;
}

.list-group-item-danger {
  color: #af233a;
  background-color: #f9e1e5;
}

.list-group-item-danger i {
  color: #d62e4a;
}

.list-group-item-warning {
  color: #73510d;
  background-color: #fbf0da;
}

.list-group-item-warning i {
  color: #a17112;
}

.list-group-item-info {
  color: #1c657d;
  background-color: #def1f7;
}

.list-group-item-info i {
  color: #2686a6;
}

.list-group-item-light {
  color: #4f4f4f;
  background-color: #f5f5f5;
}

.list-group-item-light i {
  color: #8c8c8c;
}

.list-group-item-dark {
  color: #f5f5f5;
  background-color: #332e2e;
}

.list-group-item-dark i {
  color: #e8e8e8;
}

.btn-close:focus {
  box-shadow: none;
}

.modal-content {
  --mdb-modal-box-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  box-shadow: var(--mdb-modal-box-shadow);
  border: 0;
}

.toast {
  --mdb-toast-border-bottom-width: 2px;
  --mdb-toast-btn-close-width: 1.3em;
  --mdb-toast-btn-close-mr: -.375rem;
  --mdb-toast-btn-close-ml: .75rem;
  border: 0;
}

.toast .btn-close {
  width: var(--mdb-toast-btn-close-width);
}

.toast-header {
  border-bottom-width: var(--mdb-toast-border-bottom-width);
}

.toast-header .btn-close {
  margin-right: var(--mdb-toast-btn-close-mr);
  margin-left: var(--mdb-toast-btn-close-ml);
}

.parent-toast-relative {
  position: relative;
}

.toast-absolute {
  position: absolute;
}

.toast-fixed {
  z-index: var(--mdb-toast-zindex);
  position: fixed;
}

.toast-primary {
  color: #2c58a0;
  background-color: #dfe7f6;
  border-color: #c7d6f0;
}

.toast-primary i {
  color: #376fc8;
}

.toast-secondary {
  color: #40464f;
  background-color: #ebedef;
  border-color: #dadee1;
}

.toast-secondary i {
  color: #565e6c;
}

.toast-success {
  color: #0d6831;
  background-color: #d6f0e0;
  border-color: #c0e7d0;
}

.toast-success i {
  color: #139647;
}

.toast-danger {
  color: #af233a;
  background-color: #f9e1e5;
  border-color: #f4c8cf;
}

.toast-danger i {
  color: #d62e4a;
}

.toast-warning {
  color: #73510d;
  background-color: #fbf0da;
  border-color: #f9e4be;
}

.toast-warning i {
  color: #a17112;
}

.toast-info {
  color: #1c657d;
  background-color: #def1f7;
  border-color: #c6e6f1;
}

.toast-info i {
  color: #2686a6;
}

.toast-light {
  color: #4f4f4f;
  background-color: #f5f5f5;
  border-color: #e6e6e6;
}

.toast-light i {
  color: #8c8c8c;
}

.toast-dark {
  color: #f5f5f5;
  background-color: #332e2e;
  border-color: #443c3c;
}

.toast-dark i {
  color: #e8e8e8;
}

.tooltip {
  --mdb-tooltip-font-size: 14px;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-arrow {
  display: none;
}

.tooltip-inner {
  font-size: var(--mdb-tooltip-font-size);
}

.popover {
  --mdb-popover-border-bottom-width: 2px;
}

.popover .popover-arrow {
  display: none;
}

.popover-header {
  border-bottom: var(--mdb-popover-border-bottom-width) solid var(--mdb-popover-border-color);
}

.nav-pills.menu-sidebar .nav-link {
  --mdb-scrollspy-menu-sidebar-font-size: .8rem;
  --mdb-scrollspy-menu-sidebar-color: #262626;
  --mdb-scrollspy-menu-sidebar-line-height: 1.1rem;
  --mdb-scrollspy-menu-sidebar-padding-x: 5px;
  --mdb-scrollspy-menu-sidebar-font-weight: 400;
  --mdb-scrollspy-menu-sidebar-transition: all .2s ease-in-out;
  --mdb-scrollspy-menu-sidebar-margin-y: 3px;
  font-size: var(--mdb-scrollspy-menu-sidebar-font-size);
  color: var(--mdb-scrollspy-menu-sidebar-color);
  line-height: var(--mdb-scrollspy-menu-sidebar-line-height);
  padding: 0 var(--mdb-scrollspy-menu-sidebar-padding-x);
  font-weight: var(--mdb-scrollspy-menu-sidebar-font-weight);
  transition: var(--mdb-scrollspy-menu-sidebar-transition);
  text-transform: none;
  margin-top: var(--mdb-scrollspy-menu-sidebar-margin-y);
  margin-bottom: var(--mdb-scrollspy-menu-sidebar-margin-y);
  background-color: #0000;
}

.nav-pills.menu-sidebar .nav-link.active, .nav-pills.menu-sidebar .show > .nav-link {
  --mdb-scrollspy-menu-sidebar-active-color: #3b71ca;
  --mdb-scrollspy-menu-sidebar-active-font-weight: 600;
  --mdb-scrollspy-menu-sidebar-active-border-width: .125rem;
  --mdb-scrollspy-menu-sidebar-active-border-color: #3b71ca;
  box-shadow: none;
  color: var(--mdb-scrollspy-menu-sidebar-active-color);
  font-weight: var(--mdb-scrollspy-menu-sidebar-active-font-weight);
  border-left: var(--mdb-scrollspy-menu-sidebar-active-border-width) solid var(--mdb-scrollspy-menu-sidebar-active-border-color);
  background-color: #0000;
  border-radius: 0;
}

.nav-pills.menu-sidebar .collapsible-scrollspy ~ .nav {
  --mdb-scrollspy-collapsible-nav-transition-time: .5s;
  transition: height var(--mdb-scrollspy-collapsible-nav-transition-time) ease;
  flex-wrap: nowrap;
}

.ripple-surface {
  vertical-align: bottom;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ripple-surface-unbound {
  overflow: visible;
}

.ripple-wave {
  --mdb-ripple-wave-cubicBezier: cubic-bezier(0, 0, .15, 1);
  --mdb-ripple-wave-border-radius: 50%;
  --mdb-ripple-wave-opacity: .5;
  --mdb-ripple-wave-transform: scale(0);
  --mdb-ripple-wave-z-index: 999;
  --mdb-ripple-wave-active-transform: scale(1);
  border-radius: var(--mdb-ripple-wave-border-radius);
  opacity: var(--mdb-ripple-wave-opacity);
  pointer-events: none;
  touch-action: none;
  transform: var(--mdb-ripple-wave-transform);
  transition-property: transform, opacity;
  transition-timing-function: var(--mdb-ripple-wave-cubicBezier), var(--mdb-ripple-wave-cubicBezier);
  z-index: var(--mdb-ripple-wave-z-index);
  background-image: radial-gradient(circle, #0003 0, #0000004d 40%, #0006 50%, #00000080 60%, #0000 70%);
  position: absolute;
}

.ripple-wave.active {
  transform: var(--mdb-ripple-wave-active-transform);
  opacity: 0;
}

.btn .ripple-wave, .input-wrapper .ripple-wave {
  background-image: radial-gradient(circle, #fff3 0, #ffffff4d 40%, #fff6 50%, #ffffff80 60%, #fff0 70%);
}

.ripple-surface-primary .ripple-wave {
  background-image: radial-gradient(circle, #3b71ca33 0, #3b71ca4d 40%, #3b71ca66 50%, #3b71ca80 60%, #3b71ca00 70%);
}

.ripple-surface-secondary .ripple-wave {
  background-image: radial-gradient(circle, #e3ebf733 0, #e3ebf74d 40%, #e3ebf766 50%, #e3ebf780 60%, #e3ebf700 70%);
}

.ripple-surface-success .ripple-wave {
  background-image: radial-gradient(circle, #14a44d33 0, #14a44d4d 40%, #14a44d66 50%, #14a44d80 60%, #14a44d00 70%);
}

.ripple-surface-danger .ripple-wave {
  background-image: radial-gradient(circle, #dc4c6433 0, #dc4c644d 40%, #dc4c6466 50%, #dc4c6480 60%, #dc4c6400 70%);
}

.ripple-surface-warning .ripple-wave {
  background-image: radial-gradient(circle, #e4a11b33 0, #e4a11b4d 40%, #e4a11b66 50%, #e4a11b80 60%, #e4a11b00 70%);
}

.ripple-surface-info .ripple-wave {
  background-image: radial-gradient(circle, #54b4d333 0, #54b4d34d 40%, #54b4d366 50%, #54b4d380 60%, #54b4d300 70%);
}

.ripple-surface-light .ripple-wave {
  background-image: radial-gradient(circle, #fbfbfb33 0, #fbfbfb4d 40%, #fbfbfb66 50%, #fbfbfb80 60%, #fbfbfb00 70%);
}

.ripple-surface-dark .ripple-wave {
  background-image: radial-gradient(circle, #332d2d33 0, #332d2d4d 40%, #332d2d66 50%, #332d2d80 60%, #332d2d00 70%);
}

.range {
  --mdb-range-thumb-height: 30px;
  --mdb-range-thumb-width: 30px;
  --mdb-range-thumb-top: -35px;
  --mdb-range-thumb-margin-left: -15px;
  --mdb-range-thumb-border-radius: 50% 50% 50% 0;
  --mdb-range-thumb-transform: scale(0);
  --mdb-range-thumb-transition: transform .2s ease-in-out;
  --mdb-range-thumb-value-font-size: 12px;
  --mdb-range-thumb-value-line-height: 30px;
  --mdb-range-thumb-value-color: #fff;
  --mdb-range-thumb-value-font-weight: 500;
  --mdb-range-thumb-background: #3b71ca;
  position: relative;
}

.range .thumb {
  height: var(--mdb-range-thumb-height);
  width: var(--mdb-range-thumb-width);
  top: var(--mdb-range-thumb-top);
  margin-left: var(--mdb-range-thumb-margin-left);
  text-align: center;
  transform: var(--mdb-range-thumb-transform);
  transform-origin: bottom;
  transition: var(--mdb-range-thumb-transition);
}

.range .thumb, .range .thumb:after {
  border-radius: var(--mdb-range-thumb-border-radius);
  display: block;
  position: absolute;
}

.range .thumb:after {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--mdb-range-thumb-background);
  z-index: -1;
  top: 0;
  transform: translateX(-50%);
}

.range .thumb .thumb-value {
  font-size: var(--mdb-range-thumb-value-font-size);
  line-height: var(--mdb-range-thumb-value-line-height);
  color: var(--mdb-range-thumb-value-color);
  font-weight: var(--mdb-range-thumb-value-font-weight);
  z-index: 2;
  display: block;
}

.range .thumb.thumb-active {
  transform: scale(1);
}

.accordion-button:not(.collapsed):focus {
  box-shadow: var(--mdb-accordion-btn-focus-box-shadow);
}

.accordion-button:focus {
  border-color: var(--mdb-accordion-btn-focus-border-color);
  box-shadow: none;
  outline: 0;
}

.accordion-flush {
  --mdb-accordion-flush-btn-box-shadow: inset 0 -2px 0 #f5f5f5;
  --mdb-accordion-flush-border-bottom: 2px solid #f5f5f5;
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: var(--mdb-accordion-flush-btn-box-shadow);
}

.accordion-flush .accordion-item {
  border-bottom: var(--mdb-accordion-flush-border-bottom);
}

.accordion.accordion-borderless, .accordion.accordion-flush {
  --mdb-accordion-bg: transparent;
  background-color: var(--mdb-accordion-bg);
}

.accordion-borderless {
  --mdb-accordion-borderless-btn-border-radius: .5rem;
  --mdb-accordion-borderless-btn-bg: #dfe7f6;
  --mdb-accordion-borderless-btn-color: #2c58a0;
}

.accordion-borderless .accordion-item {
  border: 0;
}

.accordion-borderless .accordion-item .accordion-button {
  border-radius: var(--mdb-accordion-borderless-btn-border-radius);
}

.accordion-borderless .accordion-item .accordion-button:not(.collapsed) {
  background-color: var(--mdb-accordion-borderless-btn-bg);
  color: var(--mdb-accordion-borderless-btn-color);
  box-shadow: none;
}

.carousel-control-prev-icon:after {
  content: "";
}

.carousel-control-next-icon:after, .carousel-control-prev-icon:after {
  --mdb-carousel-control-icon-font-size: 1.7rem;
  --mdb-carousel-control-icon-font-weight: 700;
  font-weight: var(--mdb-carousel-control-icon-font-weight);
  font-size: var(--mdb-carousel-control-icon-font-size);
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
}

.carousel-control-next-icon:after {
  content: "";
}

/*# sourceMappingURL=index.f368b6fd.css.map */
